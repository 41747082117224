
import { Component, Vue } from 'vue-property-decorator';
import { UserModule, User } from '@/store/modules/user';
import settings from '@/settings';
import { updateUserSetting, UserSettings } from '@/api/users';

@Component({
  name: 'LangSelect',
})
export default class extends Vue {
  /** Local variables */
  companyType = UserModule.companyType;
  languageSelectionIsLoading: boolean = false;

  get language() {
    return UserModule.i18nCode;
  }

  /**
   * Update user language settings by remote API
   * @param i18nCode
   */
  async updateUserLanguageRemote(i18nCode: string) {
    try {
      this.languageSelectionIsLoading = true;
      const requestBody: UserSettings = {
        userId: UserModule.id,
        i18nCode: i18nCode,
        timeZone: UserModule.timeZone,
        datetimeFormat: UserModule.datetimeFormat,
        gridPageSize: UserModule.gridPageSize.toString() ?? User.gridPageSize,
      };
      const response = await updateUserSetting(UserModule.id, requestBody);
      if (response) {
        this.handleSetLanguage(i18nCode);
        this.$message({
          message: this.$t('components.changeLanguageTips').toString(),
          type: 'success',
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.languageSelectionIsLoading = false;
    }
  }

  /**
   * Handle language change
   * @param lang
   */
  handleSetLanguage(lang: string) {
    this.$i18n.locale = lang;
    UserModule.SetLang(lang);
    document.documentElement.lang = lang;
    const title = this.$route.meta?.title
      ? `${this.$t(this.$route.meta?.title)} - ${settings.title}`
      : `${settings.title}`;
    document.title = title;
  }
}
