import { AssetType, OperationalStatus } from '@/utils/workData/lookuptable';
import { LifeCycle } from './geofence';

export enum GpsStatus {
  CONNECTED,
  DISCONNECTED,
}

export interface Asset {
  id: string;
  companyAssetId: string;
  assetType: AssetType;
}

export interface GeofenceAsset {
  id: string;
  companyAssetId: string;
  assetDataCollectionId: string;
  operationalStatus: OperationalStatus;
  geodeticLatitude: number;
  geodeticLongitude: number;
  assetTypeCode: AssetType;
  lastSyncTime: Date;
  popup?: boolean;
}

export interface AssetDetails extends GeofenceAsset {
  gpsConnectivity: GpsStatus;
  currentPayload: number;
}

export enum TrackingStrategy {
  GFNTRACK_ALWAYS,
  GFNTRACK_INSIDE_TIME_INTERVAL,
  GFNTRACK_OUTSIDE_TIME_INTERVAL,
  GFNTRACK_ALL_DAYS,
  GFNTRACK_OFF,
}

export enum TrackingDays {
  DAY_MONDAY = 'DAY_MONDAY',
  DAY_TUESDAY = 'DAY_TUESDAY',
  DAY_WEDNESDAY = 'DAY_WEDNESDAY',
  DAY_THURSDAY = 'DAY_THURSDAY',
  DAY_FRIDAY = 'DAY_FRIDAY',
  DAY_SATURDAY = 'DAY_SATURDAY',
  DAY_SUNDAY = 'DAY_SUNDAY',
}

export interface GeofencePosition {
  sequenceNumber: number;
  geodeticLatitude: number;
  geodeticLongitude: number;
}

export interface Geofence {
  id: string;
  name: string;
  organizationId: string;
  organizationName: string;
  geofencePosition: GeofencePosition[];
  lifeCycle: LifeCycle;
  associatedAssetTypes: string[];
  geofenceType: string;
  tracking: TrackingStrategy | string | null;
  trackingStartTime: string | null;
  trackingEndTime: string | null;
  trackingDays: TrackingDays[] | (string | null)[];
}

export interface Event {
  assetId: string;
  companyAssetId: string;
  companyId: string;
  eventDescription: string;
  eventName: string;
  eventTypeCategoryCode: string;
  eventTypeCategoryName: string;
  eventTypeCode: string;
  id: string;
  location: Location;
  organizationId: string;
  organizationName: string;
  speed: number;
  timestamp: string;
  timezone: string;
}

export interface Location {
  acc: string;
  alt: number;
  lat: number;
  lgt: number;
  sog: number;
}
