import { UIColumn, UITable } from '@/utils/types/columnCustomizationTypes';
import { getRequest } from '@/utils/request';
import { AssetType, UI_TABLE } from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

// TODO: Can asset type be null?
export function getUITable(
  code: UI_TABLE,
  assetType: AssetType
): CommonResultPromise<UITable> {
  const request = getRequest();
  const response = request.get<UITable>(`/user-settings/ui-tables`, {
    params: {
      code: code,
      assetType: assetType,
    },
  });
  return response;
}

export function updateUITable(
  code: UI_TABLE,
  assetType: AssetType,
  columns: UIColumn[]
): CommonResultPromise {
  const request = getRequest();
  const response = request.post(`/user-settings/ui-columns`, columns, {
    params: {
      code,
      assetType,
    },
  });
  return response;
}
