import { render, staticRenderFns } from "./HistoryTable.vue?vue&type=template&id=6b3ff3fc&scoped=true&"
import script from "./HistoryTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./HistoryTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./HistoryTable.vue?vue&type=style&index=0&id=6b3ff3fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3ff3fc",
  null
  
)

export default component.exports