
import { getHealthStatusGroupedByAssetType } from '@/api/healthStatus';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { HealthStatusAsset } from '@/utils/types/health';
import {
  assetTypeIcons,
  AssetType,
  HEALTH_STATUS_SYN,
  WARNING_TO_HEALTH_STATUS_COLOR,
  WarningType,
} from '@/utils/workData/lookuptable';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { Ref, unref } from 'vue';
import { ORGANIZATION_ID } from '@/utils/constants';
import {
  QueryParameter,
  FilterOperator,
} from '@/model/queryParameters/QueryParameter';
import { HealthStatusGroupByAssetTypeResponse } from '@/api/healthStatus';

@Component({
  name: 'AssetHealthList',
})
export default class extends Vue {
  @Prop() selectedFilter!: WarningType;
  @Prop() assetType!: AssetType;

  data: HealthStatusGroupByAssetTypeResponse = {
    assetsByStatus: [],
    totalAssetCount: 0,
  };
  tableData: HealthStatusAsset[] = [];
  assetTypeIcon: string = '';
  context!: Ref<ActiveContext>;
  isHealthStatusLoading: boolean = false;

  async created() {
    this.context = useActiveContext();
    await this.getHealthStatus();
    this.updateConditional(this.selectedFilter);
  }

  @Watch('selectedFilter')
  onWarningChanged(val: WarningType, oldVal: WarningType) {
    this.updateConditional(val);
  }

  updateConditional(warning: WarningType) {
    if (this.data.hasOwnProperty('errors')) return;
    let status = HEALTH_STATUS_SYN[warning];
    this.tableData = this.data.assetsByStatus
      .filter((group: any) => {
        return group.status == status;
      })
      .flatMap((e: any) => e.assets)
      .sort(
        (a: HealthStatusAsset, b: HealthStatusAsset) =>
          new Date(b.healthStatusLastChangeTimestamp).getTime() -
          new Date(a.healthStatusLastChangeTimestamp).getTime()
      ) as HealthStatusAsset[];
  }

  /** Fetch heath status group by asset type */
  async getHealthStatus() {
    this.isHealthStatusLoading = true;
    const activeContext: ActiveContext = unref(this.context);
    const queryParameter: QueryParameter =
      this.buildQueryParameter(activeContext);
    const healthStatusResponse = await getHealthStatusGroupedByAssetType(
      this.assetType,
      activeContext,
      queryParameter
    );
    this.data = healthStatusResponse.data;
    this.assetTypeIcon = assetTypeIcons[this.assetType] as string;
    this.isHealthStatusLoading = false;
  }

  /** Build query params */
  buildQueryParameter(activeContext: ActiveContext): QueryParameter {
    return activeContext.organizationIds
      ? {
          filters: [
            {
              name: ORGANIZATION_ID,
              operator: FilterOperator.IN,
              value: activeContext.organizationIds,
            },
          ],
        }
      : { pagination: { page: 1, size: 1000 } };
  }

  getStyle(condition: WarningType) {
    return {
      backgroundColor: this.statusColorFromCondition(condition),
    };
  }

  statusColorFromCondition(warning: WarningType): string {
    return WARNING_TO_HEALTH_STATUS_COLOR[warning];
  }

  onRowClick(row: any, column: any, event: any) {
    this.$emit(
      'asset-selected',
      row.assetId,
      row.companyAssetId || row.assetDataCollectionId
    );
  }
}
