import { render, staticRenderFns } from "./CustomerSelect.vue?vue&type=template&id=41e7c183&scoped=true&"
import script from "./CustomerSelect.vue?vue&type=script&lang=ts&setup=true&"
export * from "./CustomerSelect.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./CustomerSelect.vue?vue&type=style&index=0&id=41e7c183&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e7c183",
  null
  
)

export default component.exports