import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export const DEFAULT_SUBSCRIPTION_PACKAGES_REQUEST_SIZE: number = 10000;

// TODO type Subscription
export type Subscription = any;

export function getSubscriptionPackages(
  page?: number,
  size?: number,
  searchByNameValue?: string | null,
  packageTypeValue?: string | null
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url:
      searchByNameValue && !packageTypeValue
        ? `/subscriptionPackages?page=${page}&size=${size}&name=${searchByNameValue}`
        : packageTypeValue && !searchByNameValue
        ? `/subscriptionPackages?page=${page}&size=${size}&type=${packageTypeValue}`
        : searchByNameValue && packageTypeValue
        ? `/subscriptionPackages?page=${page}&size=${size}&name=${searchByNameValue}&type=${packageTypeValue}`
        : page && size
        ? `/subscriptionPackages?page=${page}&size=${size}`
        : '/subscriptionPackages',
    method: 'get',
  });
  return response;
}

export function getSubscriptionPackageById(
  packageId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'get',
  });
  return response;
}

export function getSystemFeatures(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/systemFeatures',
    method: 'get',
  });
  return response;
}

export function createSubscriptionPackage(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/subscriptionPackages',
    method: 'post',
    data: data,
  });
  return response;
}

export function updateSubscriptionPackageById(
  packageId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'put',
    data: data,
  });
  return response;
}

export function deleteSubscriptionPackageById(
  packageId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscriptionPackages/${packageId}`,
    method: 'delete',
  });
  return response;
}

export function getCompanySubscriptionsByCompanyId(
  companyId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${companyId}`,
    method: 'get',
  });
  return response;
}

export function createCompanySubscriptions(
  companyId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${companyId}`,
    method: 'post',
    data: data,
  });
  return response;
}

export function updateCompanySubscriptions(
  companyId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${companyId}`,
    method: 'put',
    data: data,
  });
  return response;
}

interface CompanySystemFeature {
  id: string;
  code: string;
  valueType: string;
  value: string;
  unit: string;
}

export interface CompanySubscription {
  subscriptionPackageCode: string;
  subscriptionPackageCompanyType: string;
  subscriptionPackageId: string;
  subscriptionPackageName: string;
  subscriptionPackageType: string;
  subscriptionPackageAssetType: string;
  systemFeatures: CompanySystemFeature[];
}

export function getCompanySubscriptions(
  companyId: string,
  assetType?: string
): CommonResultPromise<CompanySubscription[]> {
  const request = getRequest();
  const assetTypeParam =
    assetType != undefined ? `?assetType=${assetType}` : '';
  const response = request.get<CompanySubscription[]>(
    `/companySubscription/${companyId}${assetTypeParam}`
  );
  return response;
}

export interface SubscriptionPackageResponse {
  subscriptionPackages: SubscriptionPackage[];
  total: number;
  count: number;
}

export interface SubscriptionPackage {
  id: string;
  name: string;
  code: string;
  companyType: string;
  featuresCount: number;
  note: string;
  type: string;
}
