
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { getModules, getPagesByModuleCode } from '@/api/dashboardConf';
import {
  getWidgetsByPageCodeAndTemplateId,
  IncludedWidget,
} from '@/api/widgets';
import { PAGE_NO_WIDGET } from '@/utils/workData/dashboardConf';
import { PAGE_LAYOUT_MAP } from '@/utils/workData/utilMap';
import ModuleAccess from '@/components/form/ModuleAccess.vue';
import VueGridLayout from 'vue-grid-layout';
import { Tree } from 'element-ui';
import { modulesToTreeData } from '@/utils/elementui/tree';
import { filterModulesForCompanyType } from './moduleFiltering';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { scaleHorizontal, NUMBER_OF_GRID_COLUMNS } from '@/utils/widget';

@Component({
  name: 'ViewDashTemplate',
  components: {
    ModuleAccess,
    'grid-layout': VueGridLayout.GridLayout,
    'grid-item': VueGridLayout.GridItem,
    'el-tree': Tree,
  },
})
export default class extends Vue {
  @Prop() templateId!: string;

  pageNoWidget: any = PAGE_NO_WIDGET;
  pageNoWidgetFlag: boolean = false;
  loadingPage: boolean = false;
  loadingLayout: boolean = false;
  loadingModules: boolean = false;
  colNum: number = 12;

  treeData: any = [];
  highlightCode: string = '';
  pages: any = [];
  layout: IncludedWidget[] = [];
  currentModule: string = '';

  @Watch('pages')
  listenLoadPages() {
    this.$nextTick(() => {
      this.renderPageBg(0);
    });
  }

  // handleEdit(){
  //     this.$router.push(`/dashboard-conf/edit/${this.id}`)
  // }

  async handleNodeClick(node: any) {
    if (node.children.length === 0) {
      this.loadingPage = true;
      const res = await getPagesByModuleCode(this.templateId, node.name);
      let resTmp = res.data;
      this.pages = resTmp;

      this.loadingPage = false;

      this.handlePageClick(0, resTmp[0]?.code);
    }
  }

  cancelModulePageCheckboxBgColor() {
    var modulePageCheckbox =
      document.getElementsByClassName('module-page-title');
    Array(...modulePageCheckbox).forEach((item: any) => {
      item.style.backgroundColor = 'transparent';
    });
  }

  renderPageBg(index: number) {
    this.cancelModulePageCheckboxBgColor();
    var modulePageCheckbox: any =
      document.getElementsByClassName('module-page-title');
    if (modulePageCheckbox[index]) {
      modulePageCheckbox[index].style.backgroundColor = 'var(--Main)';
    }
  }

  handlePageClick(index: number, pageCode: string) {
    /** background */
    this.renderPageBg(index);

    this.fetchWidgets(pageCode);
  }

  setCurrentModule(moduleObj: any) {
    if (moduleObj.children.length > 0) {
      this.setCurrentModule(moduleObj.children[0]);
    } else {
      this.currentModule = moduleObj.name;
    }

    this.$nextTick(() => {
      (this.$refs.testTree as any).setCurrentKey(this.currentModule);
    });

    this.loadingModules = false;

    this.handleNodeClick({ name: this.currentModule, children: [] });
  }

  async fetchModules(id: string, companyType: COMPANY_TYPE) {
    this.loadingModules = true;

    const res = await getModules(id);
    if (res.data.length > 0) {
      const filteredModules = filterModulesForCompanyType(
        res.data,
        companyType
      );
      this.treeData = modulesToTreeData(filteredModules);
      this.setCurrentModule(this.treeData[0]);
    }
  }

  async fetchWidgets(pageCode: string) {
    if (this.pageNoWidget.includes(pageCode)) {
      this.pageNoWidgetFlag = true;
      this.layout = [];
    } else {
      this.pageNoWidgetFlag = false;
      this.loadingLayout = true;
      this.colNum = PAGE_LAYOUT_MAP.get(pageCode)?.column ?? 12;

      const res = await getWidgetsByPageCodeAndTemplateId(
        pageCode,
        this.templateId
      );
      this.layout = scaleHorizontal(
        res.data,
        NUMBER_OF_GRID_COLUMNS,
        this.colNum
      );

      this.loadingLayout = false;
    }
  }
}
