import { render, staticRenderFns } from "./KpiTargetTable.vue?vue&type=template&id=16be9e47&scoped=true&"
import script from "./KpiTargetTable.vue?vue&type=script&setup=true&lang=ts&"
export * from "./KpiTargetTable.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./KpiTargetTable.vue?vue&type=style&index=0&id=16be9e47&prod&scoped=true&lang=css&"
import style1 from "./KpiTargetTable.vue?vue&type=style&index=1&id=16be9e47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16be9e47",
  null
  
)

export default component.exports