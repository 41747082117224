
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';
import { ElTable } from 'element-ui/types/table';
import {
  ASSET_CONNECT_STATUS,
  GENERAL_QUERY_OPERATORS,
  REGISTERED_ASSET_CONNECTION_STATUSES,
  ALL_CLAIMS_CODES,
} from '@/utils/workData/lookuptable';
import { Ref, unref } from 'vue';
import { ActiveContext, useActiveContext } from '@/composables/context';

@Component({
  name: 'PureTable',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop({ default: true }) showPage!: boolean;
  @Prop() treeProp!: any;
  @Prop() cols!: [];
  @Prop() total!: number;
  @Prop() viewPagePath!: string;
  @Prop({ default: 'id' }) viewPathProperty!: string;
  @Prop() actionTable!: string;
  @Prop() maxHeight!: string;
  @Prop({ default: false }) selectable!: boolean;
  @Prop() editing!: any;
  @Prop({ default: false }) groupedSelection!: boolean;
  @Prop({ default: false }) markItemWhenClickOnRow?: boolean;
  @Prop({ default: false }) shouldAllBeExpanded?: boolean;

  list: any = [...this.tableList];
  tableComponentKey: string = new Date().toString();
  dropCols: any = [];
  assets: any[] = [];
  context!: Ref<ActiveContext>;
  ALL_CLAIMS_CODES = ALL_CLAIMS_CODES;
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  filter: any = {
    filters: [
      {
        name: 'ownerOrganizationId',
        operator: GENERAL_QUERY_OPERATORS.Equal,
        value: [this.$route.params.customerId],
      },
    ],
    sorters: [
      {
        field: 'assetType',
        order: 'asc',
      },
    ],
    pagination: {
      page: '1',
      size: '10',
    },
  };

  @Watch('tableList')
  onListChange() {
    this.list = [...this.tableList];
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  created() {
    this.context = useActiveContext();
  }

  mounted() {
    this.columnDrop();
    this.toggleSelection(this.assets);
  }

  /**
    all the action buttons call the same function, the event name depends on the item param
    * @param item
    * @param row
    */
  handleActionBtnClick(item: string, row: any) {
    this.$emit(item, row);
  }

  /**
   * Handle row click, should follow the next steps of actions:
   *  - mark item when click should be first action
   *  - return a certain route accordingly with row property or this child property
   * @param row
   * @param column
   * @param event
   */
  handleRowClick(row: any, column: any, event: any) {
    // !Important! > First step as higher priority > mark/select element by click on the row if the parent component requires it
    if (this.markItemWhenClickOnRow) {
      if (!this.assets.includes(row.id)) {
        this.assets.push(row.id);
        this.handleRowToggle(row);
      } else {
        this.assets.splice(this.assets.indexOf(row.id), 1);
        this.handleRowToggle(row);
      }
      this.$emit('updateSelectedAssets', this.assets);
      return;
    }

    if (this.viewPagePath) {
      this.$router.push(this.viewPagePath + '/' + row[this.viewPathProperty]);
    }

    this.$emit('row-click', row);
  }

  handleSelectionChange(event: any) {
    this.assets = event.map((asset: any) => asset.id);

    if (!this.groupedSelection) {
      this.$emit('updateSelectedAssets', this.assets);
      return;
    }

    let selectedReleases = event.map((asset: any) => {
      return {
        asset: asset.assetUUID,
        releaseUUID: asset.newSystemReleaseUUID,
      };
    });
    let group = selectedReleases.reduce((current: any, next: any) => {
      current[next.releaseUUID] = [...(current[next.releaseUUID] || []), next];
      return current;
    }, {});
    this.$emit('updateSelectedAssets', group);
  }

  /** Row toggle handling for row click event */
  handleRowToggle(row: any): void {
    const refOfTable = this.$refs.tableRef as ElTable;
    if (!refOfTable) return;
    refOfTable.toggleRowSelection(row);
  }

  tableRowClassName(tab: any) {
    if (this.assets.find((assetId: any) => assetId === tab.row.id)) {
      return 'selected';
    }
  }

  toggleSelection(rows: any) {
    if (rows.length < 1) return;
    const ref = this.$refs.tableRef as ElTable;
    if (!ref) return;
    rows.forEach((row: any) => {
      ref.toggleRowSelection(row);
    });
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = 'ASC';
    } else if (val.order === 'descending') {
      sortType = 'DESC';
    }

    this.$emit('handle-sort-change', val.prop, sortType);
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  columnDrop() {
    var that = this;
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        let oldMoveItem = this.dropCols[evt.oldIndex];
        let newMoveItem = this.dropCols[evt.newIndex];

        let oldItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === oldMoveItem.prop;
        });
        let newItemIndex = this.cols.findIndex((item: any) => {
          return item.prop === newMoveItem.prop;
        });
        this.cols.splice(newItemIndex, 0, this.cols.splice(oldItemIndex, 1)[0]);
      },
    });
  }

  /**
   * Connection asset from table
   */
  handleConnectBtnClick(rowInfo: any) {
    this.$router.push('/asset-mgmt/connect/edit/' + rowInfo.id);
  }

  showConnectButton(row: any) {
    const connectedStatuses = [
      REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnecting,
      REGISTERED_ASSET_CONNECTION_STATUSES.AssconConnected,
      ASSET_CONNECT_STATUS.AssconAssigned,
      ASSET_CONNECT_STATUS.AssconConnected,
    ];
    const rowStatus = row['connectionStatus'] || row['connectStatus'];
    return !connectedStatuses.includes(rowStatus);
  }

  checkClaim(claim: string) {
    return unref(this.context).claims.hasClaim(claim);
  }
}
