
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CommFormInput',
})
export default class extends Vue {
  @Prop({ default: '' }) value!: string;
  @Prop() placeholder!: string;

  inputChange(val: string) {
    this.$emit('input', val);
  }

  get modelValue() {
    return this.value;
  }

  set modelValue(newValue: string) {
    this.$emit('el-input', newValue);
  }
}
