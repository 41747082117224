
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'TransferList',
})
export default class extends Vue {
  @Prop({ required: true }) transferListData!: any;
  @Prop({ required: true }) triggerSource!: any;
  @Prop({ required: true }) isConductMaintenanceActiv!: boolean;
  @Prop() isViewMode!: boolean;
  @Prop() isHistory!: boolean;

  handleMoveToReplaceList(index: number, itemId: any) {
    if (!this.isConductMaintenanceActiv) {
      return;
    }

    let itemToMove: any = this.transferListData?.check.find(
      (item: any) => item.id === itemId
    );
    this.transferListData?.replace.unshift(itemToMove);
    this.transferListData?.check.splice(index, 1);
  }

  handleMoveToCheckList(index: number, itemId: any) {
    if (!this.isConductMaintenanceActiv) {
      return;
    }

    let itemToMove: any = this.transferListData?.replace.find(
      (item: any) => item.id === itemId
    );
    this.transferListData?.check.unshift(itemToMove);
    this.transferListData?.replace.splice(index, 1);
  }

  get isInReadOnly() {
    return this.triggerSource === 'new' || !this.isConductMaintenanceActiv;
  }
}
