
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'Status',
})
export default class extends Vue {
  @Prop() status!: any;

  isActive: boolean = false;

  created() {
    this.isActive =
      this.status.value !== 'RELSTAT_OUTDATED' &&
      this.status.value !== 'RELSTAT_DEPRECATED';
  }
}
