import { getRequest } from '@/utils/request';
import { AssetType, LANGUAGE, WarningType } from '@/utils/workData/lookuptable';
import { ActiveContext } from '@/composables/context';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { CommonResultPromise } from './commonResult';

export function getHealthStatusFactorDefinitions(
  assetId: string,
  context: ActiveContext,
  language?: string,
  requestData?: QueryParameter,
  request = getRequest()
): CommonResultPromise<HealthFactorDefinitionResponse[]> {
  const response = request.post<HealthFactorDefinitionResponse[]>(
    `health-status/asset/${assetId}/factor-conditions`,
    requestData,
    {
      params: {
        i18nCode: language,
        selectedCustomer: context?.selectedCompany,
      },
    }
  );
  return response;
}

export function getHealthStatusGroupedByAssetType(
  assetType: AssetType,
  context: ActiveContext,
  requestData?: QueryParameter,
  language?: LANGUAGE,
  request = getRequest()
): CommonResultPromise<HealthStatusGroupByAssetTypeResponse> {
  const response = request.post<HealthStatusGroupByAssetTypeResponse>(
    `health-status/asset-type/${assetType}/grouped-by-status`,
    requestData,
    {
      params: {
        i18nCode: language,
        selectedCustomer: context?.selectedCompany,
      },
    }
  );
  return response;
}

export function getHealthFactorDefinitions(
  data: any,
  language?: LANGUAGE
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `health-status/factor-definitions`,
    method: 'post',
    data: data,
    params: {
      i18nCode: language,
    },
  });
  return response;
}

export function putHealthFactorDefinitions(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `health-status/factor-definitions`,
    method: 'put',
    data: data,
  });
  return response;
}

export interface HealthStatusGroupByAssetTypeResponse {
  assetsByStatus: {
    assets: {
      assetId: string;
      assetDataCollectionId: string;
      companyAssetId: string;
      productModelPicturePath: string;
      productModelCode: string;
      productModelNumber: string;
      organizationId: string;
      organizationName: string;
      healthStatusLastChangeTimestamp: string;
    }[];
    status: string;
  }[];
  totalAssetCount: number;
}

export interface HealthFactorDefinitionResponse {
  code?: string;
  isErrorFlag?: boolean;
  healthCondition?: WarningType;
  healthConditionDescription?: string;
  lastChangeTimestamp?: string;
  limitCheck?: string;
  name?: string;
}
