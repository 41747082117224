
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

interface Asset {
  id: string;
  name: string;
  selected: boolean;
  visible: boolean;
}

@Component({
  name: 'MultiAssetSelect',
})
export default class extends Vue {
  @Prop() selectedAssetList: Asset[] = [];

  // el-select props
  selectedAssetsOptions: string[] = [];
  assetSearchFieldValue: string = '';

  //el-switch props
  selectedAssetsSwitch: Asset[] = [];

  allDropdownAssetsSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('selectedAssetList')
  handleData(newData: Asset[]): void {
    this.determineAllSelectionState();
    this.selectedAssetsOptions = this.getSelectedAssetsName(newData);
    this.selectedAssetsSwitch = newData;
  }

  /**
   * Determine 'All' btn state by comparing the total number of assets list against which one are selected
   */
  determineAllSelectionState(): void {
    this.allDropdownAssetsSelected =
      this.selectedAssetList?.length ===
      this.selectedAssetList.filter((item) => item.selected)?.length;
  }

  /**
   * Filter selected assets then return the name of those objects into the main input element
   * @param assetsList
   */
  getSelectedAssetsName(assetsList: Asset[]): string[] {
    const selectedAssets: Asset[] = assetsList.filter(
      (asset: Asset) => asset.selected
    );
    return selectedAssets.map((obj: Asset) => obj.name);
  }

  /**
   * Handle asset search in drop down list with following behavior:
   * If there is no input value, keep original options in the list
   * If there is a match, display only those options
   * If there is no match keep the original options visible
   */
  handleAssetDropdownSearch(): void {
    if (!this.assetSearchFieldValue) {
      this.selectedAssetsSwitch = this.selectedAssetList;
      return;
    }
    const result = this.selectedAssetList.filter((asset: Asset) =>
      asset.name.includes(this.assetSearchFieldValue)
    );
    this.selectedAssetsSwitch =
      result && result.length > 0 ? result : this.selectedAssetList;
  }

  /**
   * Handle asset dropdown changes
   */
  async assetDropdownChanged(): Promise<void> {
    this.selectedAssetsSwitch.forEach(
      (item: Asset) =>
        (item.selected = this.selectedAssetsOptions.includes(item.name))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedAssetsSwitch.filter((asset: Asset) => asset.selected)
    );
  }

  /**
   * Toggle all dropdown assets selection
   */
  async toggleAllDropdownAssetsSelected(): Promise<void> {
    this.allDropdownAssetsSelected = !this.allDropdownAssetsSelected;
    this.selectedAssetsSwitch.forEach(
      (item: Asset) => (item.selected = this.allDropdownAssetsSelected)
    );
    this.selectedAssetsOptions = this.selectedAssetsSwitch
      .filter((item: Asset) => item.selected)
      .map((item: Asset) => item.name);
    this.$emit(
      'filterData',
      this.selectedAssetsSwitch.filter((asset: Asset) => asset.selected)
    );
  }
}
