
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { UserModule } from '@/store/modules/user';
import Pagination from '@/components/pagination/Pagination.vue';
import Sortable from 'sortablejs';
import TripLegend from '@/components/trip/TripLegend.vue';
import {
  ColumnRendering,
  ColumnRenderType,
  TableColumn,
} from '@/utils/types/columnCustomizationTypes';
import CustomTableCell from '@/components/table/cells/CustomTableCell.vue';
import { AssetType } from '@/utils/workData/lookuptable';
import { SorterOrder } from '@/model/queryParameters/QueryParameter';
import { TripResponse } from '@/api/trip';

@Component({
  name: 'UtilTable',
  components: {
    TripLegend,
    Pagination,
    CustomTableCell,
  },
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() treeProp!: any;
  @Prop() cols!: TableColumn[];
  @Prop() total!: number;
  @Prop() pageTotal!: number;
  @Prop() statisticsTips!: string;
  @Prop() createPagePath!: string;
  @Prop() createBtnName!: string;
  @Prop() viewPagePath!: string;
  @Prop({ default: true }) showTableHeaderOptions!: boolean;
  @Prop({
    default: () => {
      return [];
    },
  })
  customRendering!: Record<string, ColumnRendering>;
  @Prop() type!: AssetType;
  @Prop() maxHeight?: string;

  list: any = [...this.tableList];

  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };

  visibleCols: TableColumn[] = [];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  @Watch('cols', { deep: true })
  onColsChange(newValue: TableColumn[], oldValue: TableColumn[]) {
    // don't emit cols-changed if no cols were loaded before
    if (oldValue && oldValue.length > 0) {
      this.$emit('cols-change', newValue);
    }
    this.visibleCols = newValue.filter((item: TableColumn) => item.visible);
  }

  handleSortChange(val: any) {
    let sortType = '';

    if (val.order === 'ascending') {
      sortType = SorterOrder.ASC;
    } else if (val.order === 'descending') {
      sortType = SorterOrder.DESC;
    }
    this.$emit('handle-sort-change', val.prop, sortType);
  }

  handleRowClick(row: any, column: any, event: any) {
    this.$emit('row-click', row);
  }

  getPageList(val: any) {
    this.$emit('handle-pagination', val.page, this.pageSize);
  }

  mounted() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');

    // make the columns sortable
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const newIndex = evt.newIndex;
        const oldIndex = evt.oldIndex;

        // find code of visible cols that were involved in the column re-order
        let col1 = this.visibleCols[newIndex];
        let col2 = this.visibleCols[oldIndex];

        // find index (in this.cols) of visible cols that were involved in the column re-order
        let colIndex1 = this.cols.findIndex(
          (col: any) => col.code === col1.code
        );
        let colIndex2 = this.cols.findIndex(
          (col: any) => col.code === col2.code
        );

        // remove the dragged element from the array and insert it at new index
        // Watch('cols') will emit 'cols-change'
        this.cols.splice(colIndex1, 0, this.cols.splice(colIndex2, 1)[0]);

        // filter resulting cols array by visibility
        this.visibleCols = this.cols.filter(
          (item: TableColumn) => item.visible
        );

        // assign new order value to columns
        this.visibleCols.forEach((col, index) => (col.order = index));
      },
    });
  }

  getRenderingOptions(code: string) {
    return this.customRendering[code] || { type: ColumnRenderType.Generic };
  }

  getOverflowOption(code: string) {
    const overflow = this.getRenderingOptions(code).overflow;

    return overflow ?? true;
  }

  $refs!: {
    utilTable: any;
  };

  /**
   * Highlight row
   * @param row
   */
  highlightRow(row: TripResponse) {
    this.$refs.utilTable.setCurrentRow(row);
  }

  /**
   * Select page number
   * @param pageNumber
   */
  setPage(pageNumber: number): void {
    this.listQuery.page = pageNumber;
  }
}
