
import { Event } from '@/api/geofenceTypes';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import safetyIcon from '@/icons/svg/safestate.svg';
import overloadIcon from '@/icons/svg/overload.svg';
import sideloadWarningIcon from '@/icons/svg/sideloadwarning.svg';
import sideloadDangerIcon from '@/icons/svg/sideloaddanger.svg';
import bucklingIcon from '@/icons/svg/buckling.svg';
import { assetTypeIcons, EVENT_CODES } from '@/utils/workData/lookuptable';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';

@Component({
  name: 'LeafletMapEvents',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    Vue2LeafletMarkerCluster,
    EventDetailsModal,
  },
})
export default class extends Vue {
  @Prop() events!: Array<Event>;
  @Prop() selectedAsset!: string;
  safetyEvents: Array<Event> = [];
  eventCodes = EVENT_CODES;
  isEventDetailsModalVisible: boolean = false;
  selectedEventId: string = '';
  eventTypes: string[] = [
    'safety',
    'overload',
    'sideloadWarning',
    'sideloadDanger',
    'buckling',
  ];

  @Watch('events')
  handleData(newValue: any) {
    if (newValue) {
      this.getSafeyEvents(newValue);
    }
  }

  @Watch('selectedAsset')
  handleSelectedAsset(newData: any) {
    this.selectedAsset = newData;
  }

  created() {
    this.getSafeyEvents(this.events);
  }

  getSafeyEvents(events: Event[] = []) {
    this.safetyEvents = [];
    this.eventTypes.forEach((event: string) => {
      this.safetyEvents = [
        ...this.safetyEvents,
        ...events.filter(
          (o: any) =>
            o.eventTypeCode ===
            this.eventCodes[event as keyof typeof EVENT_CODES]
        ),
      ];
    });
  }

  getIcon(eventType: string) {
    const icons = {
      safety: safetyIcon,
      overload: overloadIcon,
      sideloadWarning: sideloadWarningIcon,
      sideloadDanger: sideloadDangerIcon,
      buckling: bucklingIcon,
    };
    const iconKey = Object.keys(this.eventCodes).find(
      (key) => this.eventCodes[key as keyof typeof EVENT_CODES] === eventType
    );
    return icons[iconKey as keyof typeof icons];
  }

  openEventInfo(id: string) {
    this.selectedEventId = id;
    this.isEventDetailsModalVisible = true;
  }

  handleQuitEventDetailsDialog() {
    this.isEventDetailsModalVisible = false;
  }
}
