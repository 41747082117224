
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  HEALTH_STATUS_COLOR,
  LANGUAGE,
  WARNING_TO_HEALTH_STATUS_COLOR,
  WarningType,
} from '@/utils/workData/lookuptable';
import { HealthStatus } from '@/utils/types/health';
import { getHealthStatusFactorDefinitions } from '@/api/healthStatus';
import moment from 'moment';
import { UserModule } from '@/store/modules/user';
import { ActiveContext, useActiveContext } from '@/composables/context';
import { Ref, unref } from 'vue';
import { HealthFactorDefinitionResponse } from '@/api/healthStatus';

type StatusWithEnable = { status: HealthStatus; isEnabled: boolean };

const warningOrder = [
  WarningType.Alarm,
  WarningType.Warning,
  WarningType.Normal,
];

@Component({
  name: 'AssetHealthFactorList',
})
export default class extends Vue {
  @Prop() currentAsset!: string;

  /** Local variables */
  data: HealthFactorDefinitionResponse[] = [];
  listData: HealthFactorDefinitionResponse[] = [];
  context!: Ref<ActiveContext>;
  isFactorDefinitionLoading: boolean = false;

  //TODO: translate
  statusWithEnableList: StatusWithEnable[] = Object.entries(
    HEALTH_STATUS_COLOR
  ).map(([key, value]) => ({
    status: { name: key, color: value },
    isEnabled: true,
  }));

  async created() {
    this.context = useActiveContext();
    await this.getData();
  }

  async getData() {
    this.isFactorDefinitionLoading = true;
    const activeContext: ActiveContext = unref(this.context);
    const healtsStatusFactors = await getHealthStatusFactorDefinitions(
      this.currentAsset,
      activeContext,
      UserModule.i18nCode
    );
    this.data = healtsStatusFactors.data;
    this.data.forEach(
      (entry) =>
        (entry.lastChangeTimestamp = moment
          .utc(entry.lastChangeTimestamp)
          .format('YYYY/MM/DD HH:mm:ss'))
    );
    this.updateData();
  }

  @Watch('currentAsset')
  async currentAssetChanged(newValue: string, oldValue: string) {
    this.currentAsset = newValue;

    await this.getData();
  }

  /** Update requested data */
  updateData() {
    this.listData = this.data
      .filter(({ healthCondition }) => {
        const index = Object.values(WarningType).indexOf(healthCondition!);
        return this.statusWithEnableList[index].isEnabled;
      })
      .sort((a, b) => {
        let order =
          warningOrder.indexOf(a.healthCondition!) -
          warningOrder.indexOf(b.healthCondition!);

        if (order != 0) return order;

        // some order index, should be sorted by dates instead
        return (
          new Date(b.lastChangeTimestamp!).getTime() -
          new Date(a.lastChangeTimestamp!).getTime()
        );
      });
    this.isFactorDefinitionLoading = false;
  }

  async toggleStatus(index: number) {
    this.statusWithEnableList[index].isEnabled =
      !this.statusWithEnableList[index].isEnabled;
    this.updateData();
  }

  statusColorFromCondition(warning: WarningType): string {
    return WARNING_TO_HEALTH_STATUS_COLOR[warning];
  }
}
