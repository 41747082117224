import { getRequest } from '@/utils/request';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { CommonResultPromise } from './commonResult';

export function getAllServiceRegions(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/service-regions',
    method: 'post',
    data,
  });
  return response;
}

export function getAccessibleServiceRegions(
  queryParameter: QueryParameter
): CommonResultPromise<AccessibleServiceRegionResponse[]> {
  const request = getRequest();
  const response = request.post<AccessibleServiceRegionResponse[]>(
    '/organizations/accessible-service-regions',
    queryParameter
  );
  return response;
}

export function addServiceRegionsToOrganization(
  orgId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations/${orgId}/accessible-service-regions`,
    method: 'post',
    data,
  });
  return response;
}

export interface AccessibleServiceRegionResponse {
  accessibleServiceRegions: AccessibleServiceRegionsField[];
  companyId: string;
  organizationId: string;
}

export interface AccessibleServiceRegionsField {
  code: string;
  name: string;
}

/**
 * Get not allocated accessible service region withing company id hierarchy
 * - loggic handled by API (VAS)
 * - available only for helpdesk requester token
 * @returns
 */
export function getNotAllocatedAccessibleServiceRegions(): CommonResultPromise<
  NotAllocatedAccessibleServiceRegionsResponse[]
> {
  const request = getRequest();
  const response = request.get<NotAllocatedAccessibleServiceRegionsResponse[]>(
    '/organizations/not-allocated-accessible-service-regions'
  );
  return response;
}

export interface NotAllocatedAccessibleServiceRegionsResponse {
  id?: string;
  serviceRegionCode: string;
  serviceRegionName: string;
  deploymentRegionCode?: string;
}
