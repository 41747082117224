import { getRequest } from '@/utils/request';
import { IActivationStatus } from './types';
import {
  USER_MANAGEMENT_ACTIVATION_STATUSES,
  COMPANY_TYPE,
  ALL_CLAIMS_CODES,
} from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';
import { PolicyType } from './policies';

export enum UserConsentStatus {
  CONSENTED = 'PLCS_CONSENTED',
  NOT_CONSENTED = 'PLCS_NOT_CONSENTED',
  CONSENT_REFUSED = 'PLCS_CONSENT_REFUSED',
}

export interface UserConsent {
  policyType: PolicyType;
  consentStatus?: UserConsentStatus;
  consentTime?: string;
  policyVersionId: string;
  policyVersion: string;
}

/**
 * Collection of user's current response (agree/disagree) to each required consent policy type.
 */
export type UserConsentStatuses = Partial<
  Record<PolicyType, UserConsentStatus>
>;

/**
 * Collection of required policies that user will need to consent to,
 * and the user's current consent status of them, if any.
 */
export type UserConsents = Partial<Record<PolicyType, UserConsent>>;

export function getUsers(filter: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users${filter}`,
    method: 'get',
  });
  return response;
}

export function getUserById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'get',
  });
  return response;
}

export function createUser(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users`,
    method: 'post',
    data,
  });
  return response;
}

export function deleteUser(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'delete',
  });
  return response;
}

export function updateUser(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}`,
    method: 'put',
    data,
  });
  return response as any as CommonResultPromise;
}

export function getUserSettingById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/setting`,
    method: 'get',
  });
  return response;
}

export async function getUserConsents(id: string): Promise<UserConsents> {
  const request = getRequest();
  const response = await request.get<UserConsent[]>(`/user-consent/${id}`);
  return Object.fromEntries(
    response.data.map((userConsent) => [userConsent.policyType, userConsent])
  );
}

export async function updateUserConsent(
  userId: string,
  userConsentStatuses: UserConsentStatuses
): Promise<UserConsents> {
  const request = getRequest();
  const response = await request.post<UserConsent[]>(
    `user-consent/${userId}`,
    userConsentStatuses
  );
  return Object.fromEntries(
    response.data.map((userConsent) => [userConsent.policyType, userConsent])
  );
}

export function updateUserSetting(
  userUUID: string,
  requestBody: UserSettings,
  request = getRequest()
): CommonResultPromise<UserSettings> {
  const response = request.put<UserSettings>(
    `/users/${userUUID}/setting`,
    requestBody
  );
  return response;
}

export function resetPassword(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/password:reset`,
    method: 'put',
    data,
  });
  return response;
}

export function getPermissions(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `users/permissions`,
    method: 'get',
  });
  return response;
}

export function updateLastLoginTime(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/lastlogintime`,
    method: 'put',
  });
  return response;
}

export function updateActivationStatus(
  id: string,
  data: IActivationStatus
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/users/${id}/activation`,
    method: 'put',
    data,
  });
  return response;
}

export function validateToken(params: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/user/password:validatetoken',
    method: 'post',
    params,
  });
  return response;
}

export function getModules(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/authorizableResources',
    method: 'get',
  });
  return response;
}

export function copyUserTemplateFromCompany(
  userId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `users/${userId}/template/copy`,
    method: 'post',
  });
  return response;
}

export type SearchHistoryEntry = {
  order: number;
  name: string;
};

export type SearchHistoryResponse = {
  code: number;
  data: SearchHistoryEntry[];
  errors: any;
};

export function getUserSearchHistory(): CommonResultPromise<SearchHistoryResponse> {
  const request = getRequest();
  const response = request.get<SearchHistoryResponse>(
    '/user-settings/searched-asset-names'
  );
  return response;
}

export function saveUserSearchHistory(
  history: SearchHistoryEntry[]
): CommonResultPromise {
  const request = getRequest();
  const response = request.post('/user-settings/searched-asset-names', history);
  return response;
}

export interface User {
  id: string;
  activationStatus: typeof USER_MANAGEMENT_ACTIVATION_STATUSES;
  claims: Claim[];
  companyId: string;
  companyType: typeof COMPANY_TYPE;
  createdOn: string;
  email: string;
  emailVerified: boolean;
  i18nCode: string;
  modules: Module[];
  organizationId: string;
  organizationName: string;
  pages: Page[];
  role: string;
  roleName: string;
  setting: Setting;
  tenantId: string;
  theme: number;
  username: string;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  phone?: string;
  note?: string;
}

export interface Claim {
  actions: string[];
  resource: string;
}

export interface Module {
  id: string;
  code: ALL_CLAIMS_CODES;
  parentCode: string;
}

export interface Page {
  id: string;
  code: string;
  parentCode: string;
}

export interface Setting {
  userId: string;
  datetimeFormat: string;
  gridPageSize: number;
  timeZone: string;
}

export interface UserSettings {
  id?: string;
  userId: string;
  i18nCode: string;
  timeZone?: string;
  datetimeFormat?: string;
  gridPageSize?: string;
  currency?: string;
  companyId?: string;
}
