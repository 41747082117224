import {
  getCurrentInstance,
  onUnmounted,
  Ref,
  ref,
  watch,
  computed,
  unref,
} from 'vue';
import VueRouter, { Route, RouteMeta } from 'vue-router';
import { PageModule } from '@/store/modules/page';

export function useRouter(): VueRouter {
  const instance = getCurrentInstance();

  if (!instance) {
    throw Error(
      'cannot get router reference: cannot determine Vue instance context'
    );
  }

  return instance.proxy.$router;
}

export function useRoute(): Ref<Route> {
  const router = useRouter();
  const route = ref(router.currentRoute);
  const callback = ref(router.afterEach((to) => (route.value = to)));

  onUnmounted(() => callback.value());

  return route;
}

export function useBreadcrumbs(
  crumbs: Ref<undefined | string | string[]>
): void {
  const route = useRoute();

  watch(
    [route, crumbs],
    ([nextRoute, nextCrumbs], [previousRoute]) => {
      if (!nextCrumbs) {
        return;
      }

      const array = Array.isArray(nextCrumbs) ? nextCrumbs : [nextCrumbs];

      if (nextRoute.path === previousRoute?.path || !previousRoute?.path) {
        PageModule.setTitle([...PageModule.title, ...array]);
      }
    },
    { immediate: true }
  );
}

/**
 * Shallow merge all meta data of each matching route record of the route.
 *
 * I.e. all meta keys from parents down to children will be merged, where
 * keys from children override those from the parent.
 */
export function useMergedRouteMeta(
  route: Ref<Route> | Route = useRoute()
): Ref<RouteMeta> {
  return computed(() =>
    unref(route).matched.reduce(
      (merged, routeRecord) => ({ ...merged, ...routeRecord.meta }),
      {}
    )
  );
}
