import { getRequest } from '@/utils/request';
import {
  MODEL_PART_TYPE_ENUM,
  PRODUCT_MODEL_STATUS_ENUM,
} from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export function getProductModels(): CommonResultPromise<ProductModel[]> {
  const request = getRequest();
  const response = request.get<ProductModel[]>('/productModels');
  return response;
}

export function getFilterProductModels(
  requestUrl: string
): CommonResultPromise<ProductModel[]> {
  const request = getRequest();
  const response = request.get<ProductModel[]>(`/productModels${requestUrl}`);
  return response;
}

export function getProductModelById(
  productModelId: string
): CommonResultPromise<ProductModel> {
  const request = getRequest();
  const response = request.get<ProductModel>(
    `/productModels/${productModelId}`
  );
  return response;
}

export function updateProductModel(
  productModelId: string,
  requestBody: FormData
): CommonResultPromise<ProductModel> {
  const request = getRequest();
  const response = request.put<ProductModel>(
    `/productModels/${productModelId}`,
    requestBody
  );
  return response;
}

export function createProductModel(
  requestBody: FormData
): CommonResultPromise<ProductModel> {
  const request = getRequest();
  const response = request.post<ProductModel>('/productModels', requestBody);
  return response;
}

export function getPartMaterials(
  partMaterialId?: string | null
): CommonResultPromise<PartMaterial> {
  const request = getRequest();
  const response = request.get<PartMaterial>(
    partMaterialId ? `/partMaterials/${partMaterialId}` : '/partMaterials'
  );
  return response;
}

export function getFilterPartMaterials(
  requestUrl: string
): CommonResultPromise<PartMaterial[]> {
  const request = getRequest();
  const response = request.get<PartMaterial[]>(`/partMaterials${requestUrl}`);
  return response;
}

export function createPartMaterial(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/partMaterials',
    method: 'post',
    data,
  });
  return response;
}

export function updatePartMaterial(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/partMaterials/${id}`,
    method: 'put',
    data,
  });
  return response;
}

export function getProperties(
  entityTypeCode: string,
  assetType: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/properties?entityType=${entityTypeCode}&assetType=${assetType}`,
    method: 'get',
  });
  return response;
}

export function batchApprovePart(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/partMaterials:approve',
    method: 'post',
    data,
  });
  return response;
}

export interface PartMaterial {
  id: string;
  code: string;
  name: string;
  partMaterialNumber: string;
  partType: typeof MODEL_PART_TYPE_ENUM;
  lifeCycle: typeof PRODUCT_MODEL_STATUS_ENUM;
}

export interface ProductModel {
  id: string;
  code: string;
  modelNumber: string;
  assetTypeId: string;
  assetTypeCode: string;
  lifeCycle: string;
  numberOfParts: string;
  picturePath?: string;
  properties?: Property[];
  containedParts?: ContainedPart[];
  action?: string[]; // used for UI table column to populate additionally with action type
  errors?: any[];
}

export interface Property {
  code: string;
  isPreDefined: boolean;
  keyId: string;
  name: string;
  value: string;
  unit: string;
  unitText?: string; // used for DOM display
}

export interface ContainedPart {
  id: string;
  code: string;
  isMaintainable: boolean | string; // from API boolean, in UI string
  lifeTime: number | string; // from API number, in UI concatenation of strings for view mode
  lifeTimeUnit: string;
  name: string;
  partMaterialId: string;
  partMaterialName: string;
  partMaterialNumber: string;
  partType: string;
}

export interface ModelSpecsNewProperty {
  name: string;
  unit: string;
  value: string;
}

export type CreateUpdateModalSpecsProperty =
  | { propertyKeyId: string; value: string }
  | {
      id: string;
      value: string;
    }
  | {
      id: string;
      value: string;
      unit: string;
      name: string;
    };
