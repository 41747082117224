
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING,
  DEPLOYMENT_REGION_LIST,
  REGION_LIST,
  COMPANY_TYPE_LIST,
  ASSET_TYPE_LIST,
  REGISTERED_ASSET_CONNECTION_STATUSES_LIST,
  USER_MANAGEMENT_ACTIVATION_STATUSES_LIST,
  TIME_UNITS_LIST,
  LOCK_STATUSES_LIST,
  ALARM_CONFIG_METRIC_UNITS_LIST,
  ACCESS_MANAGEMENT,
  SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST,
  MAINTENANCE_ITEM_STATUS_LIST,
} from '@/utils/workData/lookuptable';
import { GENERAL_QUERY_OPERATORS } from '@/utils/workData/lookuptable';
import { disabledFutureDate } from '@/utils/date';
import { getReportTemplates } from '@/api/report';

interface OptionList {
  id: string;
  value: string;
}

@Component({
  name: 'SelectTableHeader',
})
export default class extends Vue {
  @Prop({ required: false, default: true }) isColumnSelectionVisible!: boolean;
  @Prop({ required: false, default: undefined }) assetTypeAvailable!: string[];
  @Prop() searchFieldOptions: any;
  @Prop() searchParams: any;
  @Prop({ required: false }) customerType!: string;
  @Prop() cols!: [];
  @Prop() selectedAssetes!: string[];

  /** For checking selected rows */
  unwatch: any = null;
  selectedRows: string[] = [];
  addRemoveAccess: boolean = false;

  @Watch('customerType')
  handleCustomerType(newData: string, oldData: string) {
    if (newData) {
      this.setAddRemoveAccess();
    }
    return;
  }

  /** Local Variables */
  selectionWithDropDownSearchOptions: any = [
    // Search options with custom selection or predefined values of code !
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByDeploymentRegion,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCompanyType,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByAssetType,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByInstallTime,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectionStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByConnectStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCreatedDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByActivationStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByTimeUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByLockStatus,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByMetricUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByUnit,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByManufactureDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByReportFormat,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByReportFrequency,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.maintenanceItemStaus,
  ];
  isSearchFilterWithDateAndTimePicker: any = [
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByInstallTime,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByCreatedDate,
    CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING.searchByManufactureDate,
  ];
  customInputSelectionForSearching: OptionList[] = [];
  customerTypeList: OptionList[] = COMPANY_TYPE_LIST;
  assetTypeList: OptionList[] = ASSET_TYPE_LIST;
  availableAssetTypeList: OptionList[] = [];
  hyvaRegionList: OptionList[] = REGION_LIST;
  deploymentRegionList: OptionList[] = DEPLOYMENT_REGION_LIST;
  customSelection: any = CUSTOM_SELECTION_OPTIONS_FOR_SEARCHING;
  connectionStatusList: OptionList[] =
    REGISTERED_ASSET_CONNECTION_STATUSES_LIST;
  connectStatusList: OptionList[] = REGISTERED_ASSET_CONNECTION_STATUSES_LIST;
  userActivationStatuses: OptionList[] =
    USER_MANAGEMENT_ACTIVATION_STATUSES_LIST;
  maintenanceItemStatus: OptionList[] = MAINTENANCE_ITEM_STATUS_LIST;
  timeUnitsList: OptionList[] = TIME_UNITS_LIST;
  lockStatuses: OptionList[] = LOCK_STATUSES_LIST;
  metricUnitList: OptionList[] = ALARM_CONFIG_METRIC_UNITS_LIST;
  reportFormatList = [] as string[];
  reportFrequencyList: OptionList[] = SUBSCRIBE_REPORT_TEMPLATE_FREQUENCY_LIST;

  created() {
    this.prepareDefaultInitialization();
    this.setAddRemoveAccess();
    this.initializeReportTemplates();
  }

  mounted() {
    this.unwatch = this.$watch('selectedAssetes', (newValue, oldValue) => {
      this.selectedRows = newValue;
    });
    this.unwatch = this.$watch('customerType', (newValue, oldValue) => {
      this.customerType = newValue;
    });
  }

  async initializeReportTemplates() {
    const res = await getReportTemplates();
    if (res.code === 200) {
      this.reportFormatList = res.data;
    }
  }

  setAddRemoveAccess() {
    if (!this.customerType) return;
    if (
      this.customerType === ACCESS_MANAGEMENT.dealer ||
      this.customerType === ACCESS_MANAGEMENT.bodyBuilder
    )
      this.addRemoveAccess = true;
  }

  /**
   * Prepare default initialization
   */
  prepareDefaultInitialization() {
    if (this.assetTypeAvailable) {
      this.availableAssetTypeList = this.assetTypeList.filter(
        (assetItem: OptionList) =>
          this.assetTypeAvailable.some((item: string) => item == assetItem.id)
      );
    } else {
      this.availableAssetTypeList = this.assetTypeList;
    }

    // As default select first element from search options & it's query operator
    this.searchFieldOptions[0]
      ? (this.searchParams.reference = this.searchFieldOptions[0].prop)
      : null;
    this.searchFieldOptions[0]
      ? (this.searchParams.operator = this.searchFieldOptions[0].operator)
      : null;
  }

  /** Trigger search if not empty */
  handleInputEmpty(value: string) {
    if ((value && value.length > 2) || !value) this.handleSearch();
  }

  /** Trigger search event to parent */
  handleSearch() {
    this.$emit('search-event');
  }

  /** Clear the input when selection has changed */
  handleSelectionChange() {
    if (this.searchParams.value) {
      this.searchParams.value = null;
      this.triggerSearchEventToParent();
    }
    let searchReference = this.searchParams.reference;

    /** Set operator from config for each search reference */
    let currentSearchOption = this.searchFieldOptions.find(
      (item: any) => searchReference === item.prop
    );
    this.searchParams.operator =
      currentSearchOption.hasOwnProperty('operator') &&
      currentSearchOption.operator
        ? currentSearchOption.operator
        : GENERAL_QUERY_OPERATORS.Like;

    if (
      this.selectionWithDropDownSearchOptions.includes(
        this.searchParams.reference
      )
    ) {
      switch (searchReference) {
        case this.customSelection.searchByDeploymentRegion:
          this.customInputSelectionForSearching = this.deploymentRegionList;
          break;
        case this.customSelection.searchByCompanyType:
          this.customInputSelectionForSearching = this.customerTypeList;
          break;
        case this.customSelection.searchByAssetType:
          this.customInputSelectionForSearching = this.availableAssetTypeList;
          break;
        case this.customSelection.searchByConnectionStatus:
          this.customInputSelectionForSearching = this.connectionStatusList;
          break;
        case this.customSelection.searchByActivationStatus:
          this.customInputSelectionForSearching = this.userActivationStatuses;
          break;
        case this.customSelection.searchByTimeUnit:
          this.customInputSelectionForSearching = this.timeUnitsList;
          break;
        case this.customSelection.searchByLockStatus:
          this.customInputSelectionForSearching = this.lockStatuses;
          break;
        case this.customSelection.searchByMetricUnit:
          this.customInputSelectionForSearching = this.metricUnitList;
          break;
        case this.customSelection.searchByUnit:
          this.customInputSelectionForSearching = this.metricUnitList;
          break;
        case this.customSelection.searchByReportFormat:
          this.customInputSelectionForSearching = this.reportFormatList.map(
            (rf) => ({
              id: rf,
              value: rf,
            })
          );
          break;
        case this.customSelection.searchByReportFrequency:
          this.customInputSelectionForSearching = this.reportFrequencyList;
          break;
        case this.customSelection.searchByConnectStatus:
          this.customInputSelectionForSearching = this.connectStatusList;
          break;
        case this.customSelection.maintenanceItemStaus:
          this.customInputSelectionForSearching = this.maintenanceItemStatus;
          break;
      }
    }
  }

  /** Triggered for custom input of searching -> code needs to be sent in API */
  handleSelectionInputChange() {
    this.triggerSearchEventToParent();
  }

  /** Handle the trigger event to parent */
  triggerSearchEventToParent() {
    this.$emit('search-event');
  }

  pickerOptions: any = {
    disabledDate: disabledFutureDate,
  };

  handleAddAssets() {
    this.$router.push(`${this.$route.params.customerId}/add`);
  }

  handleRemoveAssets() {
    this.$router.push(`${this.$route.params.customerId}/remove`);
  }
}
