import { computed, inject, InjectionKey, provide, ref, Ref, unref } from 'vue';
import { VERSION } from '@/generated/version'; // This file is generated in package.json, run npm install to generate it
import axios from 'axios';

interface CheckVersion {
  remoteVersion: Ref<string>;
}

const checkVersionKey: InjectionKey<CheckVersion> = Symbol('checkVersionTimer');

/**
 * Instantiate a new timer that periodically retrieves the current frontend server version
 * and provides the version for any child component to be used.
 */
export function provideCheckVersionTimer() {
  const instance: CheckVersion = {
    remoteVersion: ref(VERSION) as Ref<string>,
  };
  async function checkVersion() {
    try {
      const randomPostfix = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
      const res = await axios.get(`/version.txt?${randomPostfix}`); // Make sure requests are not cached by providing a random postfix
      instance.remoteVersion.value = (res.data as string).trim(); // Trim needed because the version.txt includes a trailing newline
    } catch (e) {
      console.warn(
        'Unable to retrieve version.txt from backend. It should have been generated in the prepare or build step, see package.json.'
      );
    }
  }
  setInterval(checkVersion, 1000 * 60 * 15);
  provide(checkVersionKey, instance);
}

/**
 * @returns A boolean ref that indicates whether the frontend server version differs
 *          from the frontend client version.
 */
export function useVersionChanged(): Ref<boolean> {
  const checkVersion = inject(checkVersionKey);
  if (!checkVersion) {
    throw new Error(
      'No CheckVersionTimer provided. Use `provideCheckVersionTimer() somewhere near the root of the app'
    );
  }
  return computed(() => unref(checkVersion.remoteVersion) !== VERSION);
}
