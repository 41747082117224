
import { Component, Prop, Vue } from 'vue-property-decorator';
import { scrollTo } from '@/utils/scrollTo';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'Pagination',
})
export default class extends Vue {
  @Prop({ required: true }) private total!: number;
  @Prop({ default: 1 }) private page!: number;
  @Prop({ default: UserModule.gridPageSize }) private limit!: number;
  @Prop({ default: () => [5, 10, 30, 50] }) private pageSizes!: number[];
  // @Prop({ default: 'total, sizes, prev, pager, next, jumper' }) private layout!: string
  @Prop({ default: 'prev, pager, next' }) private layout!: string;
  @Prop({ default: true }) private background!: boolean;
  @Prop({ default: true }) private autoScroll!: boolean;
  @Prop({ default: false }) private hidden!: boolean;

  get currentPage() {
    return this.page;
  }

  set currentPage(value) {
    this.$emit('update:page', value);
  }

  get pageSize() {
    return this.limit;
  }

  set pageSize(value) {
    this.$emit('update:limit', value);
  }

  handleSizeChange(value: number) {
    this.$emit('pagination', { page: this.currentPage, limit: value });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }

  handleCurrentChange(value: number) {
    this.$emit('pagination', { page: value, limit: this.pageSize });
    if (this.autoScroll) {
      scrollTo(0, 800);
    }
  }
}
