import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export function getSubscriptionManagement(
  customUrl: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscription-management${customUrl}`,
    method: 'get',
  });
  return response;
}

export function renewSubscriptionManagement(
  subscriptionId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscription-management/${subscriptionId}/renew`,
    method: 'post',
  });
  return response;
}

export function terminateSubscriptionManagement(
  subscriptionId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/subscription-management/${subscriptionId}/terminate`,
    method: 'post',
  });
  return response;
}
