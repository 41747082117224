
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'DefaultDialog',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: '60%' }) width!: string;

  handleDialogClose() {
    this.$emit('handle-cancel');
  }
}
