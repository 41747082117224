
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'IconCell',
})
export default class extends Vue {
  @Prop() icon!: { name: string; label: string };

  iconName: string = '';
  iconLabel: string = '';

  created() {
    this.iconName = this.icon?.name;
    this.iconLabel = this.icon?.label;
  }
}
