
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

export interface MaintenanceSelectItem {
  maintenanceItemId: string;
  maintenanceItemName: string;
  selected: boolean;
}

@Component({
  name: 'MultiMaintenanceItemSelect',
})
export default class extends Vue {
  @Prop() maintenanceList: MaintenanceSelectItem[] | undefined;
  @Prop() disabled!: boolean;

  // el-select props
  selectedItemOptions: string[] = [];

  //el-switch props
  selectedItemsSwitch: MaintenanceSelectItem[] = [];

  allDropdownItemsSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('maintenanceList', { immediate: true })
  handleData(newData: MaintenanceSelectItem[] | undefined): void {
    this.determineAllSelectionState();
    if (newData?.length) {
      this.selectedItemOptions = this.getSelectedMaintenanceName(newData);
      this.selectedItemsSwitch = newData;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of items list against which one are selected
   */
  determineAllSelectionState(): void {
    if (!!this.maintenanceList) {
      this.allDropdownItemsSelected =
        this.maintenanceList.length ===
        this.maintenanceList.filter((item) => item.selected)?.length;
    }
  }

  /**
   * Filter selected maintenances then return the name of those objects into the main input element
   * @param list
   */
  getSelectedMaintenanceName(list: MaintenanceSelectItem[]): string[] {
    const selectedItems: MaintenanceSelectItem[] = list?.filter(
      (obj: MaintenanceSelectItem) => obj.selected
    );
    return selectedItems?.map(
      (obj: MaintenanceSelectItem) => obj.maintenanceItemName
    );
  }

  /**
   * Handle item dropdown changes
   */
  async itemDropdownChanged(): Promise<void> {
    this.selectedItemsSwitch.forEach(
      (item: MaintenanceSelectItem) =>
        (item.selected = this.selectedItemOptions.includes(
          item.maintenanceItemName
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedItemsSwitch.filter(
        (item: MaintenanceSelectItem) => item.selected
      )
    );
  }

  /**
   * Toggle all dropdown items selection
   */
  async toggleAllDropdownItemsSelected(): Promise<void> {
    this.allDropdownItemsSelected = !this.allDropdownItemsSelected;
    this.selectedItemsSwitch.forEach(
      (item: MaintenanceSelectItem) =>
        (item.selected = this.allDropdownItemsSelected)
    );
    this.selectedItemOptions = this.selectedItemsSwitch
      .filter((item: MaintenanceSelectItem) => item.selected)
      .map((item: MaintenanceSelectItem) => item.maintenanceItemName);
    this.$emit(
      'filterData',
      this.selectedItemsSwitch.filter(
        (item: MaintenanceSelectItem) => item.selected
      )
    );
  }
}
