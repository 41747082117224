
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'expand-multikpi-number',
})
export default class extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: false }) value: string | number | undefined;
  @Prop({ required: false }) unit: string | undefined;
  @Prop({ required: false }) aveValue: string | number | undefined;
}
