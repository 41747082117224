export const selectedOrgKey = 'selectedOrgKey';

export const getSelectedOrgId = (
  updateOrg: (newValue: string | undefined) => void,
  selectedOrganizationId?: string,
  organizations?: { id: string }[]
) => {
  const sessionSelectedOrgLabel = sessionStorage.getItem(selectedOrgKey);
  if (
    sessionSelectedOrgLabel !== selectedOrganizationId &&
    organizations?.find((org) => org.id === sessionSelectedOrgLabel)
  ) {
    updateOrg(sessionSelectedOrgLabel || selectedOrganizationId);
  } else {
    updateOrg(selectedOrganizationId);
  }

  return selectedOrganizationId;
};
