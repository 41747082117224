import { ActiveContext } from '@/composables/context';
import { QueryParameter } from '@/model/queryParameters/QueryParameter';
import { getRequest } from '@/utils/request';
import {
  AssetType,
  TripStatus,
  TripSubActivity,
} from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export function getTrips(
  data: QueryParameter,
  context?: ActiveContext
): CommonResultPromise<TripsResponse> {
  const request = getRequest();
  const response = request.post<TripsResponse>(
    `/trips`,
    data,
    getRequestConfig(context)
  );
  return response;
}

export function getAssetsWithTrips(
  requestBody: QueryParameter,
  context?: ActiveContext
): CommonResultPromise<AssetWithTripsResponse> {
  const request = getRequest();

  const response = request.post<AssetWithTripsResponse>(
    `/assets-with-trips`,
    requestBody,
    getRequestConfig(context)
  );
  return response;
}

export function getTripTrack(
  tripId: string,
  language?: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/${tripId}/track`,
    method: 'get',
    params: {
      i18nCode: language,
    },
  });
  return response;
}

export function getTripById(
  tripId: string,
  language?: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/${tripId}`,
    method: 'get',
    params: {
      i18nCode: language,
    },
  });
  return response;
}

export function getTripAssetTypes(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/trips/asset-types`,
    method: 'get',
  });
  return response;
}

function getRequestConfig(context: ActiveContext | undefined) {
  return context
    ? {
        params: {
          selectedCustomer: context?.selectedCompany,
        },
      }
    : {};
}

export interface AssetWithTripsResponse {
  assets: TripAsset[];
  size: number;
  total: number;
}

export interface TripAsset {
  assetId: string;
  companyAssetId: string;
}

export interface TripProperties {
  propertyDetails: { code: string; unit: string }[];
  propertyValues: Record<string, string | number | boolean | null>;
}

export interface TripResponse extends TripProperties {
  assetId: string;
  assetType: AssetType;
  companyAssetId: number;
  companyId: string;
  companyName: string;
  endPoint: Point;
  events: {
    eventDescription: string;
    eventName: string;
    eventTimestamp: string;
    eventType: string;
    id: string;
    location: Location;
    speed: number;
  }[];
  id: string;
  organizationId: string;
  organizationName: string;
  startPoint: Point;
  timezone: string;
  tripStatus: TripStatus;
  tripTime: number | undefined;
  tripSubActivities: {
    location: Location;
    subActivityKind: TripSubActivity;
    subActivityStartTimeStamp: string;
  }[];
}

export type TripsResponse = {
  size: number;
  total: number;
  trips: TripResponse[];
};

type Point = {
  city: string;
  location: Location;
  openLocationCode: string;
  street: string;
  timestamp: string;
};

type Location = {
  lat: number;
  lgt: number;
  alt: number;
  sog: number;
};
