
import { Component, Prop, Vue, InjectReactive } from 'vue-property-decorator';
import KpiNumberExpanded from '@/components/widget/kpiNumber/KpiNumberExpanded.vue';
import KpiNumberMultiExpanded from '@/components/widget/kpiNumber/KpiNumberMultiExpanded.vue';
import KpiTable from './KpiTable.vue';

@Component({
  name: 'KpiInfoExpanded',
  components: {
    KpiNumberExpanded,
    KpiNumberMultiExpanded,
    KpiTable,
  },
})
export default class extends Vue {
  @Prop({ required: true }) kpiNumberList!: any;
  @Prop({ default: false }) multiKpi!: boolean;

  @InjectReactive('tableList') tableList!: any;
  @InjectReactive('cols') cols!: any;

  created() {}
}
