
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import echarts from 'echarts';
import ResizeMixin from '@/components/charts/mixins/resize';
import {
  KpiChartDataItem,
  oilLevelValueToTranslatedName,
  valueLimitCheckToColor,
} from '@/utils/kpidata';
import { realTimeKpiColor, KpiLimitAndWarningInfo } from '@/utils/kpidata';
import { takeRight } from 'lodash';

@Component({
  name: 'LineChart',
})
export default class extends mixins(ResizeMixin) {
  @Prop() id!: string;
  @Prop({ default: '100%' }) width!: string;
  @Prop({ default: '200px' }) height!: string;
  @Prop({ required: false }) yAxisUnit!: any;
  @Prop({ required: false }) limitWarningRange:
    | KpiLimitAndWarningInfo
    | undefined;
  @Prop({ required: true }) chartData!: KpiChartDataItem[];
  @Prop({ default: false }) isOilLevel!: boolean;
  @Prop({ default: false }) isPercentage!: boolean;
  @Prop({ default: true }) isRendering!: boolean;
  @Prop({ default: 100 }) showChartsLength!: number; //for the animation, charts only show

  chart: echarts.ECharts | null = null;
  timer: any = null;
  redColor: string = '#E04642';
  yellowColor: string = '#E39D00';
  orangeColor: string = '#DD730A';
  grayColor: string = '#878B8D';
  purpleColor: string = '#8DA0BF';

  @Watch('chartData', { immediate: false, deep: true })
  handleData(newData: any) {
    if (newData && this.isRendering) {
      this.updateChart();
    }
    return;
  }

  @Watch('isRendering')
  handleStopRender(newData: boolean, oldData: boolean) {
    if (newData && !oldData) {
      this.updateChart();
    }
    return;
  }

  get newestChartData() {
    if (this.chartData.length > this.showChartsLength) {
      return takeRight(this.chartData, this.showChartsLength);
    } else {
      return this.chartData;
    }
  }

  mounted() {
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.off('globalout');
  }

  destroyed() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  triggerTooltip(xAxiValue: any) {
    if (!this.chart) {
      return;
    }
    let dataIndex: number = -1;
    this.newestChartData.forEach((item: any, index: number) => {
      if (item[0] === xAxiValue) {
        dataIndex = index;
        return;
      }
    });
    this.chart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: dataIndex,
    });
  }

  hideTooltip() {
    if (!this.chart) {
      return;
    }
    this.chart.dispatchAction({
      type: 'hideTip',
    }); //hide the tooltip box

    this.chart.dispatchAction({
      type: 'updateAxisPointer',
      currTrigger: 'leave',
    }); //hide the tooltip line
  }

  private updateChart() {
    if (!this.chart) {
      return;
    }

    const markAreaConfig: any = [];
    if (this.limitWarningRange) {
      const errorStyle = { color: this.redColor, opacity: 0.21 };
      const warningStyle = { color: this.yellowColor, opacity: 0.21 };
      if (this.limitWarningRange.lowLimit) {
        markAreaConfig.push([
          {
            yAxis: 0,
            itemStyle: errorStyle,
          },
          {
            yAxis: this.limitWarningRange.lowLimit,
          },
        ]);
      }
      if (this.limitWarningRange.lowWarning) {
        markAreaConfig.push([
          {
            yAxis: this.limitWarningRange.lowLimit ?? 0,
            itemStyle: warningStyle,
          },
          {
            yAxis: this.limitWarningRange.lowWarning,
          },
        ]);
      }
      if (this.limitWarningRange.highWarning) {
        markAreaConfig.push([
          {
            yAxis: this.limitWarningRange.highWarning,
            itemStyle: warningStyle,
          },
          {
            yAxis: this.limitWarningRange.highLimit ?? null, // null indicates top of data area
          },
        ]);
      }
      if (this.limitWarningRange.highLimit) {
        markAreaConfig.push([
          {
            yAxis: this.limitWarningRange.highLimit,
            itemStyle: errorStyle,
          },
          {
            yAxis: null, // null indicates top of data area
          },
        ]);
      }
    }

    const tooltipFormatterOptions: echarts.EChartOption.Tooltip.Formatter = (
      datas: any
    ) => {
      if (datas[0].value[0].indexOf('test') === -1) {
        this.$emit('send-xdata', datas[0].value[0]);
        // datas: trigger:item   datas[0]:trigger axis
        const showValue = this.isOilLevel
          ? oilLevelValueToTranslatedName(datas[0].value[1])
          : datas[0].value[1];
        const tooltipBackground = valueLimitCheckToColor(datas[0].value[2]);
        let resHtml = `<div style="color:#FFFFFF;font-size:12px;background:${tooltipBackground};padding: 5px; border-radius:4px;"><p style="text-align:center; margin:0; line-height: 20px"></p>${
          datas[0].value[0]
        }<p style="text-align:center; margin:0; line-height: 20px">${showValue} ${
          this.yAxisUnit ? this.yAxisUnit : ''
        }</p></div>`;
        return resHtml;
      } else {
        this.$emit('hide-tooltip');
        return '';
      }
    };

    const tooltipOptions: echarts.EChartOption.Tooltip = {
      trigger: 'axis',
      formatter: tooltipFormatterOptions,
      padding: 0,
      axisPointer: {
        type: 'line',
        lineStyle: {
          color: this.orangeColor,
        },
      },
      position: (pt: any) => {
        return [pt[0], pt[1]];
      },
    };

    const xAxisOptions: echarts.EChartOption.XAxis = {
      type: 'category',
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: this.grayColor,
        },
      },
      axisLabel: {
        show: false,
      },
      boundaryGap: [0], // Was: 0, but that's not a valid value
    };

    const yAxisOptions: echarts.EChartOption.YAxis = {
      type: 'value',
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: this.grayColor,
        },
      },
      splitLine: {
        show: false, //hide the splitLine in charts
      },
      splitNumber: this.isOilLevel ? 2 : undefined,
      axisLabel: {
        formatter: (value: string | number) => {
          if (this.isOilLevel) {
            return oilLevelValueToTranslatedName(value);
          } else if (this.isPercentage) {
            return `${value}%`;
          } else {
            return value;
          }
        },
      },
    };

    const seriesOptions: echarts.EChartOption.SeriesLine[] = [
      {
        showSymbol: false, //hide the point(symbol) on the line chart
        symbolSize: 3,
        symbol: 'circle',
        data: this.newestChartData,
        type: 'line',
        lineStyle: {
          color: this.purpleColor,
        },
        itemStyle: {
          color: this.orangeColor,
        },
        areaStyle: {
          color: this.purpleColor,
          opacity: 1,
        },
        zlevel: -1,
        markArea: {
          silent: true,
          data: markAreaConfig,
        },
      },
    ];

    this.chart.setOption({
      tooltip: tooltipOptions,
      grid: { top: 20, bottom: 30, left: 50, right: 10 },
      legend: { show: true },
      xAxis: xAxisOptions,
      yAxis: yAxisOptions,
      series: seriesOptions,
    });

    this.chart.off('globalout');
    this.chart.on('globalout', (params: any) => {
      //send this emit to tell father component that the mouse has move out of charts area, then FE should disappear all the tooltips
      this.$emit('hide-tooltip');
    });
  }
}
