
import { Component, Prop, Vue } from 'vue-property-decorator';
import TripTimeline from '@/components/trip/TripTimeline.vue';
import { Trip } from '@/utils/types/trip';

@Component({
  name: 'TimelineCell',
  components: {
    TripTimeline,
  },
})
export default class extends Vue {
  @Prop() row!: Trip;
}
