
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import echarts from 'echarts';
import ResizeMixin from '@/components/charts/mixins/resize';
import moment from 'moment';

@Component({
  name: 'ScatterChart',
})
export default class extends mixins(ResizeMixin) {
  @Prop() id!: string;
  @Prop() avg!: number;
  @Prop() className!: string;
  @Prop({
    default: '100%',
  })
  width!: string;
  @Prop({
    default: '200px',
  })
  height!: string;
  @Prop({
    default: '#48B8F4',
  })
  itemColor!: any;
  @Prop({
    default: '#137AB1',
  })
  emphasisColor!: any;
  @Prop({
    required: true,
  })
  chartData!: any;
  @Prop({
    required: true,
  })
  yAxisUnit!: any;

  chart: any = null;
  xAxisData: any = [];
  yAxisData: any = [];
  numberUnderAvg: number = 0;

  @Watch('chartData', {
    deep: true,
  })
  handleData(newData: any) {
    if (newData) {
      this.collateAxisData();
      this.initChart();
    }
    return newData;
  }

  mounted() {
    this.$nextTick(() => {
      this.collateAxisData();
      this.initChart();
    });
  }

  getNumberUnderAvg() {
    this.numberUnderAvg = this.chartData.filter(
      (o: any) => Number(o[1]) < this.avg
    ).length;
  }

  /** Add values for x and y axis */
  collateAxisData() {
    this.xAxisData = [];
    this.yAxisData = [];
    this.chartData?.forEach((item: any) => {
      if (moment(item[0]).isValid()) {
        this.xAxisData.push(moment(item[0]).format('MM/DD'));
      } else this.xAxisData.push(item[0]);
      this.yAxisData.push(item[1]);
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  triggerTooltip(xAxiValue: any) {
    let dataIndex: number = -1;
    this.chartData.forEach((item: any, index: number) => {
      if (item[0] === xAxiValue) {
        dataIndex = index;
        return;
      }
    });
    this.chart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: dataIndex,
      position: (pt: any) => {
        return [pt[0], '50%'];
      },
    });
  }

  private initChart() {
    this.getNumberUnderAvg();
    this.chart = echarts.init(this.$el as HTMLDivElement, 'light');
    this.chart.setOption({
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        position: 'top',
        formatter: (datas: any) => {
          // datas: trigger:item   datas[0]:trigger axis
          let resHtml = `<p style="color: #B6B6B6; margin: 9px 0 4px">${
            this.chartData[datas[0].dataIndex][0]
          }</p><p style="color: #fff; margin: 3px 0 0 0;"> <span style="font-size: 16px">${
            datas[0].value
          }</span> <span style="font-size: 14px">${
            this.yAxisUnit
          }</span></p><p style="color: #fff; margin: 3px 0 10px 0;"> <span style="font-size: 14px">${this.$t(
            'tippingVehicles.performance.rank'
          )}</span> <span style="font-size: 14px">${
            this.chartData.length - datas[0].dataIndex
          }</span></p>`;
          return resHtml;
        }, //the custom style of tooltip
        backgroundColor: 'rgba(55, 62, 65, 0.8)',
        textStyle: {
          color: '#fff',
        },
      },
      grid: {
        top: '18%',
        left: '160',
        bottom: '10%',
        right: '80',
      },
      yAxis: [
        {
          type: 'category',
          nameLocation: 'start',
          axisLabel: {
            fontSize: 0.1,
            color: '#C1C1C1',
          },
          data: this.xAxisData,
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',
            },
          },
          splitLine: {
            show: false,
          },
        },
      ],
      xAxis: [
        {
          type: 'value',
          name: this.yAxisUnit,
          axisLine: {
            lineStyle: {
              color: '#DDDDDD',
            },
          },
          axisLabel: {
            fontSize: 14,
            color: '#C1C1C1',
          },
        },
      ],
      visualMap: {
        show: false,
        pieces: [
          {
            max: this.numberUnderAvg,
            color: '#F7781B',
          },
          {
            min: this.numberUnderAvg,
            color: '#95F019',
          },
        ],
        outOfRange: {
          color: '#F7781B',
        },
      },
      series: [
        {
          name: 'scatterChart',
          type: 'scatter',
          label: {
            show: true,
            position: 'left',
            color: 'black',
            fontSize: 12,
            formatter: function (d: any) {
              return d.name;
            },
          },
          markLine: {
            silent: true,
            data: [
              {
                type: 'average',
              },
            ],
            label: {
              color: '#EA6D01',
            },
            itemStyle: {
              normal: {
                lineStyle: {
                  type: 'dotted',
                },
                color: '#EA6D01',
                label: {
                  show: true,
                  position: 'start',
                  formatter: (data: any) => {
                    let str: string = this.$t('KPI.ave') + '.' + data.value;
                    return str;
                  },
                },
              },
            },
            symbol: ['none', 'none'],
          }, //the average line style
          data: this.yAxisData,
          lineStyle: {
            color: '#707070',
            width: 1,
          },
        },
      ],
    });
    this.chart.on('click', ({ name }: any) => this.$emit('click', name));
  }
}
