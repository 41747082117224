
import { Component, Vue } from 'vue-property-decorator';
import SelectTree from './SelectTree.vue';
import {
  getAssetList,
  getKpisForMultipleAssets,
  KpiParametersValues,
} from '@/api/assetsAll';
import { getUserSearchHistory, saveUserSearchHistory } from '@/api/users';
import { AssetType } from '@/utils/workData/lookuptable';
import { UserModule } from '@/store/modules/user';

@Component({
  name: 'NavBarSearch',
  components: {
    SelectTree,
    History,
  },
})
export default class extends Vue {
  assetKpis: KpiParametersValues[] = [];
  assetIds = [];
  assetList = [];
  options = [];
  selected = '';
  defaultProps = {
    parent: 'parentId', //  Parent unique identifier
    value: 'id', //  Uniquely identifies
    label: 'label', //  Label display
    children: 'children', //  Child
  };

  kpiPayload = {
    metadata: {
      filter: {
        assetIds: [],
      },
    },
    details: [
      {
        fields: [
          {
            code: 'KPI.OperationalStatus',
          },
        ],
      },
    ],
  };

  historyOptions = [];

  treeNode = {
    id: '',
    label: '',
    children: [],
  };

  async created() {
    await this.searchAssetsData();
    await this.getHistoryData();
  }

  async getHistoryData() {
    try {
      const searchHistory = await getUserSearchHistory();
      if (searchHistory.code == 200) {
        this.historyOptions = searchHistory.data.map((entry) => {
          return { order: entry.order, label: entry.name };
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
  }

  async searchAssetsData() {
    // TODO Should asset search only search in selected org (and children), or in all orgs of the user?
    const response = await getAssetList(
      AssetType.All,
      UserModule.organizationId
    );
    const data =
      typeof response.data === 'object' ? [response.data] : response.data;
    let ids = data.flatMap((data) => this.unpack(data));
    await this.getAssetKpiList(ids);
    for (let i in response.data) {
      let organisation = this.parseResponse(
        response.data[i],
        {},
        this.assetKpis
      );
      this.options.push(organisation);
    }
  }

  async getAssetKpiList(ids) {
    if (!ids || ids.length == 0) {
      this.assetKpis = [];
      return;
    }

    this.kpiPayload.metadata.filter.assetIds = ids;
    try {
      const kpiResponse = await getKpisForMultipleAssets(this.kpiPayload);
      this.assetKpis = kpiResponse.data;
    } catch (err) {
      console.log(err);
    }
  }

  unpack(o) {
    let ids =
      o.organization && o.organization.assets
        ? o.organization.assets.map((a) => a.id)
        : [];
    if (o.organization && o.organization.suborganizations)
      ids = [
        ...ids,
        ...o.organization.suborganizations.flatMap((c) => this.unpack(c)),
      ];
    return ids;
  }

  async addToHistorySearch(label) {
    let lastSearch = {
      label: label,
      order: 1,
    };

    this.historyOptions.reverse().push(lastSearch);
    if (this.historyOptions.length > 10) this.historyOptions.reverse().pop();

    for (let i in this.historyOptions) {
      this.historyOptions[i].order = i;
    }

    const res = await saveUserSearchHistory(
      this.historyOptions.map((entry) => {
        return { order: entry.order, name: entry.label };
      })
    );
    if (res.code !== 200) {
      console.error('Failed to save search history');
    }
  }

  parseResponse(node, treeNode, kpis) {
    treeNode.parent = true;
    treeNode.id = node.id;
    treeNode.label = node.name;
    treeNode.children = [];

    for (let i in node.assets) {
      var kpiStatus = '';
      try {
        kpiStatus =
          kpis.find((o) => o.assetId === node.assets[i].id)?.values[0].v || '';
      } catch (e) {
        console.error(e);
      }

      let asset = {
        status: kpiStatus,
        assetType: node.assets[i].assetType,
        id: node.assets[i].id,
        label: node.assets[i].companyAssetId,
        children: [],
      };
      if (asset.status == null || asset.status.length == 0)
        asset.status = 'unavailable';
      this.assetIds.push(asset.id);
      treeNode.children.push(asset);
    }

    for (let i in node.suborganizations) {
      let org = this.parseResponse(
        node.suborganizations[i].organization,
        {},
        kpis
      );
      treeNode.children.push(org);
    }

    return treeNode;
  }
}
