
import { formatServerDate } from '@/views/assetManagement/helper';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'KpiTable',
  components: {},
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() cols!: [];

  list: any = [...this.tableList];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  tableComponentKey: string = new Date().toString();
  dropCols: any = [];

  mounted() {}

  hoverRow(row: any, column: any) {
    let xAxiValue = row.hasOwnProperty('date') ? row.date : row.asset;
    this.$emit('hover-table', xAxiValue);
  }

  get activeCols() {
    this.dropCols = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.tableComponentKey = new Date().toString();

    return this.dropCols;
  }

  getDate(date: string) {
    return formatServerDate(date);
  }
}
