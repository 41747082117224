import Vue from 'vue'
import VueI18n from 'vue-i18n'

// element-ui built-in lang
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementKoLocale from 'element-ui/lib/locale/lang/ko'
import elementNlLocale from 'element-ui/lib/locale/lang/nl'

// User defined lang
import enLocale from './en.json'
import zhLocale from './zh.json'
import esLocale from './es.json'
import jaLocale from './ja.json'
import koLocale from './ko.json'
import itLocale from './it.json'
import nlLocale from './nl.json'
import { LANGUAGE } from '@/utils/workData/lookuptable';

Vue.use(VueI18n)

const messages = {
  [LANGUAGE.EN_US]: {
    ...enLocale,
    ...elementEnLocale
  },
  [LANGUAGE.ZH_CN]: {
    ...zhLocale,
    ...elementZhLocale
  },
  [LANGUAGE.NL_NL]: {
    ...nlLocale,
    ...elementNlLocale
  },
  es: {
    ...esLocale,
    ...elementEsLocale
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale
  },
  ko: {
    ...koLocale,
    ...elementKoLocale
  },
  it: {
    ...itLocale
  }
}

const i18n = new VueI18n({
  locale: LANGUAGE.EN_US,
  messages,
  silentFallbackWarn: true
})

export default i18n
