import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

/**
 * Widgets that have no templateId and no position, serving as the blueprint
 * components for other dashboard templates.
 */
export interface GenericWidget {
  i: string;
  templateId: '00000000-0000-0000-0000-000000000000';
  code: string;
  pageCode: string;
  name: string;
  type?: number;
  sort?: string;
  x: 0;
  y: 0;
  w: number;
  h: number;
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
  isResizable: boolean;
  isDynamicHeight: boolean;
  kpis?: string;
}

export function getWidgetsByPageCode(
  pageCode: string
): CommonResultPromise<GenericWidget[]> {
  const request = getRequest();
  const response = request.get<GenericWidget[]>('/widgets', {
    params: { pageCode },
  });
  return response;
}

export function getWidgetsByPageCodeAndTemplateId(
  pageCode: string,
  templateId: string
): CommonResultPromise<IncludedWidget[]> {
  const request = getRequest();
  const response = request.get<GenericWidget[]>('/widgets', {
    params: { pageCode, templateId },
  });
  return response;
}

/**
 * Widgets that belong to a specific dashboard template.
 */
export interface IncludedWidget {
  i: string;
  templateId: string;
  code: string;
  pageCode: string;
  name: string;
  type?: number;
  sort?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  maxW: number;
  minH: number;
  maxH: number;
  isResizable: boolean;
  isDynamicHeight: boolean;
  kpis?: string;
}

export function getUserWidgetsByPageCode(
  userId: string,
  pageCode: string
): CommonResultPromise<IncludedWidget[]> {
  const request = getRequest();
  const response = request.get<IncludedWidget[]>(`/users/${userId}/widgets`, {
    params: { pageCode },
  });
  return response;
}

export function updateUserWidgets(
  userId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `users/${userId}/widgets`,
    method: 'put',
    data,
  });
  return response;
}
