import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';
import { IActivationStatus } from './types';

export function getCustomers(
  page: number,
  size: number,
  filter: string = ''
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies?page=${page}&size=${size}${filter}`,
    method: 'get',
  });
  return response;
}

export function getCustomersByCustomUrl(customUrl: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies${customUrl}`,
    method: 'get',
  });
  return response;
}

export function getAllCustomers(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies`,
    method: 'get',
  });
  return response;
}

export function getCustomerById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}`,
    method: 'get',
  });
  return response;
}

export function updateCustomer(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}`,
    method: 'put',
    data,
  });
  return response;
}

export function createCustomer(
  requestBody: FormData,
  request = getRequest()
): CommonResultPromise<CompanyResponseModel> {
  const response = request.post<CompanyResponseModel>(
    '/companies',
    requestBody
  );
  return response;
}

export function deleteCustomer(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}`,
    method: 'delete',
  });
  return response;
}

export function updateActivationStatus(
  id: string,
  data: IActivationStatus
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companies/${id}/activation`,
    method: 'put',
    data,
  });
  return response;
}

export interface CompanyResponseModel {
  id: string;
  name: string;
  primaryUserId: string;
  primaryUserEmail: string;
  installerCode: string;
  contractNumber: string;
  companyType: string;
  note: string;
  deploymentRegion: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  primaryOrganizationId: string;
  industry: string;
  street: string;
  district: string;
  province: string;
  city: string;
  country: string;
  zipCode: string;
  organizationCount: number;
  userCount: number;
  assetCount: number;
  activationStatus: string;
  setting: string;
  refreshDataInterval: number;
  isDeleted: string;
  amountOfUsers?: string;
  contractStartDate?: string;
  contractEndDate?: string;
}

export interface CompanyRequestModel {
  id?: string;
  name: string;
  primaryUserId: string;
  primaryUserEmail: string;
  contractNumber: string;
  companyType: string;
  note: string;
  deploymentRegion: string;
  contactPersonName: string;
  contactPersonEmail: string;
  contactPersonPhone: string;
  primaryOrganizationId: string;
  industry: string;
  street: string;
  district: string;
  province: string;
  city: string;
  country: string;
  zipCode: string;
  activationStatus: string;
  setting: string;
  refreshDataInterval: number;
  isDeleted: string;
  contractStartDate?: string;
  contractEndDate?: string;
}

export interface ErrorResponseModel {
  errors: {
    code: string;
    field: string;
    message: string;
  }[];
}
