
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CommonBtn',
})
export default class extends Vue {
  @Prop({ default: 'info' }) type!: string;
  @Prop() content!: string;
  @Prop({ default: false }) disabled!: boolean;

  handleBtn() {
    this.$emit('handle-btn');
  }
}
