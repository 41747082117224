
import LeafletMapAssetContent from './LeafletMapAssetContent.vue';
import { GeofenceAsset, AssetDetails } from '@/api/geofenceTypes';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LMap,
  LTileLayer,
  LIcon,
  LMarker,
  LPopup,
  LTooltip,
} from 'vue2-leaflet';
import moment from 'moment';
import ArrowRight from '@/assets/imgs/maintenance/arrow_right.svg';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import L from 'leaflet';
import {
  assetTypeIcons,
  AssetType,
  OperationalStatus,
} from '@/utils/workData/lookuptable';
import Truck from '@/assets/imgs/truck.svg';
import { getKpisForMultipleAssets } from '@/api/assetsAll';
import { KPI_FIELDS } from '@/utils/workData/lookuptable';
import { getAssetStatusConfig } from '@/utils/assets';
import { ActiveContext, useActiveContext } from '@/composables/context';
// operational status icons
import inactive from '@/assets/imgs/home/status_inactive.svg';
import maintenance from '@/assets/imgs/home/status_maintenance.svg';
import motorOn from '@/assets/imgs/home/status_motor_on.svg';
import offline from '@/assets/imgs/home/status_offline.svg';
import parked from '@/assets/imgs/home/status_parked.svg';
import stopped from '@/assets/imgs/home/status_stopped.svg';
import inTransition from '@/assets/imgs/home/status_in_transition.svg';
import { Ref, unref } from 'vue';

@Component({
  name: 'LeafletMapAssets',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    LIcon,
    LeafletMapAssetContent,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
  },
})
export default class extends Vue {
  /** Local variables */
  @Prop() assets!: Array<GeofenceAsset>;
  @Prop() map!: LMap;
  @Prop() selectedAssetId!: string;
  currentAsset: { id: string; kpis: any[] } | null = null;
  clusterOptions = {
    showCoverageOnHover: false,
    spiderfyOnMaxZoom: false,
    removeOutsideVisibleBounds: true,
    animate: true,
    maxClusterRadius: 150,
  };
  popupOptions = {
    offset: new L.Point(0, 0),
  };
  toolTipOptions = {
    direction: 'top',
    permanent: true,
    interactive: true,
    offset: new L.Point(0, 3),
    opacity: 100,
  };
  assetList = AssetType;
  color: string = '';
  context!: Ref<ActiveContext>;
  showGpsFix: boolean = false;

  created() {
    this.context = useActiveContext();
  }

  @Watch('selectedAssetId')
  handleData(newData: any) {
    if (newData) {
      this.navigateToAsset(newData);
    }
  }

  async fetchAssetDetails(asset: GeofenceAsset) {
    let doPopup = asset.popup || true;
    if (!doPopup) {
      return;
    }
    this.showGpsFix = asset.assetTypeCode != AssetType.StaticCompactor;

    const assetId = asset.id;

    let data: any = null;

    if (assetId) {
      const kpiResponse = await getKpisForMultipleAssets(
        {
          metadata: {
            filter: {
              assetIds: [assetId],
            },
          },
          details: [
            {
              fields: [
                { code: KPI_FIELDS.GpsStatus },
                { code: KPI_FIELDS.OperationalStatus },
                { code: KPI_FIELDS.LastCommunicationTime },
                { code: KPI_FIELDS.GeoLocation },
                { code: KPI_FIELDS.CurrentPayload },
              ],
            },
          ],
        },
        unref(this.context)
      );
      data = kpiResponse.data;
    }

    this.currentAsset = {
      id: assetId,
      kpis: data,
    };
  }

  openTooltip(mapObject: any) {
    mapObject.openTooltip();
  }

  closeTooltip(mapObject: any) {
    mapObject.closeTooltip();
  }

  async openPopup(asset: GeofenceAsset) {
    let doPopup = asset.popup || true;
    if (!doPopup) {
      return;
    }
    await this.fetchAssetDetails(asset);
    // @ts-ignore
    this.$refs[`marker-${asset.id}`][0].mapObject.openPopup();
  }

  formatTime(provided: Date) {
    return moment(provided).fromNow();
  }

  getStatusSource(status: OperationalStatus) {
    switch (status) {
      case OperationalStatus.Active:
      case OperationalStatus.MotorOn:
        return motorOn;

      case OperationalStatus.Inactive:
        return inactive;

      case OperationalStatus.Stopped:
        return stopped;

      case OperationalStatus.InTransition:
        return inTransition;

      case OperationalStatus.Offline:
        return offline;

      case OperationalStatus.Parked:
        return parked;

      case OperationalStatus.Maintenance:
        return maintenance;
    }
  }

  getColorForIcons(asset: OperationalStatus) {
    return getAssetStatusConfig(asset, 'color', this.config);
  }

  getTruck(asset: GeofenceAsset) {
    return assetTypeIcons[asset.assetTypeCode];
  }

  getArrowRight() {
    return ArrowRight;
  }

  // todo(gilles.coolen) change URL based on asset type from real API.
  getAssetUrl(asset: GeofenceAsset) {
    let type = '';
    if (asset.assetTypeCode.toString() === this.assetList.MobileCompactor)
      type = 'mobile-compactors';
    if (asset.assetTypeCode.toString() === this.assetList.StaticCompactor)
      type = 'static-compactors';
    if (asset.assetTypeCode.toString() === this.assetList.TippingVehicle)
      type = 'tipping-vehicles';
    if (asset.assetTypeCode.toString() === this.assetList.TableTopTissector)
      type = 'table-top-tissector';

    return `/assets/${type}/${asset.id}`;
  }

  navigateToAsset(id: string) {
    let selectedAsset = this.assets.find(
      (asset: GeofenceAsset) => asset.id === id
    );
    if (!selectedAsset) return;
    this.$emit('navigateToAssetLocation', {
      lat: selectedAsset.geodeticLatitude,
      lng: selectedAsset.geodeticLongitude,
    } as L.LatLngExpression);
    this.openPopup(selectedAsset as GeofenceAsset);
  }

  // TODO-TYPING: Add type for this config object. Be aware that there's more than one usage of it.
  config = {
    status: [
      {
        type: OperationalStatus.MotorOn,
        color: '#6494E2',
        icon: motorOn,
      },
      {
        type: OperationalStatus.Stopped,
        color: '#E8BA53',
        icon: stopped,
      },
      {
        type: OperationalStatus.InTransition,
        color: '#E89253',
        icon: inTransition,
      },
      {
        type: OperationalStatus.Active,
        color: '#6494E2',
        icon: motorOn,
      },
      {
        type: OperationalStatus.Inactive,
        color: '#E8BA53',
        icon: inactive,
      },
      {
        type: OperationalStatus.Parked,
        color: '#E89253',
        icon: parked,
      },
      {
        type: OperationalStatus.Maintenance,
        color: '#CE6666',
        icon: maintenance,
      },
      {
        type: OperationalStatus.Offline,
        color: '#A7A7A7',
        icon: offline,
      },
    ],
  };
}
