
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ResizeMixin from '@/layout/mixin/resize';
import Sortable from 'sortablejs';
import Draggable from 'vuedraggable';
import { UserModule } from '@/store/modules/user';
import {
  SYSTEM_FEATURES_LIST,
  VALUE_TYPE_LIST,
  SUBSCRIPTION_PACKAGE_TIME_UNITS,
} from '@/utils/workData/lookuptable';
import { customFailedMessage } from '@/utils/prompt';

@Component({
  name: 'FeaturesTable',
  components: {
    Draggable,
  },
  // filters: {
  //     formatTimer(time: string): string {
  //         let formatTimer = moment(time).format('hh:mm:ss YYYY/MM/DD')
  //         return formatTimer;
  //     }
  // }
})
export default class extends Vue {
  @Prop() tableList!: any[];
  @Prop() total!: number;
  @Prop() cols!: [];
  @Prop() treeProp!: any;
  @Prop() path!: string;
  @Prop() isEditable!: boolean;
  @Prop({ default: 'dropDownMenu' }) multiDropDownMenu!: string;
  @Prop({ required: true }) featuresWithInputType!: any;
  @Prop({ required: true }) featuresWithDateType!: any;
  @Prop({ required: true }) featuresWithBooleanType!: any;

  filter: string = '';
  currentCol: string = '';
  pageSize = UserModule.gridPageSize;
  listQuery = {
    page: 1,
    limit: UserModule.gridPageSize,
  };
  list: any = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];
  dropDownX: number = 0;
  dropDownY: number = 0;
  systemFeatureList: any = SYSTEM_FEATURES_LIST;
  valueTypeList: any = VALUE_TYPE_LIST;
  timeUnitList: any = SUBSCRIPTION_PACKAGE_TIME_UNITS;
  timeDurationInputValue: any = '';
  timeDurationUnitValue: any = '';

  handleInputFieldEvent() {
    this.list = [...this.tableList];
  }

  booleanValues = [
    {
      id: '33e73e52-1aed-433c-8e82-64b7142ef230',
      label: this.$t('subscriptionPackages.enabled'),
      value: 'true',
    },
    {
      id: '45db2638-2d03-43ed-9ac4-7ac2f5b1a531',
      label: this.$t('subscriptionPackages.disabled'),
      value: 'false',
    },
  ];

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  /**
   * Set index as parameter to row HTMLelement for table
   * @param row
   * @param index
   */
  tableRowClassName(row: any, index: any) {
    row.index = index;
  }

  get filterDisabled() {
    return this.currentCol === 'note' ||
      this.currentCol === 'claims' ||
      this.currentCol === 'subOrganizationCount' ||
      this.currentCol === 'usersCount' ||
      this.currentCol === 'assetsCount'
      ? true
      : false;
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  handleView(row: any, column: any, event: any) {
    if (this.path) {
      this.$router.push(this.path + row.id);
    }
  }

  handleDropDownMenu(type: number) {
    var dropDownMenu: any = document.querySelector('.dropDownMenu');

    if (type === 1) {
      var that: any = this.$refs.sortTable;
      that.sort(this.currentCol, 'ascending');

      dropDownMenu.style.display = 'none';
    }

    if (type === 0) {
      var that: any = this.$refs.sortTable;
      that.sort(this.currentCol, 'descending');
      dropDownMenu.style.display = 'none';
    }
  }

  dropDownMenu(value: string, e: any) {
    if (this.currentCol != value) this.filter = ''; // remove previous searched value if filter opened from other column
    this.currentCol = value;

    // var dropDownMenu: any = document.querySelector("#dropDownMenu");
    var dropDownMenu: any = document.querySelector(
      '#' + this.multiDropDownMenu
    );
    let siderBody: any = document.querySelector('#siderBody');
    let siderBarWidth = siderBody.clientWidth;
    this.dropDownX =
      window.innerWidth - e.clientX < 130
        ? e.clientX - siderBarWidth - 200
        : e.clientX - siderBarWidth - 40;

    this.dropDownY = 30;

    dropDownMenu.style.left = this.dropDownX + 'px';
    dropDownMenu.style.top = this.dropDownY + 'px';

    //show dropDownMenu
    dropDownMenu.style.display = 'block';

    document.addEventListener('mouseup', function (e: any) {
      if (!dropDownMenu.contains(e.target)) {
        dropDownMenu.style.display = 'none';
      }
    });
  }

  async handleFilter() {
    if (this.filter === '') {
      this.list = this.tableList;
      return false;
    }

    this.list = [];
    this.retrieveFilteredItems(this.tableList);
  }

  /**
   * Recursivly need to check each level of organization
   * @param tableList
   */
  retrieveFilteredItems(tableList: any) {
    tableList.forEach((currentOrg: any) => {
      var condition: string | boolean = '';
      if (this.currentCol === 'emailVerified') {
        condition = this.$t(this.filter) === 'true' ? true : false;
      } else if (this.currentCol === 'companyType') {
        condition = this.$t(this.filter.replace(/ /g, '_')) as string;
      } else {
        condition = this.$t(this.filter) as string;
      }

      if (Object.values(currentOrg).indexOf(condition) > -1) {
        // this.list = [currentOrg]
        this.list.push(currentOrg);
        return;
      }

      if (!currentOrg.children || currentOrg.children.length === 0) return;
      return this.retrieveFilteredItems(currentOrg.children);
    });
  }

  columnDrop() {
    const theader: any = document.querySelector('.el-table__header-wrapper tr');
    Sortable.create(theader, {
      animation: 180,
      delay: 0,
      onEnd: (evt: any) => {
        const oldItem = this.dragCols[evt.oldIndex];
        this.dragCols.splice(evt.oldIndex, 1);
        this.dragCols.splice(evt.newIndex, 0, oldItem);
      },
    });
  }

  getList(val: any) {
    this.$emit('handle-page', val.page, this.pageSize);
  }

  mounted() {
    // this.columnDrop();
    // (this.$refs.sortTable as any).bodyWrapper.addEventListener('scroll', (res: any) => {
    //     (document.querySelector("#"+this.multiDropDownMenu) as any).style.display = 'none';
    // })
  }

  /**
   * Verify if row has the roleCode then show icon
   * @param index
   * @param roleCode
   */
  handleModuleAccessIconRendering(index: number, roleCode: string) {
    return this.list[index].claims
      ? this.list[index].claims.some((e: any) => e.resource == roleCode)
      : false;
  }

  handleFeatureRowDelete(index: number, row: any) {
    if (this.list.length === 1) {
      customFailedMessage(
        this.$t('subscriptionPackages.atLeastOneFeatureRequired').toString()
      );
      return;
    }

    this.list = this.tableList.filter((item: any) => {
      return item.id != row.id;
    });

    this.$emit('delete-row', this.list);
  }
}
