
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Trip } from '@/utils/types/trip';
import { TranslateResult } from 'vue-i18n';
import { formatTimer } from '@/utils/misc';
import { AssetType } from '@/utils/workData/lookuptable';
import moment from 'moment';

interface property {
  lable: TranslateResult;
  value: string | Date | null | undefined;
}

interface TripDetails {
  id: property;
  startTime: property;
  totalTime: property;
  payload: property;
}

@Component({
  name: 'TripDetails',
})
export default class extends Vue {
  @Prop() trip: Trip | null = null;
  selectedTrip: TripDetails = {
    id: {
      lable: this.$t('trip.companyAssetId'),
      value: undefined,
    },
    startTime: {
      lable: this.$t('trip.startTime'),
      value: undefined,
    },
    totalTime: {
      lable: this.$t('trip.totalTime'),
      value: undefined,
    },
    payload: {
      lable: this.$t('trip.payload'),
      value: undefined,
    },
  };

  @Watch('trip')
  handleData(newData: any) {
    if (newData) {
      this.populateSelectedTrips(newData);
    }
    return newData;
  }

  populateSelectedTrips(trip: Trip) {
    this.selectedTrip.id.value = trip.companyAssetId;
    this.selectedTrip.startTime.value = `${formatTimer(
      moment(trip.startTime),
      'datetime'
    )}`;
    this.selectedTrip.totalTime.value = `${moment
      .utc(moment.duration(trip.tripTime, 'seconds').asMilliseconds())
      .format('HH[h] mm[m] ss[s]')}`;
    this.selectedTrip.payload.value =
      trip.assetType === AssetType.TippingVehicle
        ? `${trip.payload.value} ${trip.payload.unit}`
        : trip.payload
        ? `${trip.payload} ${this.$t('UNIT_METRIC_TONNE')}`
        : '';
  }
}
