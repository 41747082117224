
import { AsyncValue } from '@/composables/async';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface ProductModelSelectItem {
  productModel: string;
  productModelId: string;
  selected: boolean;
}

@Component({
  name: 'MultiProductModelSelect',
})
export default class extends Vue {
  @Prop() productModelList!: AsyncValue<ProductModelSelectItem[] | undefined>;
  @Prop() disabled!: boolean;

  // el-select props
  selectedProductModelOptions: string[] = [];

  //el-switch props
  selectedProductModelsSwitch: ProductModelSelectItem[] = [];

  allDropdownProductModelsSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('productModelList', { immediate: true })
  handleData(newData: AsyncValue<ProductModelSelectItem[] | undefined>): void {
    this.determineAllSelectionState();
    if (newData.data?.length) {
      this.selectedProductModelOptions = this.getSelectedItemsName(
        newData.data
      );
      this.selectedProductModelsSwitch = newData.data;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of items list against which one are selected
   */
  determineAllSelectionState(): void {
    this.allDropdownProductModelsSelected =
      this.productModelList.data?.length ===
      this.productModelList.data?.filter((item) => item.selected)?.length;
  }

  /**
   * Filter selected items then return the name of those objects into the main input element
   * @param itemsList
   */
  getSelectedItemsName(itemsList: ProductModelSelectItem[]): string[] {
    const selectedItems: ProductModelSelectItem[] = itemsList?.filter(
      (item: ProductModelSelectItem) => item.selected
    );
    return selectedItems?.map(
      (obj: ProductModelSelectItem) => obj.productModel
    );
  }

  /**
   * Handle item dropdown changes
   */
  async itemDropdownChanged(): Promise<void> {
    this.selectedProductModelsSwitch.forEach(
      (item: ProductModelSelectItem) =>
        (item.selected = this.selectedProductModelOptions.includes(
          item.productModel
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedProductModelsSwitch.filter(
        (item: ProductModelSelectItem) => item.selected
      )
    );
  }

  /**
   * Toggle all dropdown items selection
   */
  async toggleAllDropdownItemsSelected(): Promise<void> {
    this.allDropdownProductModelsSelected =
      !this.allDropdownProductModelsSelected;
    this.selectedProductModelsSwitch.forEach(
      (item: ProductModelSelectItem) =>
        (item.selected = this.allDropdownProductModelsSelected)
    );
    this.selectedProductModelOptions = this.selectedProductModelsSwitch
      .filter((item: ProductModelSelectItem) => item.selected)
      .map((item: ProductModelSelectItem) => item.productModel);
    this.$emit(
      'filterData',
      this.selectedProductModelsSwitch.filter(
        (item: ProductModelSelectItem) => item.selected
      )
    );
  }
}
