
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'WidgetDialog',
})
export default class extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() title!: string;
  @Prop({ default: '60%' }) width!: string;
  @Prop() confirmBtnName!: string;
  @Prop({ default: true }) showCancelButton!: boolean;

  handleCloseDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleConfirm() {
    this.$emit('handle-confirm');
  }
}
