
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import { MAINTENANCE_STATUS } from '@/utils/workData/lookuptable';
import {
  MaintenanceAppointment,
  MaintenanceRuleProgress,
} from '@/api/maintenance';
import { formatTimer } from '@/utils/misc';
import { UserModule } from '@/store/modules/user';
import i18n from '@/lang';

@Component({
  name: 'MaintenancePlanListTable',
})
export default class extends Vue {
  @Prop() tableList!: MaintenanceAppointment[];
  @Prop() total!: number;
  @Prop() cols!: [];
  @Prop() treeProp!: any;
  @Prop() path!: string;

  list: MaintenanceAppointment[] = [...this.tableList];
  dragCols: any = [...this.cols];
  colChange: any = [];
  maintenanceStatuses: any = MAINTENANCE_STATUS;
  progressRuleNaturalDay: string = 'MKPI.NaturalDays';
  unitDay: string =
    UserModule.i18nCode === 'zh-CN' ? i18n.tc('common.day') : 'd';

  @Watch('tableList')
  onListChange(value: any) {
    this.list = [...this.tableList];
  }

  /**
   * Set index as parameter to row HTMLelement for table
   * @param row
   * @param index
   */
  tableRowClassName(row: any, index: any) {
    row.index = index;
  }

  get activeCols() {
    this.colChange = this.cols.filter((item: any) => {
      return item.visible;
    });

    this.dragCols = [...this.colChange];
    return [...this.colChange];
  }

  handleRowClick(row: MaintenanceAppointment) {
    this.$emit('appointment-clicked', row);
  }

  /**
   * Get rules progress as concatenated string by row obj
   * e.g: 10d/20d
   * @param appointment
   */
  getRulesProgressThresholdValues(appointment: MaintenanceAppointment): string {
    return (
      Number(
        appointment.rulesProgress.find(
          (rule: MaintenanceRuleProgress) =>
            rule.kpiCode === this.progressRuleNaturalDay
        )?.thresholdValue
      ) - Number(appointment.dominantValueRepresentedDays)
    )
      .toString()
      .concat(this.unitDay);
  }

  /**
   * Make sure that natural days is always on the first place in the rules progress array
   * @param appointment
   */
  handleNaturalDaysToBeFirstInRulesProgress(
    appointment: MaintenanceAppointment
  ): MaintenanceAppointment {
    if (
      appointment.rulesProgress.length > 0 &&
      appointment.rulesProgress[0]?.kpiCode === this.progressRuleNaturalDay
    ) {
      return appointment;
    }

    const naturalDaysIndex = appointment.rulesProgress.findIndex(
      (app: MaintenanceRuleProgress) =>
        app.kpiCode === this.progressRuleNaturalDay
    );

    if (!naturalDaysIndex) return appointment;

    appointment.rulesProgress.splice(
      0,
      0,
      appointment.rulesProgress[naturalDaysIndex]
    );
    appointment.rulesProgress.splice(naturalDaysIndex, 1);

    return appointment;
  }

  /**
   * Get progress bar text value by row obj
   * e.g.: 10d / 20d
   * When dominantValueRepresentedDays > natural days threshold, the value should be returned negative with "-"
   *  e.g.: -40d / 20d
   * @param appointment
   */
  getProgressBarTextValue(appointment: MaintenanceAppointment): string {
    const naturalDayThresholdValue =
      this.getNaturalDaysThresholdValue(appointment)?.toString();

    let representedDayValue: string = Number(
      appointment.dominantValueRepresentedDays
    ).toString();

    return representedDayValue
      .concat(this.unitDay)
      .concat(' / ')
      .concat(naturalDayThresholdValue.toString())
      .concat(this.unitDay);
  }

  /**
   * Return natural day threshold value for each row as received appointment obj
   * @param appointment
   */
  getNaturalDaysThresholdValue(appointment: MaintenanceAppointment): number {
    return Number(
      appointment.rulesProgress.find(
        (rule: MaintenanceRuleProgress) =>
          rule.kpiCode === this.progressRuleNaturalDay
      )?.thresholdValue
    );
  }

  /**
   * Get progress bar value
   * Range between 0 - 100
   * @param appointment
   */
  getProgressBarValue(appointment: MaintenanceAppointment): number {
    const dominantDayValue = Number(appointment.dominantValueRepresentedDays);
    const naturalDayThresholdValue =
      this.getNaturalDaysThresholdValue(appointment);

    if (dominantDayValue > naturalDayThresholdValue) return 100;

    return (
      (Number(appointment.dominantValueRepresentedDays) /
        naturalDayThresholdValue) *
      100
    );
  }

  formatColumnDateValue(date: string, pattern: string) {
    return formatTimer(date, pattern);
  }
}
