
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { findKpiValue } from '@/utils/assets';
import {
  gpsStatusColorMapping,
  KPI_FIELDS,
  KPI_UNIT,
  operationalStatusColorMapping,
} from '@/utils/workData/lookuptable';
import { parseFloatOrUndefined } from '@/utils/number';
import { unitConverterUtils } from '@/utils/unitConvertUtil';

@Component({
  name: 'LeafletMapAssetContent',
})
export default class extends Vue {
  @Prop() assetId!: string;
  @Prop() kpis!: any[];
  @Prop() showGpsFix!: boolean;

  gpsStatus: string | undefined;
  operationalStatus: string | undefined;
  lastCommunicationTime: string | undefined;
  payload: string | undefined;

  @Watch('assetId')
  onAssetIdChanged() {
    this.clearData();
    this.populateData();
  }

  mounted() {
    this.populateData();
  }

  async clearData() {
    this.gpsStatus = undefined;
    this.operationalStatus = undefined;
    this.lastCommunicationTime = undefined;
    this.payload = undefined;
  }

  async populateData() {
    const unknownValue: string = this.$t('unknownValue') as string;

    const gpsStatus = this.getKpi(KPI_FIELDS.GpsStatus) as string | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const operationalStatus = this.getKpi(KPI_FIELDS.OperationalStatus) as
      | string
      | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const lastCommunicationTime = this.getKpi(
      KPI_FIELDS.LastCommunicationTime
    ) as Date | undefined; // TODO-TYPING: Remove cast when getKpi gains typing
    const payloadInKgString = this.getKpi('KPI.CurrentPayload') as
      | string
      | undefined;
    const payloadInKg = parseFloatOrUndefined(payloadInKgString);
    const payloadInMT =
      payloadInKg !== undefined
        ? unitConverterUtils.convertUnit(
            payloadInKg,
            KPI_UNIT.Kilogram,
            KPI_UNIT.MetricTonne
          )
        : undefined;

    this.operationalStatus =
      operationalStatus !== undefined
        ? (this.$t(operationalStatus) as string)
        : unknownValue;
    this.gpsStatus =
      gpsStatus !== undefined
        ? (this.$t(`gpsConnectivity.${gpsStatus}`) as string)
        : unknownValue;
    this.lastCommunicationTime =
      lastCommunicationTime !== undefined
        ? this.formatTime(lastCommunicationTime)
        : unknownValue;
    this.payload =
      payloadInMT !== undefined
        ? `${payloadInMT.toFixed(2)} ${this.$t('UNIT_METRIC_TONNES')}`
        : unknownValue;
  }

  getKpi(code: string) {
    return findKpiValue(this.kpis, this.assetId, code);
  }

  formatTime(provided: Date) {
    return moment(provided).fromNow();
  }

  get operationalStatusStyle() {
    let color = operationalStatusColorMapping.get(
      this.getKpi(KPI_FIELDS.OperationalStatus) as string
    );
    if (!color) return undefined;
    return { backgroundColor: color };
  }

  get GPSStatusStyle() {
    let color = gpsStatusColorMapping.get(
      this.getKpi(KPI_FIELDS.GpsStatus) as string
    );
    if (!color) return undefined;
    return { backgroundColor: color };
  }
}
