import { getRequest } from '@/utils/request';
import {
  REGISTERED_ASSET_CONNECTION_STATUSES,
  ASSET_CONNECT_STATUS,
  AssetType,
} from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export function getAccessibleAssets(
  data: any,
  organizationId: string
): CommonResultPromise<AccessibleAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: `/accessible-assets/${organizationId}`,
    method: 'post',
    data: data,
  });
  return response;
}

export function addCustomerAssets(
  accessibleAssetIds: string[],
  organizationId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/set-accessible-assets/${organizationId}`,
    method: 'post',
    data: { accessibleAssetIds: accessibleAssetIds },
  });
  return response;
}

export function removeCustomerAssets(
  accessibleAssetIds: string[],
  organizationId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/accessible-assets/${organizationId}/remove`,
    method: 'put',
    data: { accessibleAssetIds: accessibleAssetIds },
  });
  return response;
}

export function getInaccessibleAssets(
  queryParams: any,
  organizationId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/inaccessible-assets/${organizationId}`,
    method: 'post',
    data: queryParams,
  });
  return response;
}

export function getAccessibleAssetsByFlexibleFiltering(
  requestData: any
): CommonResultPromise<AccessibleAssetsResponse> {
  const request = getRequest();
  const response = request.post<AccessibleAssetsResponse>(
    `/accessible-assets`,
    requestData
  );
  return response;
}

export interface AccessibleAssetsResponse {
  assets: AccessibleAssetsObjectModel[];
  total: number;
}

export interface AccessibleAssetsObjectModel {
  id: string;
  serialNumber: string;
  assetDataCollectionId: string;
  companyAssetId: string;
  gatewayId: string;
  controllerId: string;
  serviceRegion: string;
  deploymentRegion: string;
  companyId: string;
  companyName: string;
  organizationId: string;
  organizationName: string;
  productModelCode: string;
  productModelId: string;
  productModelNumber: string;
  assetType: AssetType;
  lastCommunicationTime: string;
  accessTime: string;
  manufactureDate: string;
  installTime: string;
  timezone: string;
  accessOrganizations: string[];
  registrationHandlerCompanyId: string;
  registrationHandlerCompanyName: string;
  registrationHandlerOrganizationId: string;
  registrationHandlerOrganizationName: string;
  registrationHandlerUserId: string;
  registrationHandlerUserName: string;
  connectionHandlerCompanyId: string;
  connectionHandlerCompanyName: string;
  connectionHandlerOrganizationId: string;
  connectionHandlerOrganizationName: string;
  connectionHandlerUserId: string;
  connectionHandlerUserName: string;
  connectStatus: string;
  assetStatus: typeof ASSET_CONNECT_STATUS;
}
