import { getRequest } from '@/utils/request';
import { AssetType, OperationalStatus } from '@/utils/workData/lookuptable';
import { AssetObjectModel } from './assets';
import { CommonResultPromise } from './commonResult';
import { ProductModel } from './products';

export function getProductModels(): CommonResultPromise<ProductModel[]> {
  const request = getRequest();
  const response = request.request({
    url: '/productModels',
    method: 'get',
  });
  return response;
}

export function getProductModelsByAseetType(
  assetType: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/productModels?assetType=${assetType}`,
    method: 'get',
  });
  return response;
}

export function getProductModelsByCustomParams(
  customParams: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/productModels${customParams}`,
    method: 'get',
  });
  return response;
}

export interface AssetFiltered {
  id: string;
  companyId?: string;
  companyName?: string;
  controllerId: string;
  assetId?: string;
  companyAssetId: string;
  connectionHandlerCompanyId?: string;
  connectionHandlerCompanyName?: string;
  assetDataCollectionId: string;
  assetOnBoardingId?: string;
  lastCommunicationTime?: string;
  status?: OperationalStatus;
  assetType: AssetType;
  assignDate?: string;
  subscriptionStartDate?: string;
  subscriptionEndDate?: string;
  gatewayId: string;
  manufactureDate: string;
  serialNumber: string;
  organizationName?: string;
  warrantyStatus?: string;
  productModelId?: string;
  productModelCode: string;
  productModelName?: string;
  productModelNumber: string;
  connectStatus: string;
  installTime: string;
  timezone?: string;
  organizationId?: string;
}

export interface AssetList {
  assets: AssetFiltered[];
  total: number;
}

export function getUnassignAssetsByCustomUrl(
  customUrl: string
): CommonResultPromise<AssetList> {
  const request = getRequest();
  const response = request.get(`/unassignedAssets${customUrl}`);
  return response;
}

export function getCustomers(
  page: number = 0,
  size: number = 10
): CommonResultPromise<CompanyResponse> {
  const request = getRequest();
  const response = request.get<CompanyResponse>(
    `/companies?page=${page}&size=${size}`
  );
  return response;
}

export function getOrganizationInfo(companyId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations?companyId=${companyId}`,
    method: 'get',
  });
  return response;
}

export function getAssetInfoById(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assets/${id}`,
    method: 'get',
  });
  return response;
}

export function getCompanySubscriptionInfo(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/companySubscription/${id}`,
    method: 'get',
  });
  return response;
}

export function postAssignAssets(
  customerId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assignAssets/${customerId}`,
    method: 'post',
    data,
  });
  return response;
}

/* ======================= ASSET ON BOARDING APIs ==================================== */

export function getAssetOnBoarding(
  page?: number | null,
  size?: number | null,
  assetTypeCode?: string | null,
  productModelId?: string | null,
  connectionStatus?: string | null,
  serialNumber?: string | null,
  gatewayId?: string | null
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url:
      page &&
      size &&
      assetTypeCode &&
      !productModelId &&
      !connectionStatus &&
      !serialNumber &&
      !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          !assetTypeCode &&
          productModelId &&
          !connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&productModelId=${productModelId}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&connectionStatus=${connectionStatus}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&serialNumber=${serialNumber}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          !serialNumber &&
          gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&gatewayId=${gatewayId}`
        : assetTypeCode &&
          productModelId &&
          !connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}&productModelId=${productModelId}`
        : page &&
          size &&
          assetTypeCode &&
          !productModelId &&
          connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}&connectionStatus=${connectionStatus}`
        : page &&
          size &&
          assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}&serialNumber=${serialNumber}`
        : page &&
          size &&
          assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          !serialNumber &&
          gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}&gatewayId=${gatewayId}`
        : page &&
          size &&
          !assetTypeCode &&
          productModelId &&
          connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&productModelId=${productModelId}&connectionStatus=${connectionStatus}`
        : page &&
          size &&
          !assetTypeCode &&
          productModelId &&
          !connectionStatus &&
          serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&productModelId=${productModelId}&serialNumber=${serialNumber}`
        : page &&
          size &&
          !assetTypeCode &&
          productModelId &&
          !connectionStatus &&
          !serialNumber &&
          gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&productModelId=${productModelId}&gatewayId=${gatewayId}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          connectionStatus &&
          serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&connectionStatus=${connectionStatus}&serialNumber=${serialNumber}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&serialNumber=${serialNumber}&gatewayId=${gatewayId}`
        : page &&
          size &&
          assetTypeCode &&
          productModelId &&
          connectionStatus &&
          serialNumber &&
          gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}&productModelId=${productModelId}
                    &connectionStatus=${connectionStatus}&serialNumber=${serialNumber}&gatewayId=${gatewayId}`
        : page &&
          size &&
          !assetTypeCode &&
          !productModelId &&
          !connectionStatus &&
          !serialNumber &&
          !gatewayId
        ? `assetOnBoarding?page=${page}&size=${size}`
        : `assetOnBoarding?page=${page}&size=${size}`,
    method: 'get',
  });
  return response;
}

export function getAssetOnBoardingByFilterParam(
  requestUrlParams: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `assetOnBoarding${requestUrlParams}`,
    method: 'get',
  });
  return response;
}

export function getAssetOnBoardingById(assetId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoarding/${assetId}`,
    method: 'get',
  });
  return response;
}

export function getUnassignedGateways(): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/unassignedGateways`,
    method: 'get',
  });
  return response;
}

export function postConnectAssetOnBoarding(
  assetId: string,
  payloadData: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/connectAssetOnBoarding/${assetId}`,
    method: 'post',
    data: payloadData,
  });
  return response;
}

export function editRegisterAsset(
  assetId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoarding/${assetId}`,
    method: 'put',
    data: data,
  });
  return response;
}

interface PagedAssetsResponse {
  assets: AssetObjectModel[];
  size: number;
  total: number;
}

export function getConnectedAssets(
  url: string
): CommonResultPromise<PagedAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: url,
    method: 'get',
  });
  return response;
}

export function getAssignedAssets(
  url: string
): CommonResultPromise<PagedAssetsResponse> {
  const request = getRequest();
  const response = request.request({
    url: url,
    method: 'get',
  });
  return response;
}

export function getAssets(
  page?: number | null,
  size?: number | null,
  connectStatus?: string | null,
  productModelId?: string | null,
  assetTypeCode?: string | null,
  controllerId?: string | null,
  companyId?: string | null,
  assetId?: string | null,
  installDateTime?: string | null,
  companyAssetId?: string | null
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url:
      page &&
      size &&
      productModelId &&
      !connectStatus &&
      !controllerId &&
      !assetTypeCode &&
      !companyId &&
      !assetId &&
      !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}`
        : page &&
          size &&
          !productModelId &&
          !connectStatus &&
          controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&controllerId=${controllerId}`
        : page &&
          size &&
          !productModelId &&
          !connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          !productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&companyId=${companyId}`
        : page &&
          size &&
          !productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&assetId=${assetId}`
        : page &&
          size &&
          productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&connectStatus=${connectStatus}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&controllerId=${controllerId}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&companyId=${companyId}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&assetId=${assetId}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&controllerId=${controllerId}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&productModelId=${productModelId}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          companyId &&
          !assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&companyId=${companyId}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&assetId=${assetId}`
        : page &&
          size &&
          productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          assetId &&
          !installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&connectStatus=${connectStatus}&assetTypeCode=${assetTypeCode}`
        : page &&
          size &&
          productModelId &&
          connectStatus &&
          controllerId &&
          assetTypeCode &&
          companyId &&
          assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&connectStatus=${connectStatus}
                    &controllerId=${controllerId}&assetTypeCode=${assetTypeCode}&companyId=${companyId}&assetId=${assetId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId
        ? `assets?page=${page}&size=${size}`
        : page &&
          size &&
          productModelId &&
          !connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&productModelId=${productModelId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&controllerId=${controllerId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&assetTypeCode=${assetTypeCode}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&companyId=${companyId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&assetId=${assetId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          !assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&productModelId=${productModelId}&assetTypeCode=${assetTypeCode}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          assetTypeCode &&
          !companyId &&
          assetId &&
          installDateTime
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&assetTypeCode=${assetTypeCode}&assetId=${assetId}&installDateTime=${installDateTime}`
        : page &&
          size &&
          !productModelId &&
          connectStatus &&
          !controllerId &&
          !assetTypeCode &&
          !companyId &&
          !assetId &&
          !installDateTime &&
          companyAssetId
        ? `assets?page=${page}&size=${size}&connectStatus=${connectStatus}&companyAssetId=${companyAssetId}`
        : `assets?page=${page}&size=${size}`,
    method: 'get',
  });
  return response;
}

export function getAssetOnBoardingProgressById(
  assetOnboardingId: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assetOnBoardingProgress/${assetOnboardingId}`,
    method: 'get',
  });
  return response;
}

export interface CompanyResponse {
  companies: Company[];
  count: number;
  total: number;
  links: CompanyLink;
}

export interface Company {
  id: string;
  activationStatus: string;
  assetCount: number;
  city: string;
  companyType: string;
  contactPersonEmail: string;
  contactPersonName: string;
  contactPersonPhone: string;
  contractEndDate: string;
  contractNumber: string;
  contractStartDate: string;
  country: string;
  deleted: boolean;
  district: string;
  installerCode: string;
  name: string;
  organizationCount: number;
  primaryUserEmail: string;
  primaryUserId: string;
  province: string;
  refreshDataInterval: number;
  street: string;
  userCount: number;
  zipCode: string;
}

export interface CompanyLink {
  first: CompanyLinkHref;
  last: CompanyLinkHref;
  self: CompanyLinkHref;
}

interface CompanyLinkHref {
  href: string;
}
