
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  AssetType,
  subActivityColors,
  TripSubActivity,
} from '@/utils/workData/lookuptable';

@Component({
  name: 'TripLegend',
})
export default class extends Vue {
  @Prop({ required: true }) type!: AssetType;

  items = Object.entries(subActivityColors);

  get filtered() {
    let list = [] as TripSubActivity[];

    switch (this.type) {
      case AssetType.MobileCompactor:
        list = [TripSubActivity.Moving, TripSubActivity.StandingStill];
        break;
      case AssetType.TippingVehicle:
        list = [
          TripSubActivity.Driving,
          TripSubActivity.Tipping,
          TripSubActivity.StandingStill,
        ];
        break;
    }

    return this.items.filter((i) => list.includes(i[0] as TripSubActivity));
  }
}
