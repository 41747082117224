
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Polyline, Tooltip } from 'leaflet';
import { TripModule } from '@/store/modules/trip';
import TripTimeline from '@/components/trip/TripTimeline.vue';
import LeafletMap from '@/components/leafletMap/LeafletMap.vue';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { Trip } from '@/utils/types/trip';
import EventDetailsModal from '@/views/dashboard/components/EventDetailsModal.vue';
import TripLegend from '@/components/trip/TripLegend.vue';
import { formatTimer } from '@/utils/misc';
import { Geofence } from '@/api/geofenceTypes';

@Component({
  name: 'TripPlayer',
  components: {
    TripLegend,
    TripTimeline,
    LeafletMap,
    EventDetailsModal,
  },
})
export default class extends Vue {
  /** Local variables */
  @Prop() trip: Trip | null = null;
  @Prop() geofences: Geofence[] = [];
  $refs!: {
    map: LeafletMap;
  };
  loadingTripPlayer: boolean = false;
  private center = [46.771211, 23.623634];
  private zoom = 10;
  private tripProgress = 0;
  private selectedSpeed = 1;
  private speedValues = [
    {
      speed: 1,
      label: 'X 1',
    },
    {
      speed: 2,
      label: 'X 2',
    },
    {
      speed: 4,
      label: 'X 4',
    },
    {
      speed: 60,
      label: 'X 60',
    },
  ];
  private startDate: Moment | null = null;
  private polyline: Polyline | null = null;
  private tracker: Tooltip | null = null;
  private selectedEvent: string | null = null;

  private get isPlaying() {
    return TripModule.IS_PLAYING;
  }

  private get duration() {
    return TripModule.DURATION;
  }

  private get progress() {
    return TripModule.PROGRESS;
  }

  private get dialogVisible() {
    return Boolean(this.selectedEvent);
  }

  private formatTooltip(value: number) {
    const date = this.startDate?.clone().add(value, 'seconds');

    if (!date) {
      return 'Unknown';
    }

    return formatTimer(date.toISOString(), 'time');
  }

  handlePlayPause() {
    if (this.isPlaying) {
      return TripModule.pause();
    }

    if (this.progress === this.duration) {
      TripModule.updateProgress(0);
    }

    TripModule.play();
  }

  private handleSpeedChange() {
    TripModule.updateSpeed(this.selectedSpeed);
  }

  private handleProgressChange() {
    TripModule.updateProgress(this.tripProgress);
  }

  private handleCloseDialog() {
    this.selectedEvent = null;
  }

  @Watch('trip')
  private async onTripUpdate(trip: Trip) {
    this.$refs.map.clearLayers();

    try {
      this.loadingTripPlayer = true;
      await TripModule.loadTrip(trip);
      await TripModule.updateSpeed(this.selectedSpeed);

      const track = TripModule.TRACK;

      this.startDate = moment(trip.startTime);
      this.polyline = this.$refs.map.renderLine(track);
      this.$refs.map.renderEvents(
        this.polyline,
        trip,
        (event) => (this.selectedEvent = event.id)
      );
      this.tracker = this.$refs.map.renderTripTracker(
        track[track.length - 1].location,
        trip.assetType
      );
    } catch (e) {
    } finally {
      this.loadingTripPlayer = false;
    }
  }

  @Watch('progress')
  private onProgressUpdate(progress: number) {
    this.tripProgress = progress;

    if (!this.trip || !this.polyline || !this.tracker) {
      return;
    }

    const time = moment(this.trip.startTime).add(progress, 's');
    const index = TripModule.TRACK.findIndex((t) =>
      moment(t.timestamp).isAfter(time)
    );

    const start = TripModule.TRACK[index - 1];
    const end = TripModule.TRACK[index];

    if (!start || !end) return;

    const startTime = moment(start.timestamp);
    const endTime = moment(end.timestamp);
    const ratio =
      (1 / (endTime.unix() - startTime.unix())) *
      (time.unix() - startTime.unix());

    const position = this.$refs.map.guessTrackerPosition(
      start.location,
      end.location,
      ratio
    );

    if (position) {
      this.tracker.setLatLng(position);
    }
  }

  @Watch('geofences')
  private onGeofenceUpdate(geofences: Geofence[]) {
    this.$refs.map.initializeGeofence(geofences as any);
    this.$refs.map.setBoundsToGeofences();
  }
}
