
import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import KpiNumber from '@/components/widget/kpiNumber/KpiNumber.vue';
import BarChart from '@/components/kpiCharts/BarChart.vue';
import KpiInfoExpanded from './kpiInfo/KpiInfoExpanded.vue';
import { ROUTE_ASSET_MAP } from '@/utils/workData/utilMap';
import WidgetCard from '@/components/layout/widget/WidgetCard.vue';
import TimeSelect from '@/components/form/TimeSelect.vue';
import { DateRange } from '@/utils/types/date';

@Component({
  name: 'KpiBarChart',
  components: {
    WidgetCard,
    TimeSelect,
    KpiNumber,
    BarChart,
    KpiInfoExpanded,
  },
})
export default class extends Vue {
  @Prop({ required: true }) itemColor!: string;
  @Prop({ required: true }) kpiUnit!: string;
  @Prop({ default: [] }) chartData!: any;
  @Prop({ default: [] }) kpiNumberList!: any;
  @Prop() loading?: boolean;

  routeAssetMap = ROUTE_ASSET_MAP;
  assetRelatedData: any = {
    route: '',
    pageCode: '',
    widgetContainerId: '',
    title: '',
    assetTypeCode: '',
  };
  @Inject() expanded!: boolean;
  isFleet: boolean = false; //determine whether the kpi is fleet kpi
  fleetType: string = 'date';
  fleetTypeList: any = [
    {
      label: 'KPI.overTime',
      value: 'date',
    },
    {
      label: 'KPI.perAsset',
      value: 'asset',
    },
  ];

  created() {
    //if kpi param exists, it is expanded type
    this.isFleet = this.$route.params.id ? false : true;
    if (this.$route.name) {
      this.assetRelatedData = this.routeAssetMap.get(this.$route.name);
    }
  }

  dateRangeChange(dateRange: DateRange) {
    this.$emit('send-date', dateRange);
  }

  changeFleetType(tab: any) {
    this.$emit('send-fleet-type', tab);
  }

  triggerTooltip(row: any) {
    (this.$refs.canvasChart as any).triggerTooltip(row);
  }
}
