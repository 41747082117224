
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  getModules,
  getPagesByModuleCode,
  getTemplatesById,
  getDefaultTemplate,
} from '@/api/dashboardConf';
import { getWidgetsByPageCode } from '@/api/widgets';
// import VueGridLayout from 'vue-grid-layout'
import { GridLayout, GridItem } from 'vue-grid-layout';
import { PAGE_NO_WIDGET } from '@/utils/workData/dashboardConf';
import { PAGE_LAYOUT_MAP } from '@/utils/workData/utilMap';
import { Tree } from 'element-ui';
import {
  filterWidgetsByPermissions,
  scaleHorizontal,
  NUMBER_OF_GRID_COLUMNS,
} from '@/utils/widget';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import { modulesToTreeData } from '@/utils/elementui/tree';
import { filterModulesForCompanyType } from './moduleFiltering';
import { Module } from '@/api/users';

var mouseXY = {
  x: 0,
  y: 0,
};

var DragPos = {
  i: '',
  x: 0,
  y: 0,
};

interface WIDGET_TYPE {
  i?: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW: number;
  minH: number;
  maxW: number;
  maxH: number;
  isResizable: boolean;
  code: string;
  pageCode: string;
  name: string;
}

@Component({
  name: 'EditDashTemplate',
  components: {
    'el-tree': Tree,
    'grid-layout': GridLayout,
    'grid-item': GridItem,
  },
})
export default class extends Vue {
  @Prop() id!: string;
  @Prop() customerType!: COMPANY_TYPE;
  @Prop({ default: true }) showModule!: boolean;
  @Prop({ default: false }) filterByPermission!: boolean;
  @Prop({
    default: () => {
      return {
        moduleSpan: 5,
        pageSpan: 15,
        widgetSpan: 4,
      };
    },
  })
  templateColSpan!: any;

  pageLayoutMap = PAGE_LAYOUT_MAP;
  pageNoWidget = PAGE_NO_WIDGET;
  pageNoWidgetFlag: boolean = false;
  currentNodeKey: string = 'AUTHRSC_MOD_HOME';
  colNum: number = NUMBER_OF_GRID_COLUMNS;
  loadingPage: boolean = false;
  loadingWidget: boolean = false;
  loadingTree: boolean = false;

  modulesInfo: any = [];
  treeData: any = [];
  defaultChecked: string[] = [];

  pages: any = [];

  layout: WIDGET_TYPE[] = [];
  widgets: any = [];
  widgetsBuffer: Map<string, WIDGET_TYPE[]> = new Map();

  pageCheckListBufer: Map<string, string[]> = new Map();
  currentPageCode: string = '';
  currentModuleNode: string = '';
  currentPages: string[] = [];
  checkPageList: string[] = [];
  checkedNodeList: string[] = [];
  modules: Module[] = [];

  @Watch('pages')
  listenLoadPages() {
    this.$nextTick(() => {
      this.renderPageBg(0);
    });
  }

  @Watch('customerType', {
    deep: true,
  })
  updateTreeData() {
    const newModules = filterModulesForCompanyType(
      this.modules,
      this.customerType
    );
    this.treeData = modulesToTreeData(newModules);
  }

  handlePageCheck() {
    let isExit: boolean = false;
    for (let item of this.pages) {
      if (this.checkPageList.includes(item.code)) {
        isExit = true;
        break;
      }
    }

    if (isExit) {
      if (!this.defaultChecked.includes(this.currentModuleNode)) {
        this.defaultChecked.push(this.currentModuleNode);
      }

      (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    } else {
      this.defaultChecked.splice(
        this.defaultChecked.findIndex(
          (item) => item === this.currentModuleNode
        ),
        1
      );
      (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    }
  }

  async handleModuleCheck(node: any, checkedNodes: any) {
    this.currentPages = [];
    const res = await getPagesByModuleCode('', node.name);
    res.data.forEach((item: any) => {
      this.currentPages.push(item.code);
    });

    if (!checkedNodes.checkedKeys.includes(node.name)) {
      this.checkPageList = this.checkPageList.filter((item: string) => {
        return !this.currentPages.includes(item);
      });
      this.defaultChecked.splice(
        this.defaultChecked.findIndex((item) => item === node.name),
        1
      );
    } else {
      if (node.children.length > 0) {
        node.children.forEach((item: any) => {
          this.defaultChecked.push(item.name);
        });
      } else {
        this.defaultChecked.push(node.name);
      }

      this.currentPages.forEach((item: any) => {
        if (!this.checkPageList.includes(item)) {
          this.checkPageList.push(item);
        }
      });
    }
  }

  updateWidgetStatus(widgetId: string, dragDisable: boolean) {
    if (!document.getElementById(widgetId)) return false;

    let widgetDom = document.getElementById(widgetId) as HTMLElement;
    widgetDom.draggable = dragDisable;
    widgetDom.style.cursor = dragDisable ? 'pointer' : 'not-allowed';
    widgetDom.style.background = dragDisable ? '' : 'rgb(175, 175, 175, 0.6)';
    widgetDom.style.color = dragDisable ? '' : 'rgb(55, 62, 65, 0.6)';
  }

  removeGridItem(pageCode: string, widgetCode: string) {
    const index = this.layout.map((item) => item.code).indexOf(widgetCode);
    this.layout.splice(index, 1);

    let widgetId = pageCode + '_' + widgetCode;
    this.updateWidgetStatus(widgetId, true);
  }

  drag(val: any) {
    let parentRect: any = (
      document.getElementById('content') as any
    ).getBoundingClientRect();

    let mouseInGrid = false;
    if (
      mouseXY.x > parentRect.left &&
      mouseXY.x < parentRect.right &&
      mouseXY.y > parentRect.top &&
      mouseXY.y < parentRect.bottom
    ) {
      mouseInGrid = true;
    }

    if (
      mouseInGrid === true &&
      this.layout.findIndex((item) => item.i === 'drop') === -1
    ) {
      this.layout.push({
        i: 'drop',
        x: (this.layout.length * 2) % (this.colNum || 12),
        y: this.layout.length + (this.colNum || 12),
        w: val.w,
        h: val.h,
        minW: 1,
        minH: 1,
        maxW: 4,
        maxH: 5,
        isResizable: false,
        code: 'code',
        pageCode: 'pageCode',
        name: 'grid',
      });
    }

    let index = this.layout.findIndex((item) => item.i === 'drop');
    if (index !== -1) {
      try {
        (this.$refs.gridlayout as any).$children[
          this.layout.length
        ].$refs.item.style.display = 'none';
      } catch {}

      let childIndex = (this.$refs.gridlayout as any).$children.length - 1;

      let el = (this.$refs.gridlayout as any).$children[childIndex];
      el.dragging = {
        top: mouseXY.y - parentRect.top,
        left: mouseXY.x - parentRect.left,
      };

      let new_pos = el.calcXY(
        mouseXY.y - parentRect.top,
        mouseXY.x - parentRect.left
      );

      if (mouseInGrid === true) {
        (this.$refs.gridlayout as any).dragEvent(
          'dragstart',
          'drop',
          new_pos.x,
          new_pos.y,
          val.h,
          val.w
        );

        DragPos.i = val.i;
        DragPos.x = this.layout[index].x;
        DragPos.y = this.layout[index].y;
      }

      if (mouseInGrid === false) {
        (this.$refs.gridlayout as any).dragEvent(
          'dragend',
          'drop',
          new_pos.x,
          new_pos.y,
          1,
          1
        );
        this.layout = this.layout.filter((obj) => obj.i !== 'drop');
      }
    }
  }

  dragend(val: WIDGET_TYPE) {
    let parentRect: any = (
      document.getElementById('content') as any
    ).getBoundingClientRect();

    let mouseInGrid = false;
    if (
      mouseXY.x > parentRect.left &&
      mouseXY.x < parentRect.right &&
      mouseXY.y > parentRect.top &&
      mouseXY.y < parentRect.bottom
    ) {
      mouseInGrid = true;
    }

    if (mouseInGrid === true) {
      // alert(`Dropped element props:\n${JSON.stringify(this.DragPos, ['x', 'y', 'w', 'h'], 2)}`);

      (this.$refs.gridlayout as any).dragEvent(
        'dragend',
        'drop',
        DragPos.x,
        DragPos.y,
        1,
        1
      );

      this.layout = this.layout.filter((obj) => obj.i !== 'drop');

      this.layout.push({
        i: DragPos.i,
        x: DragPos.x,
        y: DragPos.y,
        w: val.w,
        h: val.h,
        minW: val.minW,
        minH: val.minH,
        maxW: val.maxW,
        maxH: val.maxH,
        isResizable: val.isResizable,
        code: val.code,
        pageCode: val.pageCode,
        name: val.name,
      });

      (this.$refs.gridlayout as any).dragEvent(
        'dragend',
        DragPos.i,
        DragPos.x,
        DragPos.y,
        1,
        1
      );

      try {
        (this.$refs.gridlayout as any).$children[
          this.layout.length
        ].$refs.item.style.display = 'block';
      } catch {}
    }

    for (let i = this.layout.length - 1; i >= 0; --i) {
      if (this.layout[i].code === val.code) {
        let widgetId = val.pageCode + '_' + val.code;
        this.updateWidgetStatus(widgetId, false);
        break;
      }
    }
  }

  async handleResetToDefault(
    customerType: string,
    industry: string,
    templateId?: string
  ) {
    const resData: any = templateId
      ? (await getTemplatesById(templateId))?.data
      : (await getDefaultTemplate(customerType, industry))?.data;
    if (resData) {
      this.fetchCheckedModules(resData.id);
      this.currentPageCode = '';

      this.updateWidgetsBuffer(resData.widgets);

      (this.$refs.createPageTree as any).setCurrentKey('AUTHRSC_MOD_HOME');

      this.handleNodeClick({ name: 'AUTHRSC_MOD_HOME', children: [] });
    }
  }

  cancelModulePageCheckboxBgColor() {
    var modulePageCheckbox = document.getElementsByClassName(
      'module-page-checkbox'
    );
    Array(...modulePageCheckbox).forEach((item: any) => {
      item.style.backgroundColor = 'transparent';
    });
  }

  renderPageBg(index: number) {
    this.cancelModulePageCheckboxBgColor();
    var modulePageCheckbox: any = document.getElementsByClassName(
      'module-page-checkbox'
    );
    if (modulePageCheckbox[index]) {
      modulePageCheckbox[index].style.backgroundColor = 'var(--Main)';
    }
  }

  updateWidgetsBuffer(widgets: WIDGET_TYPE[]) {
    widgets = this.filterWidgets(widgets);

    const newWidgetsBuffer = widgets.reduce((cumulative, widget) => {
      const pageLayout = this.pageLayoutMap.get(widget.pageCode);
      const scaledWidget =
        pageLayout != undefined
          ? scaleHorizontal(
              [widget],
              NUMBER_OF_GRID_COLUMNS,
              pageLayout.column
            )[0]
          : widget;
      if (cumulative.has(widget.pageCode)) {
        cumulative.get(widget.pageCode)?.push(scaledWidget);
      } else {
        cumulative.set(widget.pageCode, [scaledWidget]);
      }
      return cumulative;
    }, new Map<string, WIDGET_TYPE[]>());

    this.widgetsBuffer = newWidgetsBuffer;
    this.checkPageList = [...newWidgetsBuffer.keys()];
  }

  async handleNodeClick(node: any) {
    this.currentModuleNode = node.name;

    if (node.children.length === 0) {
      this.loadingPage = true;
      const res = await getPagesByModuleCode('', node.name);
      let pageData = res.data;
      this.pages = pageData;

      this.loadingPage = false;

      this.handlePageClick(0, pageData[0]?.code);
    }
  }

  updateWidget() {
    if (this.currentPageCode) {
      this.widgetsBuffer.set(this.currentPageCode, this.layout);
    }
  }

  handlePageClick(index: number, pageCode: string) {
    /** background */
    this.renderPageBg(index);

    this.updateWidget();

    this.currentPageCode = pageCode;
    this.layout = [];

    if (this.pageNoWidget.includes(pageCode)) {
      this.pageNoWidgetFlag = true;
      this.layout = [
        {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          minW: 0,
          minH: 0,
          maxW: 0,
          maxH: 0,
          isResizable: false,
          code: 'AUTHRSC_WIDGET_NULL',
          pageCode: pageCode,
          name: 'null',
        },
      ];
      this.widgets = [];
    } else {
      this.colNum =
        this.pageLayoutMap.get(pageCode)?.column ?? NUMBER_OF_GRID_COLUMNS;

      this.pageNoWidgetFlag = false;
      const widgets = this.widgetsBuffer.get(this.currentPageCode);
      if (widgets != undefined) {
        this.layout = widgets.map((x) => ({ ...x }));
      }

      this.fetchWidgets(pageCode);
    }
  }

  setCurrentPageCode(pageCode: string) {
    this.currentPageCode = pageCode;
  }

  async fetchPages(templateId: string, moduleCode: string) {
    const res = await getPagesByModuleCode(templateId, moduleCode);
    this.pages = res.data;
  }

  filterWidgets(widgets: any[]) {
    if (this.filterByPermission) {
      return filterWidgetsByPermissions(widgets);
    }
    return widgets;
  }

  async fetchWidgets(pageCode: string) {
    this.loadingWidget = true;

    const res = await getWidgetsByPageCode(pageCode);
    const pageLayout = this.pageLayoutMap.get(pageCode);
    this.widgets = pageLayout
      ? scaleHorizontal(
          this.filterWidgets(res.data),
          NUMBER_OF_GRID_COLUMNS,
          pageLayout.column
        )
      : this.filterWidgets(res.data);

    this.loadingWidget = false;

    let widgetCodes: string[] = this.layout.map((item: any) => item.code);

    this.$nextTick(() => {
      this.widgets.map((item: any) => {
        let widgetId = item.pageCode + '_' + item.code;
        if (widgetCodes.includes(item.code)) {
          this.updateWidgetStatus(widgetId, false);
        } else {
          this.updateWidgetStatus(widgetId, true);
        }
      });
    });
  }

  async fetchModules() {
    this.modules = (await getModules('')).data;
    this.updateTreeData();
  }

  setCheckedModules(resCheckedModules: any) {
    this.defaultChecked = [];

    let checkedModules = modulesToTreeData(resCheckedModules.data);

    let arr: string[] = [];
    checkedModules.forEach((item: any) => {
      if (item.children.length > 0) {
        item.children.forEach((item: any) => {
          arr.push(item.name);
        });
      } else {
        arr.push(item.name);
      }
    });

    this.defaultChecked = arr;

    (this.$refs.createPageTree as any).setCheckedKeys(this.defaultChecked);
    this.loadingTree = false;
  }

  async fetchCheckedModules(templateId: string) {
    this.loadingTree = true;
    const resCheckedModules = await getModules(templateId);
    this.setCheckedModules(resCheckedModules);
  }

  filterModules() {
    let unSelectedModules: string[] = [];

    (this.$refs.createPageTree as any)
      .getCheckedNodes(false, true)
      .forEach((item: any) => {
        this.checkedNodeList.push(item.name);
      });

    let unCheckedNodeList = this.modulesInfo.filter((item: any) => {
      return !this.checkedNodeList.includes(item.code);
    });

    unCheckedNodeList.forEach((item: any) => {
      unSelectedModules.push(item.code);
    });

    return unSelectedModules;
  }

  isPageNull() {
    for (let page of this.checkPageList) {
      if (!this.pageNoWidget.includes(page)) {
        if (
          !this.widgetsBuffer.has(page) ||
          this.widgetsBuffer.get(page)?.length === 0
        ) {
          this.$message({
            type: 'error',
            // message: `${this.$t(page)} Is Null`,
            // widget should be added.
            message: `${this.$t('dashboardConf.pageWidgetsNull')}`,
            duration: 3000,
          });

          return true;
        }
      }
    }

    return false;
  }

  isCheckedNodeListNull() {
    if (this.checkedNodeList.length === 0) {
      this.$message({
        type: 'error',
        message: `${this.$t('dashboardConf.tipSelectModules')}`,
        duration: 2000,
      });

      return true;
    }

    return false;
  }

  getAllWidgets() {
    let allWidgets: WIDGET_TYPE[] = [];

    this.checkPageList.map((pageCode: string) => {
      let scaledPageWidgets = this.widgetsBuffer.get(pageCode);
      if (!scaledPageWidgets) {
        scaledPageWidgets = [
          {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            minW: 0,
            minH: 0,
            maxW: 0,
            maxH: 0,
            isResizable: false,
            code: 'AUTHRSC_WIDGET_NULL',
            pageCode,
            name: 'null',
          },
        ];
      }

      // Apply conversion to widget scale here (back to 12 columns)
      const pageLayout = this.pageLayoutMap.get(pageCode);
      const pageWidgets = pageLayout
        ? scaleHorizontal(
            scaledPageWidgets,
            pageLayout.column,
            NUMBER_OF_GRID_COLUMNS
          )
        : scaledPageWidgets;

      allWidgets.push.apply(allWidgets, pageWidgets);
    });

    return allWidgets;
  }

  mounted() {
    document.addEventListener(
      'dragover',
      function (e) {
        // prevent default to allow drop
        e.preventDefault();
        (e.dataTransfer as any).dropEffect = 'move';

        mouseXY.x = e.clientX;
        mouseXY.y = e.clientY;
      },
      false
    );
  }

  created() {
    this.fetchModules();
    if (!this.id) {
      this.handleNodeClick({ name: 'AUTHRSC_MOD_HOME', children: [] });
    }
  }
}
