import { render, staticRenderFns } from "./TransferList.vue?vue&type=template&id=4b0ee8fc&scoped=true&"
import script from "./TransferList.vue?vue&type=script&lang=ts&"
export * from "./TransferList.vue?vue&type=script&lang=ts&"
import style0 from "./TransferList.vue?vue&type=style&index=0&id=4b0ee8fc&prod&scoped=true&lang=css&"
import style1 from "./TransferList.vue?vue&type=style&index=1&id=4b0ee8fc&prod&lang=scss&"
import style2 from "./TransferList.vue?vue&type=style&index=2&id=4b0ee8fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b0ee8fc",
  null
  
)

export default component.exports