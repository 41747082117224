
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Trip, TripActivity, TripEvent } from '@/utils/types/trip';
import { subActivityColors } from '@/utils/workData/lookuptable';
import { formatTimer } from '@/utils/misc';

@Component({
  name: 'TripTimeline',
})
export default class extends Vue {
  @Prop() trip!: Trip;
  @Prop({ default: false }) compact!: boolean;

  get formattedStart() {
    return formatTimer(moment(this.trip.startTime), 'time');
  }

  get formattedEnd() {
    return formatTimer(moment(this.trip.endTime), 'time');
  }

  get activities() {
    if (!this.trip.summary) return;
    return [...this.trip.summary].sort((a, b) => {
      return (
        new Date(a.subActivityStartTimeStamp).getTime() -
        new Date(b.subActivityStartTimeStamp).getTime()
      );
    });
  }

  get events() {
    if (!this.trip.events) return [];
    return this.trip.events.filter((e) => e.id);
  }

  getPercentageFromTime(date: Date) {
    const time = new Date(date);
    const startTime = this.trip.startTime;
    const endTime = this.trip.endTime ?? new Date();

    return (
      (100 / (endTime.getTime() - startTime.getTime())) *
      (time.getTime() - startTime.getTime())
    );
  }

  getActivityStyle(activity: TripActivity, index: number) {
    const start = Math.max(
      0,
      this.getPercentageFromTime(activity.subActivityStartTimeStamp)
    );
    const end =
      index < this.activities!.length - 1
        ? Math.min(
            100,
            this.getPercentageFromTime(
              this.activities![index + 1].subActivityStartTimeStamp
            )
          )
        : 100;

    return {
      left: `${start}%`,
      width: `${end - start}%`,
      backgroundColor: subActivityColors[activity.subActivityKind],
    };
  }

  getActivityTime(activity: TripActivity, index: number) {
    if (
      !moment(this.trip.endTime).isAfter(activity.subActivityStartTimeStamp)
    ) {
      return;
    }

    const next = index + 1;
    const end =
      this.activities!.length > next
        ? this.activities![next].subActivityStartTimeStamp
        : this.trip.endTime;

    return `${moment(end).diff(
      activity.subActivityStartTimeStamp,
      'minutes'
    )} ${this.$t('UNIT_MINUTE_SHORT')}`;
  }

  getEventStyle(event: TripEvent) {
    return {
      left: `${Math.max(0, this.getPercentageFromTime(event.eventTimestamp))}%`,
      backgroundColor: event.eventType.includes('WARNING')
        ? '#ffa500'
        : '#de453f',
    };
  }
}
