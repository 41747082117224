var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('el-table',{ref:"tableRef",class:_vm.shouldAllBeExpanded ? 'expandAll' : '',attrs:{"data":_vm.list,"tree-props":_vm.treeProp,"default-expand-all":!!_vm.shouldAllBeExpanded ? true : false,"row-key":"id","max-height":_vm.maxHeight,"row-style":{ height: '40px' },"cell-style":{ padding: '7px 0px' },"row-class-name":_vm.tableRowClassName},on:{"row-click":_vm.handleRowClick,"sort-change":_vm.handleSortChange,"selection-change":_vm.handleSelectionChange}},[(_vm.selectable)?_c('el-table-column',{attrs:{"reserve-selection":_vm.selectable,"type":"selection","width":"55"}}):_vm._e(),_vm._l((_vm.activeCols),function(col,index){return _c('el-table-column',{key:index + '_' + _vm.tableComponentKey,attrs:{"prop":col.prop,"min-width":"242","show-overflow-tooltip":"","sortable":col.sortable},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"table-header"},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(_vm.activeCols[index]?.label)))])])]},proxy:true},(col.prop == 'claims')?{key:"default",fn:function(slotProps){return [(
            _vm.handleModuleAccessIconRendering(
              slotProps.$index,
              'AUTHRSC_MOD_ASSETS'
            )
          )?_c('span',{staticClass:"module-access"},[_c('img',{staticClass:"module-access-item",attrs:{"viewBox":"0 0 18.3 14.3","src":require("@/assets/imgs/modules-access/assets.svg"),"title":_vm.$t('AUTHRSC_MOD_ASSETS')}})]):_vm._e(),(
            _vm.handleModuleAccessIconRendering(
              slotProps.$index,
              'AUTHRSC_MOD_HOME'
            )
          )?_c('span',{staticClass:"module-access"},[_c('img',{staticClass:"module-access-item",attrs:{"viewBox":"0 0 18.3 14.3","src":require("@/assets/imgs/modules-access/home.svg"),"title":_vm.$t('AUTHRSC_MOD_HOME')}})]):_vm._e(),(
            _vm.handleModuleAccessIconRendering(
              slotProps.$index,
              'AUTHRSC_MOD_ADMIN'
            )
          )?_c('span',{staticClass:"module-access"},[_c('img',{staticClass:"module-access-item",attrs:{"viewBox":"0 0 18.3 14.3","src":require("@/assets/imgs/modules-access/administration.svg"),"title":_vm.$t('AUTHRSC_MOD_ADMIN')}})]):_vm._e(),(
            _vm.handleModuleAccessIconRendering(
              slotProps.$index,
              'AUTHRSC_MOD_USER_SETTINGS'
            )
          )?_c('span',{staticClass:"module-access"},[_c('img',{staticClass:"module-access-item",attrs:{"viewBox":"0 0 18.3 14.3","src":require("@/assets/imgs/modules-access/userSetting.svg"),"title":_vm.$t('AUTHRSC_MOD_USER_SETTINGS')}})]):_vm._e()]}}:(col.useIconSet)?{key:"default",fn:function(slotProps){return [(col.useIconSet[slotProps.row[col.prop]])?_c('img',{staticClass:"img-auto-center",attrs:{"src":col.useIconSet[slotProps.row[col.prop]].img,"title":_vm.$t(col.useIconSet[slotProps.row[col.prop]].title)}}):_vm._e(),(!col.useIconSet[slotProps.row[col.prop]])?_c('span',[_vm._v(_vm._s(slotProps.row[col.prop]))]):_vm._e()]}}:(col.dateFormat === 'date' || col.dateFormat === 'datetime')?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm._f("formatTimer")(slotProps.row[col.prop],col.dateFormat)))])]}}:(col.multiLang)?{key:"default",fn:function(slotProps){return [_c('span',[_vm._v(_vm._s(_vm.$t(slotProps.row[col.prop])))])]}}:(col.prop === 'action')?{key:"default",fn:function(slotProps){return _vm._l((slotProps.row[col.prop]),function(item,index){return _c('el-button',{key:index,attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleActionBtnClick(item, slotProps.row)}}},[_vm._v(" "+_vm._s(_vm.$t(item))+" ")])})}}:(col.prop === 'ifEnable')?{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"enable-container"},[_c('span',{staticClass:"enable-value"},[_vm._v(_vm._s(_vm.$t(`${slotProps.row.ifEnable}`)))]),(_vm.editing)?_c('el-switch',{staticClass:"el-switch-component",attrs:{"active-color":"var(--Main)","inactive-color":"#D5D5D5"},model:{value:(slotProps.row.ifEnable),callback:function ($$v) {_vm.$set(slotProps.row, "ifEnable", $$v)},expression:"slotProps.row.ifEnable"}}):_vm._e()],1)]}}:(col.prop === 'gatewayId')?{key:"default",fn:function(slotProps){return [(
            _vm.checkClaim(_vm.ALL_CLAIMS_CODES.AUTHRSC_ACTION_ASSET_CONNECT) &&
            _vm.showConnectButton(slotProps.row)
          )?_c('el-button',{staticClass:"connect-btn",attrs:{"size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleConnectBtnClick(slotProps.row)}}},[_vm._v(" "+_vm._s(_vm.$t('assetMgmt.connectBtn'))+" ")]):_c('span',[_vm._v(_vm._s(slotProps.row[col.prop]))])]}}:(col.prop === 'accessOrganizations')?{key:"default",fn:function(slotProps){return [(slotProps.row[col.prop] != undefined)?_c('div',{staticClass:"access-organization"},[_vm._v(" "+_vm._s(slotProps.row[col.prop].join(', '))+" ")]):_vm._e()]}}:null],null,true)})})],2),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPage && parseInt(_vm.total) > parseInt(_vm.pageSize)),expression:"showPage && parseInt(total) > parseInt(pageSize)"}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limt":_vm.listQuery.limt},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limt":function($event){return _vm.$set(_vm.listQuery, "limt", $event)},"pagination":_vm.getList}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }