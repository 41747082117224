
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  ColumnRendering,
  ColumnRenderType,
  KpiData,
  TableColumn,
} from '@/utils/types/columnCustomizationTypes';
import { formatTimer } from '@/utils/misc';
import { unitConverterUtils } from '@/utils/unitConvertUtil';
import { KPI_UNIT } from '@/utils/workData/lookuptable';

interface Row {
  kpiData: KpiData[] | undefined;
  [extra: string]: unknown;
}

@Component({
  name: 'CustomTableCell',
})
export default class extends Vue {
  @Prop({ required: true }) column!: TableColumn;
  @Prop({ required: true }) row!: Row;
  @Prop({
    required: false,
  })
  renderingOptions?: ColumnRendering;
  @Prop({ required: false }) scope: any;

  private renderComponent: boolean = false;
  private computedValue: string = '';
  private componentProperties: any;

  async created() {
    this.renderComponent =
      this.renderingOptions?.type === ColumnRenderType.Component;

    if (this.renderComponent) {
      if (this.renderingOptions?.getComponentProperties) {
        this.componentProperties = this.renderingOptions.getComponentProperties(
          this.row
        );
      } else {
        this.componentProperties = { row: this.row };
      }
      return;
    }

    const prop = this.column.prop;
    if (this.column.isKpiData) {
      this.computedValue = this.formatValue(
        this.getKpiData(prop, this.column.kpiUnitCode),
        this.column.kpiUnitCode,
        undefined
      );
    } else {
      this.computedValue = this.formatValue(
        this.row[prop],
        this.column.kpiUnitCode,
        this.scope.row.timeZone
      );
    }
  }

  private getKpiData(propertyName: string, unit: string) {
    const kpiData = this.row.kpiData;
    if (!kpiData) {
      return '';
    }
    let kpi = kpiData.find(
      (data) => data?.code.toLowerCase() === propertyName.toLowerCase()
    );
    if (!kpi) return '';

    if (kpi.unit != unit) {
      console.warn(
        `Kpi unit should be ${unit} but is ${kpi.unit} instead!\nTrying to convert.`
      );
      try {
        const convertedValue = unitConverterUtils.convertUnit(
          kpi.value as number,
          kpi.unit as KPI_UNIT,
          unit as KPI_UNIT
        );
        return convertedValue;
      } catch (exception) {
        console.error(exception);
      }
    }

    return kpi.value;
  }

  private formatValue(value: any, unit: string, timeZone: string | undefined) {
    if (this.renderingOptions?.getValue) {
      value = this.renderingOptions.getValue(value);
    }

    if (!value) {
      return '';
    }

    switch (this.renderingOptions?.type) {
      case ColumnRenderType.Date:
        return formatTimer(value, 'date', timeZone);

      case ColumnRenderType.DateTime:
        return formatTimer(value, 'datetime', timeZone);

      case ColumnRenderType.Translatable:
        return this.$t(
          `${this.renderingOptions.translatePrefix || ''}${value}`
        ) as string;
    }

    if (
      !this.renderingOptions?.hideKpiUnit &&
      unit &&
      unit != KPI_UNIT.Boolean
    ) {
      value = `${value} ${this.$t(unit)}`;
    }
    return value;
  }
}
