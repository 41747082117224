<script>
import ElButton from 'element-ui/packages/button';
import Emitter from 'element-ui/src/mixins/emitter';
import Locale from 'element-ui/src/mixins/locale';
import TransferPanel from './TransferPanel.vue';
import Migrating from 'element-ui/src/mixins/migrating';

export default {
  name: 'ElTransfer',

  mixins: [Emitter, Locale, Migrating],

  components: {
    TransferPanel,
    ElButton,
  },

  props: {
    generalDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
    buttonTexts: {
      type: Array,
      default() {
        return [];
      },
    },
    filterPlaceholder: {
      type: String,
      default: '',
    },
    filterMethod: Function,
    leftDefaultChecked: {
      type: Array,
      default() {
        return [];
      },
    },
    rightDefaultChecked: {
      type: Array,
      default() {
        return [];
      },
    },
    rightDownDefaultChecked: {
      type: Array,
      default() {
        return [];
      },
    },
    renderContent: Function,
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    rightDownvalue: {
      type: Array,
      default() {
        return [];
      },
    },
    format: {
      type: Object,
      default() {
        return {};
      },
    },
    filterable: Boolean,
    props: {
      type: Object,
      default() {
        return {
          label: 'label',
          key: 'key',
          disabled: 'disabled',
        };
      },
    },
    targetOrder: {
      type: String,
      default: 'original',
    },
  },

  data() {
    return {
      leftChecked: [],
      rightChecked: [],
      rightDownChecked: [],
    };
  },

  computed: {
    dataObj() {
      const key = this.props.key;
      return this.data.reduce((o, cur) => (o[cur[key]] = cur) && o, {});
    },

    sourceData() {
      let sourceData = this.data.filter(
        (item) => this.value.indexOf(item[this.props.key]) === -1
      );
      return sourceData.filter(
        (item) => this.rightDownvalue.indexOf(item[this.props.key]) === -1
      );
      // return this.data.filter(item => this.value.indexOf(item[this.props.key]) === -1);
    },

    targetData() {
      if (this.targetOrder === 'original') {
        return this.data.filter(
          (item) => this.value.indexOf(item[this.props.key]) > -1
        );
      } else {
        return this.value.reduce((arr, cur) => {
          const val = this.dataObj[cur];
          if (val) {
            arr.push(val);
          }
          return arr;
        }, []);
      }
    },

    rightDownData() {
      if (this.targetOrder === 'original') {
        return this.data.filter(
          (item) => this.rightDownvalue.indexOf(item[this.props.key]) > -1
        );
      } else {
        return this.value.reduce((arr, cur) => {
          const val = this.dataObj[cur];
          if (val) {
            arr.push(val);
          }
          return arr;
        }, []);
      }
      // return this.data.filter(item => this.rightDownvalue.indexOf(item[this.props.key]) > -1);
    },

    hasButtonTexts() {
      return this.buttonTexts.length === 2;
    },
  },

  watch: {
    value(val) {
      this.dispatch('ElFormItem', 'el.form.change', val);
    },
    // rightDownvalue(val) {
    //   this.dispatch('ElFormItem', 'el.form.change', val);
    // }
  },

  methods: {
    getMigratingConfig() {
      return {
        props: {
          'footer-format': 'footer-format is renamed to format.',
        },
      };
    },

    onSourceCheckedChange(val, movedKeys) {
      this.leftChecked = val;
      if (movedKeys === undefined) return;
      this.$emit('left-check-change', val, movedKeys);
    },

    onTargetCheckedChange(val, movedKeys) {
      this.rightChecked = val;
      if (movedKeys === undefined) return;
      this.$emit('right-check-change', val, movedKeys);
    },

    onRightDownCheckedChange(val, movedKeys) {
      this.rightDownChecked = val;
      if (movedKeys === undefined) return;
      this.$emit('right-down-check-change', val, movedKeys);
    },

    addToLeft() {
      let currentValue = this.value.slice();
      this.rightChecked.forEach((item) => {
        const index = currentValue.indexOf(item);
        if (index > -1) {
          currentValue.splice(index, 1);
        }
      });
      this.$emit('input', currentValue);
      this.$emit('change', currentValue, 'left', this.rightChecked);
    },

    addDownToLeft() {
      let currentValue = this.rightDownvalue.slice();
      this.rightDownChecked.forEach((item) => {
        const index = currentValue.indexOf(item);
        if (index > -1) {
          currentValue.splice(index, 1);
        }
      });
      this.$emit('right-value-change', currentValue);
      this.$emit('change', currentValue, 'left', this.rightDownChecked);
    },

    addToRight() {
      let currentValue = this.value.slice();
      const itemsToBeMoved = [];
      const key = this.props.key;
      this.data.forEach((item) => {
        const itemKey = item[key];
        if (
          this.leftChecked.indexOf(itemKey) > -1 &&
          this.value.indexOf(itemKey) === -1
        ) {
          itemsToBeMoved.push(itemKey);
        }
      });
      currentValue =
        this.targetOrder === 'unshift'
          ? itemsToBeMoved.concat(currentValue)
          : currentValue.concat(itemsToBeMoved);
      this.$emit('input', currentValue);
      this.$emit('change', currentValue, 'right', this.leftChecked);
    },

    addToRightDown() {
      let currentValue = this.rightDownvalue.slice();
      const itemsToBeMoved = [];
      const key = this.props.key;
      this.data.forEach((item) => {
        const itemKey = item[key];
        if (
          this.leftChecked.indexOf(itemKey) > -1 &&
          this.value.indexOf(itemKey) === -1
        ) {
          itemsToBeMoved.push(itemKey);
        }
      });
      currentValue =
        this.targetOrder === 'unshift'
          ? itemsToBeMoved.concat(currentValue)
          : currentValue.concat(itemsToBeMoved);
      this.$emit(
        'right-value-change',
        currentValue,
        'rightDown',
        this.leftChecked
      );
      // this.$emit('change', currentValue, 'right', this.leftChecked);
    },

    clearQuery(which) {
      if (which === 'left') {
        this.$refs.leftPanel.query = '';
      } else if (which === 'right') {
        this.$refs.rightPanel.query = '';
      }
    },
  },
};
</script>

<template>
  <div class="el-transfer">
    <TransferPanel
      class="source-panel"
      v-bind="$props"
      ref="leftPanel"
      :data="sourceData"
      :title="$t(titles[0]) || t('el.transfer.titles.0')"
      :default-checked="leftDefaultChecked"
      :placeholder="filterPlaceholder || t('el.transfer.filterPlaceholder')"
      @checked-change="onSourceCheckedChange"
      :generalDisable="generalDisable"
    >
      <slot name="left-footer"></slot>
    </TransferPanel>

    <div class="d-flex d-flex-col jc-center">
      <div class="d-flex ai-center">
        <div class="el-transfer__buttons">
          <el-button
            type="info"
            :class="[
              'el-transfer__button',
              hasButtonTexts ? 'is-with-texts' : '',
            ]"
            @click.native="addToLeft"
            :disabled="rightChecked.length === 0 || generalDisable"
            circle
          >
            <i class="el-icon-back"></i>
            <span v-if="buttonTexts[0] !== undefined">{{
              buttonTexts[0]
            }}</span>
          </el-button>
          <el-button
            type="info"
            :class="[
              'el-transfer__button',
              hasButtonTexts ? 'is-with-texts' : '',
            ]"
            @click.native="addToRight"
            :disabled="leftChecked.length === 0 || generalDisable"
            circle
            style="margin-top: 10px"
          >
            <span v-if="buttonTexts[1] !== undefined">{{
              buttonTexts[1]
            }}</span>
            <i class="el-icon-right"></i>
          </el-button>
        </div>

        <!-- <div> -->
        <TransferPanel
          class="sub-panel"
          v-bind="$props"
          ref="rightPanel"
          :data="targetData"
          :title="$t(titles[1]) || t('el.transfer.titles.1')"
          :default-checked="rightDefaultChecked"
          :placeholder="filterPlaceholder || t('el.transfer.filterPlaceholder')"
          @checked-change="onTargetCheckedChange"
          :generalDisable="generalDisable"
        >
          <slot name="right-footer"></slot>
        </TransferPanel>
        <!-- </div> -->
        <!-- <div>
          <el-button
            type="primary"
            :class="['el-transfer__button', hasButtonTexts ? 'is-with-texts' : '']"
            @click.native="addDownToLeft"
            :disabled="rightDownChecked.length === 0">
            <i class="el-icon-arrow-left"></i>
            <span v-if="buttonTexts[2] !== undefined">{{ buttonTexts[2] }}</span>
          </el-button>
          <el-button
            type="primary"
            :class="['el-transfer__button', hasButtonTexts ? 'is-with-texts' : '']"
            @click.native="addToRightDown"
            :disabled="leftChecked.length === 0">
            <span v-if="buttonTexts[3] !== undefined">{{ buttonTexts[3] }}</span>
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div> -->
      </div>

      <div class="d-flex ai-center">
        <div class="el-transfer__buttons">
          <el-button
            type="info"
            :class="[
              'el-transfer__button',
              hasButtonTexts ? 'is-with-texts' : '',
            ]"
            @click.native="addDownToLeft"
            :disabled="rightDownChecked.length === 0 || generalDisable"
            circle
          >
            <i class="el-icon-back"></i>
            <span v-if="buttonTexts[2] !== undefined">{{
              buttonTexts[2]
            }}</span>
          </el-button>
          <el-button
            type="info"
            :class="[
              'el-transfer__button',
              hasButtonTexts ? 'is-with-texts' : '',
            ]"
            @click.native="addToRightDown"
            :disabled="leftChecked.length === 0 || generalDisable"
            circle
            style="margin-top: 10px"
          >
            <span v-if="buttonTexts[3] !== undefined">{{
              buttonTexts[3]
            }}</span>
            <i class="el-icon-right"></i>
          </el-button>
        </div>

        <TransferPanel
          class="sub-panel"
          style="margin-top: 20px"
          v-bind="$props"
          ref="rightPanel"
          :data="rightDownData"
          :title="$t(titles[2]) || t('el.transfer.titles.1')"
          :default-checked="rightDownDefaultChecked"
          :placeholder="filterPlaceholder || t('el.transfer.filterPlaceholder')"
          @checked-change="onRightDownCheckedChange"
          :generalDisable="generalDisable"
        >
          <slot name="right-footer"></slot>
        </TransferPanel>
      </div>
    </div>
  </div>
</template>

<style>
.source-panel .el-transfer-panel__body {
  height: 360px;
  border: 1px solid #373e41;
}

.sub-panel .el-transfer-panel__body {
  height: 140px;
  border: 1px solid #373e41;
}

.el-transfer-panel__list {
  height: 100%;
}

.el-button + .el-button {
  margin-left: 0px;
}

.el-transfer__buttons {
  display: flex;
  flex-direction: column;
}

.el-transfer__button i,
.el-transfer__button span {
  font-size: 20px;
}
</style>
