import { Organization } from '@/api/organizations';
import { Claims } from '@/store/modules/claims';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';
import {
  computed,
  inject,
  InjectionKey,
  provide,
  ref,
  Ref,
  toRaw,
  unref,
} from 'vue';
import { Route } from 'vue-router';
import { MutableRef } from '../utils/ref';
import { useMergedRouteMeta, useRoute } from './router';

/**
 * Convenience type for the `.ref` of `useLoggedInUser()`.
 */
export type LoggedInUserRef = Ref<Readonly<LoggedInUserContext | undefined>>;

/**
 * Information about the logged in user.
 */
export interface LoggedInUserContext {
  /**
   * User's uuid.
   */
  uuid: string;

  /**
   * User's company UUID.
   */
  companyId: string;

  /**
   * User's company type.
   */
  companyType: COMPANY_TYPE;

  /**
   * User's organization and all child organizations.
   */
  organization: Organization;

  /**
   * User's claims.
   */
  claims: Claims;
}

// TODO Concept of SelectedCustomer probably needs to be split to two
// independent flags like supportsSelectedCustomer and supportsSelectedOrganization
// on the route meta.
export enum ContextType {
  LoggedInUser = 'LoggedInUser',
  SelectedCustomer = 'SelectedCustomer',
}

export interface ActiveContext {
  /**
   * Selected (aka 'impersonated') company UUID.
   *
   * This value will be set only if it differs from the logged in user's company.
   * It can only be used by specific types of users, e.g. helpdesk.
   */
  selectedCompany?: string;

  /**
   * Selected organization, if an organization is actually selected.
   */
  organization: Organization | undefined;

  /**
   * List of selected organization UUID's to be used for filtering
   * assets within the current company (logged in user's company or
   * selectedCustomer).
   * If undefined, no specific filtering on organization IDs should
   * be performed.
   *
   * Derived by flattening the tree of the selected organization.
   */
  organizationIds: string[] | undefined;

  /**
   * Currently active claims (typically from logged in user).
   */
  claims: Claims;

  /**
   * Current time zone of the primary organization for the selected customer
   */
  primaryOrgTimeZone: string | undefined;
}

export interface Contexts {
  loggedInUser: MutableRef<LoggedInUserContext | undefined>;
  selectedCustomer: MutableRef<string | undefined>;
  selectedOrganization: MutableRef<string | undefined>;
  primaryOrgTimeZone: MutableRef<string | undefined>;
}

const contextsKey: InjectionKey<Contexts> = Symbol('contexts');

/**
 * Instantiate new Contexts (for logged in user and selected customer),
 * and inject them for any child components to be used.
 *
 * This must be called somewhere near the root of the application, at least
 * 'above' any child component that wants to make use of contexts.
 */
export function provideContexts(): Contexts {
  function makeMutableRef<T>(initialValue: T): MutableRef<T> {
    let previousValueJson = JSON.stringify(initialValue);
    const theRef = ref(initialValue) as Ref<Readonly<T>>;
    return {
      ref: theRef,
      update: (newValue) => {
        const newValueJson = JSON.stringify(toRaw(newValue));
        if (previousValueJson === newValueJson) {
          // Prevent unnecessary rerenders when components update to the same value.
          // Using JSON.stringify may lead to false positives (e.g. just reordering keys), but
          // it's good enough.
          return;
        }
        theRef.value = newValue;

        // We have to track the old value explicitly. Otherwise,
        // when reading context[type].value, it will trigger a dependency on
        // that, and it will cause a recursive loop.
        // You'd expect something like toRaw(context[type]) to work, but it doesn't.
        previousValueJson = newValueJson;
      },
    };
  }

  const contexts: Contexts = {
    loggedInUser: makeMutableRef(undefined),
    selectedCustomer: makeMutableRef(undefined),
    selectedOrganization: makeMutableRef(undefined),
    primaryOrgTimeZone: makeMutableRef(undefined),
  };
  provide(contextsKey, contexts);
  return contexts;
}

/**
 * Obtain the default context to use for requests within this part of the application,
 * based on the `context` key in the route's meta.
 */
export function useContextTypeFromRoute(
  route: Route | Ref<Route> = useRoute()
): Ref<ContextType> {
  const routeMetaRef = useMergedRouteMeta(route);
  return computed(
    () => unref(routeMetaRef).context ?? ContextType.LoggedInUser
  );
}

/**
 * Obtain reference to the contexts provided by a parent component.
 */
function useContexts(): Contexts {
  const contexts = inject(contextsKey);
  if (!contexts) {
    throw new Error(
      'No Contexts provided, use `provideContexts()` somewhere near the root of the app'
    );
  }
  return contexts;
}

/**
 * Use the active request context.
 *
 * Use the 'default' context based on whatever the route indicates this should be
 * in this part of the application.
 *
 * The returned ref updates whenever the user context changes, and/or the route changes to
 * a different default context.
 *
 * WARNING: Never mutate the returned context directly!
 */
export function useActiveContext(
  route: Route | Ref<Route> = useRoute()
): Ref<Readonly<ActiveContext>> {
  const contexts = useContexts();
  const contextType = useContextTypeFromRoute(route);
  return computed((): ActiveContext => {
    const activeType = unref(contextType);
    const loggedIn = unref(contexts.loggedInUser.ref);
    if (!loggedIn) {
      // TODO Ideally, just return undefined, or a 'special' empty object
      return {
        selectedCompany: undefined,
        organization: undefined,
        organizationIds: undefined,
        claims: new Claims([]),
        primaryOrgTimeZone: undefined,
      };
    }
    switch (activeType) {
      case ContextType.LoggedInUser: {
        return {
          selectedCompany: undefined,
          organization: loggedIn.organization,
          organizationIds: flattenOrganizations(loggedIn.organization).map(
            (org) => org.id
          ),
          claims: loggedIn.claims,
          primaryOrgTimeZone: undefined,
        };
      }
      case ContextType.SelectedCustomer: {
        const selectedCustomer = unref(contexts.selectedCustomer.ref);
        const selectedOrganizationId = unref(contexts.selectedOrganization.ref);
        const timeZonePrimaryOrg = unref(contexts.primaryOrgTimeZone.ref);
        if (selectedCustomer) {
          return {
            selectedCompany: selectedCustomer,
            organization: undefined,
            organizationIds: undefined,
            claims: loggedIn.claims,
            primaryOrgTimeZone: timeZonePrimaryOrg,
          };
        } else if (selectedOrganizationId) {
          const flattened = flattenOrganizations(loggedIn.organization);
          const selectedOrg = flattened.find(
            (org) => org.id === selectedOrganizationId
          );
          if (!selectedOrg) {
            throw new Error(
              `Unknown selected organization ID ${selectedOrganizationId}`
            );
          }
          return {
            selectedCompany: undefined,
            organization: selectedOrg,
            organizationIds: flattenOrganizations(selectedOrg).map(
              (org) => org.id
            ),
            claims: loggedIn.claims,
            primaryOrgTimeZone: timeZonePrimaryOrg,
          };
        } else {
          return {
            selectedCompany: undefined,
            organization: loggedIn.organization,
            organizationIds: flattenOrganizations(loggedIn.organization).map(
              (org) => org.id
            ),
            claims: loggedIn.claims,
            primaryOrgTimeZone: timeZonePrimaryOrg,
          };
        }
      }
    }
  });
}

/**
 * Obtain MutableRef of LoggedInUser context.
 *
 * This allows to both get and set the active logged in user.
 * You should probably use useLoggedInUser() in most cases.
 */
export function useMutableLoggedInUser(): MutableRef<
  LoggedInUserContext | undefined
> {
  const contexts = useContexts();
  return contexts.loggedInUser;
}

/**
 * Obtain Ref of LoggedInUser context.
 */
export function useLoggedInUser(): Ref<
  Readonly<LoggedInUserContext | undefined>
> {
  const contexts = useContexts();
  return contexts.loggedInUser.ref;
}

/**
 * Obtain Ref of selected customer primary organization time zone
 * @returns string
 */
export function usePrimaryOrgTimeZone(): MutableRef<
  Readonly<string | undefined>
> {
  const contexts = useContexts();
  return contexts.primaryOrgTimeZone;
}

/**
 * Obtain Ref of Selected Customer context.
 *
 * Useful for e.g. Helpdesk, Body Builder and Dealer users to select the
 * customer to impersonate.
 */
export function useSelectedCustomer(): MutableRef<string | undefined> {
  const contexts = useContexts();
  return contexts.selectedCustomer;
}

/**
 * Obtain Ref of Selected Organization context.
 *
 * Useful for e.g. Customer users to select a child organization.
 */
export function useSelectedOrganization(): MutableRef<string | undefined> {
  const contexts = useContexts();
  return contexts.selectedOrganization;
}

/**
 * Filter given set of organizations down to given list of ids.
 */
export function filterOrganizations(
  organizations: Organization[],
  ids: string[]
): Organization[] {
  return organizations.filter((org) => ids.includes(org.id));
}

/**
 * Return a 'flat' array of all organizations passed in.
 *
 * Note that the resulting list still contains children properties with their child organizations.
 */
export function flattenOrganizations(
  organizations: Organization | Organization[]
): Organization[] {
  if (!Array.isArray(organizations)) {
    organizations = [organizations];
  }
  return organizations.flatMap((org) => [
    org,
    ...flattenOrganizations(org?.children),
  ]);
}
