import { getRequest } from '@/utils/request';
import { CommonResultPromise } from './commonResult';

export interface CustomerResponse {
  id: string;
  name: string;
  primaryOrganizationId?: string;
}

export function getCustomersByAssetServiceRegion(
  accessibleServiceRegions: string[],
  page: number,
  size: number
): CommonResultPromise<CustomerResponse[]> {
  const request = getRequest();
  const response = request.post<CustomerResponse[]>(
    '/customers-by-assets-service-regions',
    { accessibleServiceRegions },
    {
      params: { page, size },
    }
  );
  return response;
}

export function getCustomersByAccessibleAssets(
  organizationUUID: string,
  page: number,
  size: number
): CommonResultPromise<CustomerResponse[]> {
  const request = getRequest();
  const response = request.get<CustomerResponse[]>(
    `/customers-by-accessible-assets/${organizationUUID}`,
    {
      params: { page, size },
    }
  );
  return response;
}
