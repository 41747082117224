
import { AsyncValue } from '@/composables/async';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

export interface AssetTypeSelectItem {
  assetType: string;
  selected: boolean;
}

@Component({
  name: 'MultiAssetTypeSelect',
})
export default class extends Vue {
  @Prop() assetTypeList!: AsyncValue<AssetTypeSelectItem[] | undefined>;
  @Prop() error!: string | undefined;
  @Prop() disabled?: boolean;

  selectedAssetTypeOptions: string[] = [];

  // el-select props
  selectedAssetTypeOptionsTranslated: string[] = [];

  //el-switch props
  selectedAssetTypesSwitch: AssetTypeSelectItem[] = [];

  allDropdownAssetTypesSelected: boolean = true;

  /**
   * Check the received list changes to reflect in the dropdown
   * @param newData
   */
  @Watch('assetTypeList', { immediate: true })
  handleData(newData: AsyncValue<AssetTypeSelectItem[] | undefined>): void {
    this.determineAllSelectionState();
    if (newData.data?.length) {
      this.selectedAssetTypeOptions = this.getSelectedAssetsCode(newData.data);
      this.selectedAssetTypeOptionsTranslated =
        this.selectedAssetTypeOptions.map((el) => this.$tc(el));
      this.selectedAssetTypesSwitch = newData.data;
    }
  }

  /**
   * Determine 'All' btn state by comparing the total number of assets list against which one are selected
   */
  determineAllSelectionState(): void {
    this.allDropdownAssetTypesSelected =
      this.assetTypeList.data?.length ===
      this.assetTypeList.data?.filter((item) => item.selected)?.length;
  }

  /**
   * Filter selected assets then return the name of those objects into the main input element
   * @param assetsList
   */
  getSelectedAssetsCode(assetsList: AssetTypeSelectItem[]): string[] {
    const selectedAssets: AssetTypeSelectItem[] = assetsList?.filter(
      (asset: AssetTypeSelectItem) => asset.selected
    );
    return selectedAssets?.map((obj: AssetTypeSelectItem) => obj.assetType);
  }

  getValue(assetsCode: string): string {
    return this.$tc(assetsCode);
  }

  /**
   * Handle asset dropdown changes
   */
  async assetDropdownChanged(): Promise<void> {
    this.selectedAssetTypesSwitch.forEach(
      (item: AssetTypeSelectItem) =>
        (item.selected = this.selectedAssetTypeOptionsTranslated.includes(
          this.$tc(item.assetType)
        ))
    );
    this.determineAllSelectionState();
    this.$emit(
      'filterData',
      this.selectedAssetTypesSwitch.filter(
        (asset: AssetTypeSelectItem) => asset.selected
      )
    );
  }

  /**
   * Toggle all dropdown assets selection
   */
  async toggleAllDropdownAssetsSelected(): Promise<void> {
    this.allDropdownAssetTypesSelected = !this.allDropdownAssetTypesSelected;
    this.selectedAssetTypesSwitch.forEach(
      (item: AssetTypeSelectItem) =>
        (item.selected = this.allDropdownAssetTypesSelected)
    );
    this.selectedAssetTypeOptions = this.selectedAssetTypesSwitch
      .filter((item: AssetTypeSelectItem) => item.selected)
      .map((item: AssetTypeSelectItem) => item.assetType);
    this.selectedAssetTypeOptionsTranslated = this.selectedAssetTypeOptions.map(
      (el) => this.$tc(el)
    );
    this.$emit(
      'filterData',
      this.selectedAssetTypesSwitch.filter(
        (asset: AssetTypeSelectItem) => asset.selected
      )
    );
  }
}
