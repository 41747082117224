import { getRequest } from '@/utils/request';
import { Module } from '@/api/users';
import { CommonResultPromise } from './commonResult';
import { IncludedWidget } from './widgets';
import { COMPANY_TYPE } from '@/utils/workData/lookuptable';

export interface DashboardTemplate {
  id: string;
  name: string;
  isDefault: boolean;
  customerType: COMPANY_TYPE;
  industry: string;
  companyId?: string;
  parentTemplateId?: string;
  userId?: string;
  widgets: IncludedWidget[];
  unSelectedModules?: string[];
}

export function getTemplatesById(
  id: string
): CommonResultPromise<DashboardTemplate> {
  const request = getRequest();
  const response = request.get<DashboardTemplate>(`/templates/${id}`);
  return response;
}

export function getTemplates(
  filter?: string
): CommonResultPromise<DashboardTemplate[]> {
  const request = getRequest();
  const response = request.get<DashboardTemplate[]>(`/templates${filter}`);
  return response;
}

export function getModules(templateId: string): CommonResultPromise<Module[]> {
  const request = getRequest();
  const response = request.get<Module[]>(`/modules?templateId=${templateId}`);
  return response;
}

export function getModulesByCompanyId(companyId: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/modules?companyId=${companyId}`,
    method: 'get',
  });
  return response;
}
export function getPagesByModuleCode(
  templateId: string,
  moduleCode: string
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/pages?templateId=${templateId}&moduleCode=${moduleCode}`,
    method: 'get',
  });
  return response;
}

export interface ModulePageResponse {
  id: string;
  code: string;
  parentCode: string;
}

// TODO: add name field to pages
export function getPagesByModuleCodeByCompanyId(
  companyId: string,
  moduleCode: string
): CommonResultPromise<ModulePageResponse[]> {
  const request = getRequest();

  const response = request.get<ModulePageResponse[]>('/pages', {
    params: { companyId, moduleCode },
  });
  return response;
}

export function createTemplate(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: 'templates',
    method: 'post',
    data,
  });
  return response;
}

export function updateTemplate(
  templateId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `templates/${templateId}`,
    method: 'put',
    data,
  });
  return response;
}

export function getDefaultTemplate(
  customerType: string,
  industry: string
): CommonResultPromise<DashboardTemplate> {
  const request = getRequest();
  const query = `?customerType=${customerType}&industry=${industry}`;
  const response = request.get<DashboardTemplate>(
    `templates/default/template${query}`
  );
  return response;
}

export function setDefaultTemplate(
  templateId: string,
  data: any
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `templates/${templateId}/default:set`,
    method: 'put',
    data,
  });
  return response;
}
