import { getRequest } from '@/utils/request';
import { ActiveContext } from '@/composables/context';
import { ORDER_TYPES } from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export function getAssetList(
  assetType?: string,
  organizationId?: string | null,
  context?: ActiveContext
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/assets/organizations-assets-hierarchy`,
    params: {
      assetType: assetType,
      organizationId: organizationId,
      selectedCustomer: context?.selectedCompany,
    },
    method: 'get',
  });
  return response;
}

export function getKpiList(
  requestData: any,
  context: ActiveContext
): CommonResultPromise<KpiParametersValues[]> {
  const request = getRequest();
  const response = request.post<KpiParametersValues[]>(
    `/kpis/assets`,
    requestData,
    {
      params: {
        selectedCustomer: context?.selectedCompany,
      },
    }
  );
  return response;
}

export interface KpiValue {
  code: string;
  v: any;
}

export interface KpiParametersValues {
  assetId: string;
  values: KpiValue[];
}

export function getKpisForMultipleAssets(
  requestData: any,
  context?: ActiveContext
): CommonResultPromise<KpiParametersValues[]> {
  const request = getRequest();
  const response = request.post<KpiParametersValues[]>(
    `/kpis/assets`,
    requestData,
    {
      params: {
        selectedCustomer: context?.selectedCompany,
      },
    }
  );
  return response;
}

export interface KpiDataResponse {
  metadata?: {
    filter?: {
      assetTypeCode?: string;
      assetId?: string;
      assetIds?: string[];
      companyId?: string;
      organizationId?: string;
      organizationIds?: string[];
      groupBy?: string[];
    };
    sort?: KpiSort;
    paging?: KpiPaging;
    selection: {
      startDate: string;
      endDate: string;
      dataBucketDimension: string;
      dataBucketSize: string;
    };
    timeZone?: string;
  };
  details: {
    entity: string;
    fields: {
      code: string;
      unit: string;
      growthPercentage?: number | null;
      warningLimit?: {
        low: number | null;
        high: number | null;
      };
      alarmLimit?: {
        low: number | null;
        high: number | null;
      };
      values: Record<string, string>[];
    }[];
  }[];
}

export interface KpiSort {
  sortBy: string;
  sortOrder: ORDER_TYPES;
}

export interface KpiPaging {
  size: number;
  page: number;
}
