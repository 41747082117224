import { Module } from '@/api/users';
import {
  ALL_CLAIMS_CODES,
  COMPANY_TYPE,
  DESIGNATED_COMPANY_TYPES,
} from '@/utils/workData/lookuptable';

const modulesOnlyForBodyBuilderAndHelpdesk = [
  ALL_CLAIMS_CODES.AUTHRSC_MOD_ASSETS_MGMT,
];
const modulesNotAvailableForDesignated = [
  ALL_CLAIMS_CODES.AUTHRSC_MOD_CUST_ALARM_CONFIGURATION,
  ALL_CLAIMS_CODES.AUTHRSC_MOD_GEOFENCES,
  ALL_CLAIMS_CODES.AUTHRSC_MOD_ALARM_CONFIGURATION,
];

export function filterModulesForCompanyType(
  modules: Module[],
  companyType: COMPANY_TYPE
): Module[] {
  let newModules = modules;
  if (DESIGNATED_COMPANY_TYPES.includes(companyType)) {
    newModules = modules.filter(
      (item: any) => !modulesNotAvailableForDesignated.includes(item.code)
    );
  } else if (
    ![COMPANY_TYPE.BodyBuilder, COMPANY_TYPE.Dealer].includes(companyType)
  ) {
    newModules = modules.filter(
      (item: any) => !modulesOnlyForBodyBuilderAndHelpdesk.includes(item.code)
    );
  }
  return newModules;
}
