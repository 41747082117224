
import { GeofenceAsset, Geofence, GeofencePosition } from '@/api/geofenceTypes';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  LMarker,
  LControlZoom,
  LMap,
  LPolyline,
  LTileLayer,
  LTooltip,
} from 'vue2-leaflet';
import LeafletMapAssets from './LeafletMapAssets.vue';
import LeafletMapEvents from './LeafletMapEvents.vue';
import L, {
  divIcon,
  DomEvent,
  DomUtil,
  LatLng,
  LatLngBounds,
  marker,
  Polygon,
  Polyline,
  polyline,
  tooltip,
} from 'leaflet';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import GeometryUtils from 'leaflet-geometryutil';
import { Trip, TripEvent, TripTrack } from '@/utils/types/trip';
import { Position } from '@/utils/types/geoposition';
import mobileCompactor from '@/icons/svg/mobileCompactor.svg';
import {
  AssetType,
  assetTypeIcons,
  eventTypeIcons,
} from '@/utils/workData/lookuptable';
import { getBounds } from '@/utils/geofence';

@Component({
  name: 'LeafletMap',
  components: {
    LMap,
    LMarker,
    LTileLayer,
    LTooltip,
    LPolyline,
    LControlZoom,
    LeafletMapAssets,
    LeafletMapEvents,
  },
})
export default class extends Vue {
  @Prop({ required: true }) center!: number[];
  @Prop({ required: true }) zoom!: number;
  @Prop() assets!: GeofenceAsset[];
  @Prop() events!: any[];
  @Prop() selectedAsset!: string;
  @Prop() selectedAssetId!: string;
  @Prop() bounds: number[][] | boolean = false;
  @Prop() geofenceConfig!: {
    enableCreate: boolean;
    editOnly: boolean;
    showLifeCycleStatus: boolean;
  };
  @Prop() tripConfig!: {
    enableTrip: boolean;
  };

  @Watch('selectedAssetId')
  handleSelectedAssetId(newId: string) {
    if (newId) {
      this.selectedAssetIdForMap = newId;
      this.clusteredAssets = this.assets.filter(
        (asset: GeofenceAsset) => asset.id !== newId
      );
      this.unclusteredSelectedAsset = this.assets.filter(
        (asset: GeofenceAsset) => asset.id === newId
      );
    }
  }

  @Watch('assets')
  handleAssetList(newAssetList: GeofenceAsset[]) {
    if (newAssetList) {
      this.clusteredAssets = newAssetList;
    }
  }

  clusteredAssets: GeofenceAsset[] = [];
  unclusteredSelectedAsset: GeofenceAsset[] = [];
  selectedAssetIdForMap: string = '';
  isLoading = true;
  coordinates: L.LatLng[] = [];
  url: string | undefined = Vue.prototype.$envConfig.VUE_APP_MAP_URL;
  geofenceFeatureGroup: L.FeatureGroup | null = null;
  tripFeatureGroup: L.FeatureGroup | null = null;
  options: L.MapOptions = {
    zoomControl: false,
    scrollWheelZoom: false,
  };
  colors = [
    {
      key: 'GFNLCL_ACTIVE',
      value: 'green',
    },
    {
      key: 'GFNLCL_DRAFT',
      value: 'orange',
    },
    {
      key: 'GFNLCL_DEPRECATED',
      value: 'gray',
    },
  ];
  defaultZoomForIMAP: number = 9; // used for outside of china to see at least the first level of details in zoomed map tile
  mapZoom: number = this.initializeMapZoom;

  $refs!: {
    map: LMap;
    mapAssets: LeafletMapAssets;
  };

  created() {
    this.clusteredAssets = this.assets;
  }

  async mounted() {
    if (this.tripConfig && this.tripConfig.enableTrip) {
      this.initializeTrip();
    }
  }

  /**
   * For create new geofence, when initializing IMAP for china, give a default zoom to see at least the tile level details
   * Otherwise use passed in zoom prop
   * @return number
   */
  get initializeMapZoom(): number {
    return this.checkIfIMAPisUsed && this.$route.name == 'createNewGeofence'
      ? this.defaultZoomForIMAP
      : this.zoom;
  }

  get checkIfIMAPisUsed(): boolean {
    return this.url && !this.url.includes('hereapi') ? true : false;
  }

  private initializeTrip() {
    this.tripFeatureGroup = new L.FeatureGroup().addTo(
      this.$refs.map.mapObject
    );
  }

  /**
   * Fix the map rendering.
   * If you don't use this,
   * it will render gray squares.
   */
  async fixMapSize() {
    this.$refs.map.mapObject.invalidateSize();
  }

  navigateToAssetLocation(latlng: L.LatLngExpression) {
    this.$refs.map.mapObject.panTo(latlng);
  }

  initializeGeofence(geofences: Geofence[]) {
    this.geofenceFeatureGroup?.clearLayers();

    this.geofenceFeatureGroup = new L.FeatureGroup().addTo(
      this.$refs.map.mapObject
    );

    const options: L.Control.DrawConstructorOptions = {
      position: 'topright',
      draw: {
        polygon: this.geofenceConfig.enableCreate
          ? { allowIntersection: false }
          : false,
        polyline: false,
        rectangle: false,
        circle: false,
        marker: false,
        circlemarker: false,
      },
      edit: this.geofenceConfig.enableCreate
        ? { featureGroup: this.geofenceFeatureGroup, remove: true }
        : undefined,
    };

    const drawControl = new L.Control.Draw(options);

    this.$refs.map.mapObject.addControl(drawControl);

    this.$refs.map.mapObject.on(L.Draw.Event.CREATED, (event) => {
      if (this.geofenceConfig.enableCreate)
        this.geofenceFeatureGroup!.clearLayers();

      this.geofenceFeatureGroup!.addLayer(event.layer);
      this.coordinates = event.layer.getLatLngs()[0];
    });

    this.renderGeofences(geofences);
  }

  setBoundsToGeofences() {
    if (this.geofenceFeatureGroup) {
      this.setBounds(this.geofenceFeatureGroup.getBounds());
    }
  }

  setBounds(bounds: LatLngBounds) {
    this.$refs.map?.setBounds(bounds);
  }

  renderSingleGeofence(geofence: Geofence) {
    this.geofenceFeatureGroup?.clearLayers();
    this.renderGeofence(geofence);
  }

  renderGeofences(geofences: Geofence[]) {
    this.geofenceFeatureGroup?.clearLayers();
    for (const geofence of geofences) {
      this.renderGeofence(geofence);
    }
  }

  private renderGeofence(geofence: Geofence) {
    const latlngs: LatLng[] = [];

    geofence.geofencePosition = geofence.geofencePosition.sort((a, b) =>
      a.sequenceNumber > b.sequenceNumber ? 1 : -1
    );

    for (const p of geofence.geofencePosition) {
      latlngs.push(new LatLng(p.geodeticLatitude, p.geodeticLongitude));
    }

    const found = this.colors.find((s) => s.key === geofence.lifeCycle);
    const color = found ? found.value : 'grey';
    const polygon = new Polygon(latlngs, { color });

    this.geofenceFeatureGroup?.addLayer(polygon);
  }

  getGeofenceCoordinates(): GeofencePosition[] {
    return this.coordinates.map((c, i) => {
      return {
        sequenceNumber: i + 1,
        geodeticLatitude: c.lat,
        geodeticLongitude: c.lng,
      };
    });
  }

  zoomToGeofence(geofence: Geofence) {
    this.$refs.map.mapObject.fitBounds(getBounds(geofence));
  }

  clearLayers(): void {
    this.tripFeatureGroup?.clearLayers();
  }

  renderLine(locations: TripTrack[]): Polyline {
    const latlngs = locations.map(
      (l) => new LatLng(l.location.lat, l.location.lgt)
    );

    const line = polyline(latlngs, { weight: 10 });
    const startIcon = divIcon({ className: 'trip-start' });
    const endIcon = divIcon({ className: 'trip-end' });
    const startMarker = marker(latlngs[0], { icon: startIcon });
    const endMarker = marker(latlngs[latlngs.length - 1], { icon: endIcon });

    this.tripFeatureGroup?.addLayer(startMarker);
    this.tripFeatureGroup?.addLayer(endMarker);
    this.tripFeatureGroup?.addLayer(line);

    this.$refs.map.mapObject.fitBounds(line.getBounds(), { padding: [75, 75] });

    return line;
  }

  renderTripTracker(location: Position, type: AssetType) {
    const tripTracker = this.createPopup(
      assetTypeIcons[type] ?? mobileCompactor,
      new LatLng(location.lat, location.lgt)
    );
    this.tripFeatureGroup?.addLayer(tripTracker);

    return tripTracker;
  }

  renderEvents(
    polyline: Polyline,
    trip: Trip,
    onClick: (event: TripEvent) => void
  ) {
    for (const event of trip.events ?? []) {
      const icon = eventTypeIcons[event.eventType];

      if (!icon) {
        continue;
      }

      const { lat, lgt } = event.location;
      const eventMarker = this.createPopup(icon, new LatLng(lat, lgt), () =>
        onClick(event)
      );

      this.tripFeatureGroup?.addLayer(eventMarker);
    }
  }

  private createPopup(image: string, location: LatLng, onClick?: () => void) {
    const content = DomUtil.create('div', 'map-image');

    content.style.backgroundImage = `url(${image})`;

    if (onClick) {
      DomEvent.on(content, 'click', onClick);
    }

    return tooltip({
      direction: 'top',
      permanent: true,
      opacity: 100,
      className: 'map-tooltip',
    })
      .setContent(content)
      .setLatLng(location);
  }

  guessTrackerPosition(start: Position, end: Position, ratio: number) {
    return GeometryUtils.interpolateOnLine(
      this.$refs.map.mapObject,
      [
        {
          lat: start.lat,
          lng: start.lgt,
        },
        {
          lat: end.lat,
          lng: end.lgt,
        },
      ],
      ratio
    )?.latLng;
  }

  focus() {
    this.$refs.map.mapObject.scrollWheelZoom.enable();
  }

  unfocus() {
    this.$refs.map.mapObject.scrollWheelZoom.disable();
  }
}
