var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-container"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"row-class-name":_setup.getRowClass}},[_c('el-table-column',{attrs:{"prop":"assetId","label":_vm.$t('fleetPerformance.assetId'),"width":"160","sortable":""}}),_c('el-table-column',{attrs:{"prop":"loadPerHour","label":`${_vm.$t('fleetPerformance.loadPerHour')} (${_vm.$t(
        'UNIT_METRIC_TONNE_P_HOUR'
      )})`,"width":"120","sortable":""}}),_c('el-table-column',{attrs:{"prop":"loadPrTrip","label":`${_vm.$t('fleetPerformance.loadPerTrip')} (${_vm.$t(
        'UNIT_METRIC_TONNE'
      )})`,"unit":"$t('UNIT_METRIC_TONNE')","width":"120","sortable":""}}),_c('el-table-column',{attrs:{"prop":"avgTripTime","label":`${_vm.$t('fleetPerformance.avgTripTime')} (${_vm.$t(
        'UNIT_MINUTE_SHORT'
      )})`,"width":"140","sortable":""}}),_c('el-table-column',{attrs:{"prop":"trips","label":_vm.$t('fleetPerformance.trips'),"width":"80","sortable":""}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }