
import { ElTree } from 'element-ui/types/tree';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'TreeTransfer',
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop() confirmBtnName!: string;
  @Prop({ default: false }) visible!: boolean;
  @Prop() listTitles!: Array<any>;
  @Prop() data!: { selectedData: Array<string>; tree: any };
  @Prop({ default: ' assets' }) numberOfAssetsLabel!: string;

  unwatch: any = null;

  tree1Count = 0;
  tree2Count = 0;

  @Watch('visible')
  visibleChanged(newValue: any, oldValue: any) {
    this.$nextTick(() => {
      const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
      const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;

      tree1.filter(false);
      tree2.filter(true);
      [this.tree1Count, this.tree2Count] = this.countCustomers();
    });
  }

  countCustomers(): any {
    let selectedData = this.data.selectedData;
    let selectedCount = this.data.tree.reduce(
      (count: number, node: any) =>
        count + (this.anySelected(node, selectedData) ? 1 : 0),
      0
    );
    return [this.data.tree.length - selectedCount, selectedCount];
  }

  anySelected(node: any, selectedList: any[]) {
    if (selectedList.includes(node.id)) {
      return true;
    }
    for (let child of node.children) {
      if (this.anySelected(child, selectedList)) {
        return true;
      }
    }
    return false;
  }

  shouldHighlight(id: any) {
    return this.data.selectedData.includes(id);
  }

  beforeUnmount() {
    if (this.unwatch) {
      this.unwatch();
    }
  }

  closeDialog() {
    this.$emit('handle-cancel');
  }

  handleDialogCancel() {
    this.$emit('handle-cancel');
  }

  handleConfirm() {
    this.$emit('handle-confirm');
  }

  moveRight() {
    const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
    const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;

    let nodes: Array<any> = tree1.getCheckedNodes();
    this.data.selectedData.push(...nodes.map((node) => node.id));

    tree1.filter(false);
    tree2.filter(true);
    tree1.setCheckedKeys([], true);
    [this.tree1Count, this.tree2Count] = this.countCustomers();
  }

  moveLeft() {
    const tree1 = this.$refs.tree1 as ElTree<HTMLElement, any>;
    const tree2 = this.$refs.tree2 as ElTree<HTMLElement, any>;
    let nodes: Array<string> = tree2
      .getCheckedNodes()
      .map((node: { id: any }) => node.id);
    this.data.selectedData = this.data.selectedData.filter(
      (id: string) => !nodes.includes(id)
    );

    tree1.filter(false);
    tree2.filter(true);
    tree2.setCheckedKeys([], true);
    [this.tree1Count, this.tree2Count] = this.countCustomers();
  }

  filterTreeNode(value: any, node: any) {
    return this.data.selectedData.includes(node.id) == value;
  }
}
