import { getRequest } from '@/utils/request';
import { IActivationStatus } from './types';
import { ACTIVATION_STATUS } from '@/utils/workData/lookuptable';
import { CommonResultPromise } from './commonResult';

export type OrganizationResponse = Organization[];

export interface Organization {
  id: string;
  companyId: string;
  parentId: string;
  name: string;
  activationStatus: ACTIVATION_STATUS;
  assetsCount: number;
  usersCount: number;
  subOrganizationCount: number;
  timezone: string;
  children: OrganizationChild[];
  note: string;
  serviceRegion: string[];
  street: string;
  district: string;
  province: string;
  city: string;
  country: string;
  additionalInfo: string;
  zipCode: string;
  isDeleted: boolean;
  parentName?: string;
}

export interface OrganizationChild extends Organization {
  parentId: string;
  parentName: string;

  city: string;
  country: string;
  district: string;
  province: string;
  street: string;
  zipCode: string;
  note: string;
}

export function getOrganizations(
  id: string,
  filter: string = ''
): CommonResultPromise<OrganizationResponse> {
  const request = getRequest();
  const response = request.get<OrganizationResponse>(
    `/organizations?companyId=${id}${filter}`
  );
  return response;
}

export function getOrgById(id: string): CommonResultPromise<Organization> {
  const request = getRequest();
  const response = request.get<Organization>(`/organizations/${id}`);
  return response;
}

/**
 * Get organization by id with additional query params in url
 * @param id
 * @param queryParams
 * @returns Organization
 */
export function getOrgByIdWithMultipleFiltering(
  id: string,
  queryURLParams: string
): CommonResultPromise<Organization> {
  const request = getRequest();
  const response = request.get<Organization>(
    !queryURLParams
      ? `/organizations/${id}`
      : `/organizations/${id}${queryURLParams}`
  );
  return response;
}

export function updateOrg(id: string, data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations/${id}`,
    method: 'put',
    data,
  });
  return response;
}

export function createOrganization(data: any): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: '/organizations',
    method: 'post',
    data,
  });
  return response;
}

export function deleteOrganization(id: string): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations/${id}`,
    method: 'delete',
  });
  return response;
}

export function updateActivationStatus(
  id: string,
  data: IActivationStatus
): CommonResultPromise {
  const request = getRequest();
  const response = request.request({
    url: `/organizations/${id}/activation`,
    method: 'put',
    data,
  });
  return response;
}
