
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ButtonProps } from '@/components/button/CardButton.vue';
import CardButton from '@/components/button/CardButton.vue';

@Component({
  name: 'BaseCard',
  components: {
    CardButton,
  },
})
export default class extends Vue {
  @Prop() title!: string;
  @Prop({ default: false }) backIconVisible!: boolean;
  @Prop({ default: '-1' }) backPath!: string;
  @Prop({ default: false }) showDialogBeforeBack!: boolean;
  @Prop({ default: [] }) buttons!: ButtonProps[];

  handleGoBack() {
    if (!this.showDialogBeforeBack) {
      this.goBack();
    } else {
      this.$emit('handle-back');
    }
  }

  goBack() {
    this.backPath === '-1'
      ? this.$router.go(-1)
      : this.$router.push(this.backPath);
  }
}
